var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { Modal } from '@common/components/common/modal';
import { defaults } from 'lodash-es';
import React, { cloneElement } from 'react';
import { render, unmount } from '../render';
export const InnerModal = (props) => {
    var _a;
    const { color = 'primary', destroyCloseIcon = true, icon, title, content, children, titleClassName } = props, modalProps = __rest(props, ["color", "destroyCloseIcon", "icon", "title", "content", "children", "titleClassName"]);
    const iconEl = icon &&
        cloneElement(icon, {
            className: classNames(`w-16 h-16 text-${color}`, {
                'mb-3 pc:mb-6': Boolean(title || content || children)
            }, (_a = icon.props) === null || _a === void 0 ? void 0 : _a.className)
        });
    return (_jsxs(Modal, Object.assign({}, modalProps, { open: true, className: classNames('p-0 w-75.75 pc:w-100', modalProps.className), bodyClassName: classNames('flex flex-col items-center p-6 w-full', modalProps.bodyClassName), footerClassName: classNames('flex items-center justify-center w-full h-20 p-6', modalProps.footerClassName), color: color, destroyHeader: true, destroyCloseIcon: destroyCloseIcon, getContainer: () => document.body }, { children: [iconEl, title && (_jsx("div", Object.assign({ className: classNames('text-base pad:text-xl font-medium', {
                    'mb-4': Boolean(content || children)
                }, titleClassName) }, { children: title }))), content ? _jsx("div", Object.assign({ className: "text-sm text-gray-500" }, { children: content })) : children] })));
};
function config(options) {
    return new Promise((resolve) => {
        const container = document.createDocumentFragment();
        render(_jsx(InnerModal, Object.assign({}, options, { onClose: () => {
                var _a, _b;
                unmount(container);
                // 兼容老版本
                if (typeof options.onClose === 'function') {
                    (_a = options.onClose) === null || _a === void 0 ? void 0 : _a.call(options);
                }
                else {
                    (_b = options.onCancel) === null || _b === void 0 ? void 0 : _b.call(options);
                }
                resolve(false);
            }, onConfirm: () => {
                var _a;
                unmount(container);
                (_a = options.onConfirm) === null || _a === void 0 ? void 0 : _a.call(options);
                resolve(true);
            }, onCancel: () => {
                var _a;
                unmount(container);
                (_a = options.onCancel) === null || _a === void 0 ? void 0 : _a.call(options);
                resolve(false);
            } })), container);
    });
}
function success(options) {
    return config(defaults({}, success.defaultProps, options));
}
success.defaultProps = {
    color: 'primary',
    cancelProps: { className: 'min-w-23 h-10.5 pc:h-11' },
    confirmProps: { className: 'min-w-23 h-10.5 pc:h-11' }
};
function error(options) {
    return config(Object.assign(Object.assign({}, error.defaultProps), options));
}
error.defaultProps = {
    color: 'primary',
    cancelProps: { className: 'min-w-23 h-10.5 pc:h-11' },
    confirmProps: { className: 'min-w-23 h-10.5 pc:h-11' }
};
export const confirm = config;
confirm.success = success;
confirm.error = error;

import { createUnique } from '@common/application/utils/common';
import { Cookie } from '@common/application/utils/cookie';
import { STORAGE_PREFIX, USER_STORAGE_KEY } from '@common/constants/common';
import { cookieOptions } from '@common/constants/env';
import { parse } from 'cookie';
import { AES, enc } from 'crypto-ts';
import { get } from 'lodash-es';

export default async function checkAndSetUserSession(ctx: any) {
  const cryptoKey = STORAGE_PREFIX;
  // 处理accessToken和userssion
  // let userSession = ctx ? Cookie.get(ctx, USER_STORAGE_KEY) : '';
  const { req } = ctx;

  const cookies = parse(req?.headers?.cookie || '');
  // 处理accessToken和userssion
  let userSession = ctx
    ? get(cookies, `${STORAGE_PREFIX}${USER_STORAGE_KEY}`)
      ? AES.decrypt(get(cookies, `${STORAGE_PREFIX}${USER_STORAGE_KEY}`), cryptoKey).toString(
          enc.Utf8
        )
      : ''
    : '';

  if (!userSession && ctx) {
    const id = createUnique();
    Cookie.set(ctx, USER_STORAGE_KEY, id, 365, cookieOptions);
    userSession = id;
  }

  return userSession;
}

import { toNumber } from 'lodash-es';
import { STORAGE_PREFIX } from '@common/constants/common';
export const prefix = STORAGE_PREFIX;
class LocalStorage {
    static get(name, defaultValue = null) {
        const key = prefix + name;
        try {
            const data = localStorage.getItem(key);
            if (data) {
                return JSON.parse(data);
            }
            return defaultValue;
        }
        catch (e) {
            return defaultValue;
        }
    }
    static set(name, value) {
        const key = prefix + name;
        return localStorage.setItem(key, JSON.stringify(value));
    }
    static remove(name) {
        const key = prefix + name;
        return localStorage.removeItem(key);
    }
    static setWithExpire(name, value, expire = 1000 * 60 * 60 * 24 * 30) {
        // default save a month
        const expiredTime = new Date().getTime() + expire;
        const key = prefix + name;
        return localStorage.setItem(key, `${JSON.stringify({ value })}&expire=${expiredTime}`);
    }
    static getWithExpire(name, defaultValue = null) {
        const key = prefix + name;
        try {
            const data = localStorage.getItem(key);
            if (data) {
                const [dataString, expiredTIme] = data.split(/&expire=/);
                const nowDate = new Date().getTime();
                // 如果没有过期
                if (!expiredTIme || toNumber(expiredTIme) > nowDate) {
                    return JSON.parse(dataString).value;
                }
                // 已经过期了,移除过期的存储
                localStorage.removeItem(key);
            }
            return defaultValue;
        }
        catch (e) {
            return defaultValue;
        }
    }
}
export default LocalStorage;

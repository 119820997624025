var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CommonApi } from '@common/api/common';
import { useInRN } from '@common/application/hooks/use-in-rn';
import { WEBSITE_LANGUAGE } from '@common/constants/common';
import env from '@common/constants/env';
import { LANGUAGE_NAMES, LANGUAGE_OPTIONS, LANGUAGE_VALUES } from '@common/constants/language';
import axios from 'axios';
import { find } from 'lodash-es';
import { useRouter } from 'next/router';
import { i18n } from 'next-i18next';
import { useEffect, useState } from 'react';
export const useHeaderNotice = () => {
    const router = useRouter();
    const isRNWebViewState = useInRN();
    const [noticeList, setNoticeList] = useState([]);
    const [language, setLanguage] = useState(LANGUAGE_OPTIONS[0]);
    const changeLanguage = (value) => {
        if (value.id === LANGUAGE_VALUES.EN) {
            setLanguage({ id: LANGUAGE_VALUES.TC, name: LANGUAGE_NAMES[LANGUAGE_VALUES.TC] });
        }
        else if (value.id === LANGUAGE_VALUES.TC || value.id === LANGUAGE_VALUES.CN) {
            setLanguage({ id: LANGUAGE_VALUES.EN, name: LANGUAGE_NAMES[LANGUAGE_VALUES.EN] });
        }
        else {
            setLanguage({ id: LANGUAGE_VALUES.TC, name: LANGUAGE_NAMES[LANGUAGE_VALUES.TC] });
        }
    };
    useEffect(() => {
        function getData() {
            return __awaiter(this, void 0, void 0, function* () {
                const noticeMsgList = yield CommonApi.getNoticeMsgList({
                    type: 1
                });
                setNoticeList(noticeMsgList);
            });
        }
        getData();
        const local = find(LANGUAGE_OPTIONS, {
            id: router.locale
        });
        if (local)
            changeLanguage(local);
    }, []);
    const selectLanguage = (value) => __awaiter(void 0, void 0, void 0, function* () {
        setLanguage(value);
        yield axios.get('/api/setWebsiteLanguage', {
            headers: {
                [WEBSITE_LANGUAGE]: value.id
            }
        });
        yield router.replace(router.route, router.asPath, { locale: value.id, shallow: true });
        router.reload();
    });
    const selectSite = (value) => {
        if (value.url) {
            window.open(`${value.url}${i18n === null || i18n === void 0 ? void 0 : i18n.language}`);
        }
    };
    return {
        noticeList,
        language,
        isRNWebViewState,
        selectSite,
        selectLanguage
    };
};
export const SiteList = [
    { id: '1', name: 'DCH Living', url: env.LIVING_SITE },
    { id: '2', name: 'Foodmart', url: env.FOODMART_SITE },
    { id: '3', name: 'Ahaa', url: env.AHAA_SITE }
];

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { isString } from 'lodash-es';
import { useMemo } from 'react';
/**
 * 雪碧图
 */
export const SpritesImage = (props) => {
    const { className, itemWidth, itemHeight = itemWidth, aspectRatio, imageWithIcon = 10, index = 1, images, children } = props, divProps = __rest(props, ["className", "itemWidth", "itemHeight", "aspectRatio", "imageWithIcon", "index", "images", "children"]);
    const { imageSrc, offset } = useMemo(() => {
        // @ts-ignore 1->0, 10->0, 11->1, 20->1 ...
        const imageIndex = Math.ceil(index / imageWithIcon) - 1;
        const image = images[imageIndex];
        // @ts-ignore 1->0, 10->9, 11->0, 20->9 ...
        const offset = (index - 1) % imageWithIcon;
        return {
            imageSrc: isString(image) ? image : image.src,
            offset
        };
    }, [index, imageWithIcon, images]);
    const iconScale = useMemo(() => {
        if (aspectRatio) {
            const [w, h] = aspectRatio.split('/');
            // @ts-ignore
            return h / w / (itemHeight / itemWidth);
        }
        return 1;
    }, [aspectRatio]);
    // 只有使用 img 标签才能动态改变图标的显示比例和尺寸
    return (_jsxs("div", Object.assign({}, divProps, { className: classNames('relative inline-block overflow-hidden flex-shrink-0', className) }, { children: [_jsx("img", { className: "absolute left-0 top-[var(--icon-top)] origin-top scale-y-[var(--icon-scale)] w-full pointer-events-none", style: {
                    // @ts-ignore
                    '--icon-top': `-${offset * 100}%`,
                    '--icon-scale': iconScale
                }, src: imageSrc }), children] })));
};
SpritesImage.createIcon = (rawOpts) => {
    const { icons } = rawOpts, options = __rest(rawOpts, ["icons"]);
    const SpritesIcon = (props) => {
        var _a;
        return _jsx(SpritesImage, Object.assign({}, props, options, { index: (_a = icons[props.icon]) !== null && _a !== void 0 ? _a : props.index }));
    };
    return SpritesIcon;
};

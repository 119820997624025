export var DisplayLocation;
(function (DisplayLocation) {
    /**
     * 首页
     */
    DisplayLocation["HOME"] = "HOME";
    /**
     * 指定品牌
     */
    DisplayLocation["SPECIFIC_BRAND"] = "SPECIFIC_BRAND";
    /**
     * 指定分类
     */
    DisplayLocation["SPECIFIC_CATEGORY"] = "SPECIFIC_CATEGORY";
    /**
     * 指定商户
     */
    DisplayLocation["SPECIFIC_MERCHANT"] = "SPECIFIC_MERCHANT";
})(DisplayLocation || (DisplayLocation = {}));

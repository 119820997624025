export var EVENT_NAME;
(function (EVENT_NAME) {
    /**
     * 初始化PLP列表数量显示
     */
    EVENT_NAME["CART_NUMBER"] = "cartNumber";
    /**
     * 更新miniCart角标数量显示
     */
    EVENT_NAME["UPDATE_CART_NUMBER"] = "updateCartNumber";
    /**
     * 重置PLP筛选价格
     */
    EVENT_NAME["RESET_PRICE_FILTER"] = "resetPriceFilter";
    /**
     * PDP 使用浏览器回退状态
     */
    EVENT_NAME["POP_STATET"] = "popState";
    /**
     * 重置mob端从pdp返回时的page
     */
    EVENT_NAME["RESET_PDP_PAGE"] = "resetPdpPage";
    /**
     * 提交订单时触发
     */
    EVENT_NAME["SUBMIT_ORDER"] = "submitOrder";
    /**
     * 防止plp筛选项二次更新
     */
    EVENT_NAME["PLP_FILTER_UPDATE"] = "plpFilterUpdate";
    /**
     * 更新plp价格筛选
     */
    EVENT_NAME["UPDATE_PRICE_FILTER"] = "updatePriceFilter";
    /**
     * 更新pdp底部button是否触底
     */
    EVENT_NAME["PDP_BTN_FIXED"] = "pdpBtnFixed";
})(EVENT_NAME || (EVENT_NAME = {}));

import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable global-require */
/*
 * 纯数字输入框
 */
import React from 'react';
import Input from './input';
// eslint-disable-next-line react/display-name
const InputNumber = React.forwardRef((props, _ref) => {
    return (_jsx(Input, Object.assign({}, props, { ref: _ref, onChange: (event) => {
            const val = event.target.value.replace(/[^\d]/g, '');
            if (props.onChange) {
                props.onChange(val);
            }
        } })));
});
export default InputNumber;

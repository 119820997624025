import LocalStorage from '@common/application/utils/storage';
import { SEARCH_HISTORY_KEY } from '@common/constants/common';
import { uniq } from 'lodash-es';
import { atom, useSetRecoilState } from 'recoil';
import { RECOIL_KEY } from './key';
/**
 * 搜索历史
 */
export const searchHistory = atom({
    key: RECOIL_KEY.SEARCH_HISTORY,
    default: []
});
/**
 * 添加搜索历史
 */
export function useAddSearchHistory() {
    const setSearchHistory = useSetRecoilState(searchHistory);
    return (value) => {
        if (!value)
            return;
        const historyList = LocalStorage.get(SEARCH_HISTORY_KEY, []);
        historyList.unshift(value);
        const newHistoryList = uniq(historyList).slice(0, 20);
        LocalStorage.set(SEARCH_HISTORY_KEY, newHistoryList);
        setSearchHistory(newHistoryList);
    };
}
/**
 * 清空搜索历史
 */
export function useClearSearchHistory() {
    const setSearchHistory = useSetRecoilState(searchHistory);
    return () => {
        LocalStorage.remove(SEARCH_HISTORY_KEY);
        setSearchHistory([]);
    };
}

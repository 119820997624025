import { createElement } from 'react';
/**
 * 用于加载部分打包时会被忽略的 tailwindcss 类名
 */
export const CssClassNameLoader = ({ loadClassName }) => {
    return createElement('div', {
        style: {
            display: 'none'
        },
        className: loadClassName
    });
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*
 * @Author: zhulu
 * @Date: 2022-10-08 10:46:47
 * @Description: 输入组件
 */
import { classNames } from '@common/application/utils/classnames';
import { IconClose } from '@common/components/icon/close';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
const InputComponent = (props, ref) => {
    const { t } = useTranslation(['common']);
    const { className, inputClassName, placeholder = t('form.inputPlaceholder'), label, value, disabled, showError = false, error = '', autoComplete, renderRight, renderLeft, required, inputTextClassName, onChange, valueFromOut = false, clearable } = props, otherProps = __rest(props, ["className", "inputClassName", "placeholder", "label", "value", "disabled", "showError", "error", "autoComplete", "renderRight", "renderLeft", "required", "inputTextClassName", "onChange", "valueFromOut", "clearable"]);
    const [focus, setFocus] = useState(false);
    const [errMsg, setError] = useState(error);
    const [inputValue, setInputValue] = useState(value || '');
    let isOnComposition = false;
    const inputRef = useRef();
    useImperativeHandle(ref, () => ({
        setError: (msg) => {
            setError(msg);
        }
    }));
    useEffect(() => {
        setError(error || '');
    }, [error]);
    const errStatus = useMemo(() => {
        return !isEmpty(errMsg) || showError;
    }, [errMsg, showError]);
    useEffect(() => {
        /** value是否从外部获取 */
        if (valueFromOut)
            setInputValue(value || '');
    }, [value]);
    const handleComposition = (e) => {
        if (e.type === 'compositionend') {
            isOnComposition = false;
            if (!isOnComposition && onChange) {
                onChange(e);
            }
        }
        else {
            isOnComposition = true;
        }
    };
    return (_jsxs(_Fragment, { children: [_jsxs("div", Object.assign({ className: classNames(className) }, { children: [label && (_jsx("div", Object.assign({ className: classNames('text-base text-gray-500 pb-2', {
                            'text-red-form': errStatus
                        }) }, { children: label }))), _jsxs("div", Object.assign({ className: classNames('relative w-full flex flex-row items-center px-5 h-13.5 box-border rounded-sm border-solid border-gray bg-white border', 'h-11', 'pc:h-13.5', {
                            '!border-black-1': focus,
                            'border-red-form shadow-red-form': errStatus
                        }, inputClassName) }, { children: [renderLeft && _jsx("div", Object.assign({ className: "flex-shrink-0 h-full" }, { children: renderLeft() })), _jsx("input", Object.assign({ onCompositionStart: handleComposition, onCompositionEnd: handleComposition, ref: inputRef }, otherProps, { className: classNames('outline-none w-full h-full color-black text-sm bg-transparent text-black-1', 
                                // eslint-disable-next-line sonarjs/no-all-duplicated-branches
                                required ? 'required-placeholder' : 'placeholder:text-black-4', inputTextClassName), placeholder: required ? `* ${placeholder}` : placeholder, autoComplete: autoComplete || 'off', disabled: disabled, value: inputValue, onFocus: (event) => {
                                    setFocus(true);
                                    if (otherProps.onFocus) {
                                        otherProps.onFocus(event);
                                    }
                                }, onBlur: (event) => {
                                    setFocus(false);
                                    if (otherProps.onBlur) {
                                        otherProps.onBlur(event);
                                    }
                                }, onChange: (e) => {
                                    setInputValue(e.target.value);
                                    if (!isOnComposition && onChange) {
                                        onChange(e);
                                    }
                                } })), clearable && inputValue && (_jsx("button", Object.assign({ className: "flex-shrink-0 flex items-center justify-center mx-4 w-6 h-6 bg-black-7 rounded-full", onClick: (e) => {
                                    setInputValue('');
                                    if (!isOnComposition && onChange) {
                                        // @ts-ignore
                                        e.target.value = '';
                                        onChange(e);
                                    }
                                } }, { children: _jsx(IconClose, { className: "w-2 h-2 text-black" }) }))), renderRight && _jsx("div", Object.assign({ className: "flex-shrink-0 h-full" }, { children: renderRight() }))] })), errMsg ? (_jsx("div", Object.assign({ className: classNames('w-full py-1 flex items-center text-sm text-primary box-border break-words') }, { children: errMsg || '' }))) : (_jsx(React.Fragment, {}))] })), _jsx("style", Object.assign({ jsx: true }, { children: `
          .required-placeholder::-webkit-input-placeholder {
            font-style: 1em;
            background: linear-gradient(to right, #a11f41 0, #a11f41 8px, #999 8px);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
          }
          .required-placeholder::placeholder {
            font-style: 1em;
            background: linear-gradient(to right, #a11f41 0, #a11f41 8px, #999 8px);
            background-clip: text;
            -webkit-background-clip: text;
            color: transparent;
          }
        ` }))] }));
};
const Input = React.forwardRef(InputComponent);
export default Input;

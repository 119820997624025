/*
 * @Author: 朱璐
 * @Date: 2022-10-31 16:32:36
 * @Description: store Keys
 */
export var RECOIL_KEY;
(function (RECOIL_KEY) {
    /** SSO 认证状态 */
    RECOIL_KEY["SSO_AUTHENTICATED"] = "SSO_Authenticated";
    /** 用户相关  */
    RECOIL_KEY["USER_STATE"] = "userState";
    /** 接口获取用户信息 */
    RECOIL_KEY["GET_USER_INFO"] = "getUserInfo";
    /** 商品列表的筛选值 */
    RECOIL_KEY["PRODUCT_LIST_FILTER"] = "productListFilter";
    /** 商品列表的基础筛选项 */
    RECOIL_KEY["PRODUCT_LIST_BASE_FILTER"] = "productListBaseFilter";
    /** 商品列表的分类候选项 */
    RECOIL_KEY["PRODUCT_LIST_CATEGORY"] = "productListCategory";
    /** 商品列表數據 */
    RECOIL_KEY["PRODUCT_LIST"] = "productList";
    /** 商品列表横幅 */
    RECOIL_KEY["PRODUCT_LIST_BANNER"] = "productListBanner";
    /** 商品列表来源参数 */
    RECOIL_KEY["PROUDUCT_LIST_SOURCE_PARAMS"] = "productListSourceParams";
    /** 结算页数据 */
    RECOIL_KEY["CHECKOUT"] = "checkout";
    /** 商品详情 */
    RECOIL_KEY["PRODUCT_DETAIL"] = "productDetailState";
    /** 商品详情选中数量 */
    RECOIL_KEY["PRODUCT_DETAIL_COUNT"] = "productDetailCountState";
    /** 商品详情面包屑 */
    RECOIL_KEY["PRODUCT_DETAIL_BREADCRUMB"] = "productDetailBreadcrumbState";
    /** 商品 seo 配置 */
    RECOIL_KEY["PRODUCT_SEO_CONFIG"] = "productSeoConfig";
    /** 推荐商品 */
    RECOIL_KEY["PRODUCT_DETAIL_RECOMMEND_PRODUCTS"] = "productDetailRecommendProductsState";
    /** 选中的赠品 */
    RECOIL_KEY["PRODUCT_SELECTED_GIFT_MAPS"] = "productDetailSelectedGiftMapsState";
    /** 商品评价总数量 */
    RECOIL_KEY["PRODUCT_REVIEWS_TOTAL"] = "reviewsTotalState";
    /** 配送地址 */
    RECOIL_KEY["CONSIGNEE_ADDRESS_LIST"] = "consigneeAddressList";
    /** 商品列表面包屑 */
    RECOIL_KEY["CATEGORY_BREADCRUMB"] = "categoryBreadcrumb";
    /** 购物车详情 */
    RECOIL_KEY["CART_DETAIL"] = "cartDetail";
    /** 购物车数量 */
    RECOIL_KEY["CART_COUNT"] = "cartCount";
    /** 用于展示的购物车数量 */
    RECOIL_KEY["DISPLAY_CART_COUNT"] = "displayCartCount";
    /** 页面标题参数 */
    RECOIL_KEY["PAGE_TITLE_PARAMS"] = "pageTitleParams";
    /** 当前门店 */
    RECOIL_KEY["CURRENT_STORE"] = "currentStore";
    /** 订单详情 */
    RECOIL_KEY["ORDER_DETAIL"] = "orderDetail";
    /** 搜索历史 */
    RECOIL_KEY["SEARCH_HISTORY"] = "searchHistory";
    /** 订单详情 */
    RECOIL_KEY["APPLY_AFTER_SALES"] = "applyAfterSales";
    /** 全局默认断点 */
    RECOIL_KEY["GLOBAL_DEFAULT_BREAKPOINT"] = "glabalIsDefaultBreakpoint";
    /** 积分详情 */
    RECOIL_KEY["POINT_DETAIL"] = "pointDetailState";
    /** 评论订单详情 */
    RECOIL_KEY["EVALUATION_ORDER_DETAIL"] = "evaluationOrderDetail";
    /** 首页分类 */
    RECOIL_KEY["PAGE_HONE_CATEGORY"] = "pageHomeCategory";
    /** plp搜索价格初始值 */
    RECOIL_KEY["PRODUCT_LIST_INIT_PRICE"] = "prodcutListInitPrice";
    /** 是否是RN-webView进入 */
    RECOIL_KEY["IS_RN_WEBVIEW"] = "isRNWebView";
    /** 结算页配送地址 */
    RECOIL_KEY["CHECKOUT_ADDRESS_LIST"] = "checkoutAddressList";
    /** 提货限制异常商户列表 */
    RECOIL_KEY["PICKUP_LIMIT_MERCHANTS"] = "PickupLimitMerchants";
    /** 是否是robot访问 */
    RECOIL_KEY["GLOBAL_IS_ROBOT"] = "isRobot";
    /** 商品列表横幅 */
    RECOIL_KEY["PRODUCT_LIST_BANNERS"] = "productListBanners";
    /** plp 页面类型 */
    RECOIL_KEY["PRODUCT_LIST_PAGE_TYPE"] = "prodcutListPageType";
    /** 合作商户Banner */
    RECOIL_KEY["MERCHANT_BANNER_STATE"] = "merchantInfoState";
    /** 是否在 App 中 */
    RECOIL_KEY["GLOBAL_IS_IN_APP"] = "glabalIsInApp";
})(RECOIL_KEY || (RECOIL_KEY = {}));

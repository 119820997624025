import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { IconArrow } from '@common/components/icon/arrow';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { ICategory } from '@common/types/category';
import { useToggle } from 'ahooks';
import Link from 'next/link';
import Router from 'next/router';
import { useEffect } from 'react';

interface Data {
  key: string;
  firstData: ICategory;
  data: ICategory;
  className?: string;
  index: number;
}
export const SecondCategoryMobile = (secondCategory: Data) => {
  const { data, firstData } = secondCategory;
  const [opening, openActions] = useToggle();

  const titleClick = () => {
    Router.push(
      createPath(ROUTE_MAP.CATEGORY_LIST, {
        category: [firstData.name, data.name],
        id: data.id
      })
    );
  };

  useEffect(() => {
    openActions.toggle();
  }, []);

  return (
    <>
      <div
        className={classNames(
          'flex justify-between items-center w-full pl-0 py-4 pr-1 text-xsm font-medium border-b border-black-7'
        )}
        onClick={() => {
          openActions.toggle();
        }}
      >
        <div
          onClick={(e) => {
            e.stopPropagation();
            titleClick();
          }}
          className="flex-1 line-clamp-1"
        >
          {data.name}
        </div>
        <IconArrow
          className={classNames('flex-shrink-0 w-4 h-4 rotate-180', { 'rotate-0': opening })}
        />
      </div>
      {Boolean(data.childTree?.length) && opening && (
        <ul className="flex flex-col">
          {data.childTree.map((item) => (
            <li key={item.id}>
              <Link
                className="py-2 text-xsm text-black-3 line-clamp-2"
                href={createPath(ROUTE_MAP.CATEGORY_LIST, {
                  category: [firstData.name, data.name, item.name],
                  id: item.id
                })}
              >
                {item.name}
              </Link>
            </li>
          ))}
        </ul>
      )}
    </>
  );
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/*
 * 商品分类面包屑
 */
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { PATH_NAME_MAP, ROUTE_MAP } from '@common/constants/route-map';
import { get } from 'lodash-es';
import Link from 'next/link';
import Router, { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useState } from 'react';
import { IconArrow } from '../icon/arrow';
import { Button } from './button';
import { Dropdown } from './dropdown';
export const Breadcrumb = ({ className, breadcrumb, arrowIcon }) => {
    const { t } = useTranslation(['common']);
    const [expend, setExpend] = useState('');
    const router = useRouter();
    const pathName = get(PATH_NAME_MAP, router.route);
    return (_jsx("div", Object.assign({ className: classNames('text-sm pc:text-2xl h-fit mb-4 flex text-black-2', className) }, { children: breadcrumb.map((item, index) => {
            switch (item.type) {
                case 'home':
                    return (_jsx("div", Object.assign({ className: "flex items-center" }, { children: _jsx("div", Object.assign({ className: classNames('text-sm mr-1', {
                                'text-black-1': index === breadcrumb.length - 1,
                                'text-black-3': index === 0
                            }) }, { children: _jsx(Link, Object.assign({ href: ROUTE_MAP.HOME }, { children: item.name })) })) }), item.id));
                case 'search':
                    return (_jsxs("div", Object.assign({ className: "grid grid-flow-col place-content-center pt-0.75" }, { children: [arrowIcon, _jsxs("div", Object.assign({ className: classNames('text-sm text-primary font-bold whitespace-nowrap overflow-hidden text-ellipsis') }, { children: ["\u300C", item.name] })), _jsxs("span", Object.assign({ className: "text-sm text-primary font-bold" }, { children: ["\u300D", t('categoryBreadcrumb.relatedProducts')] }))] })));
                default:
                    return (_jsxs("div", Object.assign({ className: "flex items-center" }, { children: [arrowIcon, _jsx(Dropdown, Object.assign({ onVisibleChange: (visible) => {
                                    setExpend(visible ? item.id : '');
                                }, trigger: ['hover'], data: item.menu || [], value: item.id, className: "text-xs text-black-2 max-h-96 overflow-y-auto", textClassName: "line-clamp-1", placement: "bottomLeft", onChange: (value) => {
                                    const { path = [] } = item;
                                    const _path = [...path];
                                    _path[path.length - 1] = value.name;
                                    Router.push(createPath(ROUTE_MAP.CATEGORY_LIST, {
                                        category: _path,
                                        id: value.id
                                    }));
                                } }, { children: _jsx("div", Object.assign({ className: "text-sm link" }, { children: _jsx(Button, Object.assign({ className: classNames('mr-1 flex items-center text-left bg-black-8 border-black-6 px-1 text-black-1'), textClassName: "line-clamp-1", rightIcon: item.type !== 'brand' && (_jsx(IconArrow, { className: classNames('flex-shrink-0 w-4 h-4 border border-black-4 rounded-full rotate-180 border-transparent text-black-3', { '!rotate-0': expend === item.id }) })) }, { children: index === breadcrumb.length - 1 && pathName !== 'productDetailsPage' ? (_jsx("h1", { children: item.name })) : (_jsx(_Fragment, { children: item.name })) })) })) }))] }), item.id));
            }
        }) })));
};
Breadcrumb.defaultProps = {
    arrowIcon: _jsx(_Fragment, {})
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CategoryApi } from '@common/api/category';
import { categoryBreadcrumb, prodcutListPageTypeState } from '@common/application/store/product-list';
import { last, uniqueId } from 'lodash-es';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { checkQueryDep } from '../utils/check-query-dep';
export function useBreadcrumb() {
    const { t } = useTranslation();
    const { query } = useRouter();
    const pageType = useRecoilValue(prodcutListPageTypeState);
    const [breadcrumb, setBreadcrumb] = useRecoilState(categoryBreadcrumb);
    const name = ((pageType === 'search' ? query.keywords || query.q : query.name) || '');
    const breadcrumbWithHome = useMemo(() => [
        {
            id: uniqueId(),
            path: [],
            type: 'home',
            name: t('common:home')
        },
        ...breadcrumb
    ], [breadcrumb]);
    function getBreadcrumb() {
        var _a, _b;
        return __awaiter(this, void 0, void 0, function* () {
            if (pageType === 'displayCategory') {
                return CategoryApi.getBreadcrumb(last(query.path));
            }
            if (pageType === 'virtualCategory') {
                return ((_b = (_a = query.path) === null || _a === void 0 ? void 0 : _a.slice(0, -2).map((name) => ({
                    id: uniqueId(),
                    path: [],
                    type: pageType,
                    name
                }))) !== null && _b !== void 0 ? _b : []);
            }
            return [
                {
                    id: uniqueId(),
                    path: [],
                    type: pageType,
                    name
                }
            ];
        });
    }
    useEffect(() => {
        getBreadcrumb().then(setBreadcrumb);
    }, [checkQueryDep(query, pageType), pageType, name]);
    return [breadcrumb, breadcrumbWithHome];
}

import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import React, { useMemo } from 'react';
export const Price = (props) => {
    const { className, symbolClassName, priceClassName, lineThrough, minus, children } = props;
    const price = useMemo(() => {
        if (children === undefined)
            return '0';
        const priceNumber = Number(children);
        return Number.isNaN(priceNumber) ? children : priceNumber.toLocaleString();
    }, [children]);
    return (_jsxs("span", Object.assign({ className: classNames({
            'line-through': lineThrough
        }, className) }, { children: [_jsxs("span", Object.assign({ className: symbolClassName }, { children: [minus ? '-' : '', '$'] })), _jsx("span", Object.assign({ className: priceClassName }, { children: price }))] })));
};

import { CommonApi } from '@common/api/common';
import { useAddSearchHistory } from '@common/application/store/search';
import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
import { useDebounce } from 'ahooks';
import { isEmpty } from 'lodash-es';
import Router from 'next/router';
import { useEffect, useState } from 'react';
export const useSearchPrediction = ({ keyword, onListChange }) => {
    const [predictionList, setPredictionList] = useState([]);
    const handleAddSearchHistory = useAddSearchHistory();
    const debouncedValue = useDebounce(keyword, { wait: 250 });
    const setList = (list) => {
        onListChange === null || onListChange === void 0 ? void 0 : onListChange(list);
        setPredictionList(list);
    };
    const handleSearch = (value) => {
        handleAddSearchHistory(value);
        Router.push(createPath(ROUTE_MAP.SEARCH_RESULT, { q: value }));
    };
    useEffect(() => {
        if (isEmpty(debouncedValue))
            return setList([]);
        CommonApi.getPredictionWords({ keywords: debouncedValue }).then(({ cskuNameList }) => setList(cskuNameList));
    }, [debouncedValue]);
    return {
        predictionList,
        handleSearch
    };
};

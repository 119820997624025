var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { PopupAdApi } from '@common/api/popup-ad';
import { DisplayLocation } from '@common/types/popup-ad';
import { useAsyncEffect, useToggle } from 'ahooks';
import { useState } from 'react';
export const PopupAd = ({ displayLocation = DisplayLocation.HOME, id, icon }) => {
    const [open, openActions] = useToggle(true);
    const [data, setData] = useState();
    const renderable = displayLocation === DisplayLocation.HOME || !!id;
    useAsyncEffect(() => __awaiter(void 0, void 0, void 0, function* () {
        if (!renderable)
            return;
        const data = yield PopupAdApi.get({ displayLocation, id });
        if (!data)
            return;
        setData(data);
    }), [displayLocation, id]);
    if (!renderable || !data || !open)
        return null;
    return (_jsx("div", Object.assign({ className: "fixed z-[10000] top-0 left-0 flex items-center justify-center w-full h-full bg-[rgba(0,0,0,0.5)]" }, { children: _jsxs("div", Object.assign({ className: "relative" }, { children: [_jsx("button", Object.assign({ className: "absolute -top-13 right-0 p-1 w-8 h-8 rounded-full border border-white bg-[rgba(0,0,0,0.5)] pc:-top-15 pc:p-1.5 pc:w-10 pc:h-10", onClick: openActions.toggle }, { children: icon })), _jsx("a", Object.assign({ className: "block w-82 pc:hidden", href: data.redirectUrl, onClick: openActions.toggle }, { children: _jsx("img", { className: "w-full rounded-2", src: data.popupAdMob }) })), _jsx("a", Object.assign({ className: "hidden h-110 pc:block", href: data.redirectUrl, onClick: openActions.toggle }, { children: _jsx("img", { className: "h-full rounded-2", src: data.popupAdPc }) }))] })) })));
};

export var DELIVERY_TYPE;
(function (DELIVERY_TYPE) {
    /**
     * 自提
     */
    DELIVERY_TYPE[DELIVERY_TYPE["STORE_PICKUP"] = 0] = "STORE_PICKUP";
    /**
     * 配送
     */
    DELIVERY_TYPE[DELIVERY_TYPE["HOME_DELIVERY"] = 1] = "HOME_DELIVERY";
})(DELIVERY_TYPE || (DELIVERY_TYPE = {}));

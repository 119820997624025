import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { noop } from 'lodash-es';
import { MinusLine } from '../icon/minus-line';
import { PlusLine } from '../icon/plus-line';
import { Button } from './button';
export const InputNumber = (props) => {
    const { className, btnClassName, textClassName, iconClassName, disabled, value = 1, max = 99, min = 1, onChange = noop } = props;
    return (_jsxs("div", Object.assign({ className: classNames('inline-flex items-center w-31 h-8 border border-black-6 rounded-sm', {
            'cursor-not-allowed': disabled
        }, className) }, { children: [_jsx(Button, { className: classNames('px-0 py-0 w-8 h-full bg-black-8 border-none', btnClassName), icon: _jsx(MinusLine, { className: classNames('w-4 h-4', iconClassName, {
                        'text-black-4': value <= min,
                        'text-black-1': !(value <= min)
                    }) }), dark: false, disabled: disabled || value <= min, onClick: () => onChange(value - 1) }), _jsx("span", Object.assign({ className: classNames('flex-1 flex items-center justify-center h-full text-sm text-black-1 border-l border-r border-black-6', {
                    'opacity-50': disabled
                }, textClassName) }, { children: value })), _jsx(Button, { className: classNames('px-0 py-0 w-8 h-full bg-black-8 border-none', btnClassName), icon: _jsx(PlusLine, { className: classNames('w-4 h-4', iconClassName, {
                        'text-black-4': value >= max,
                        'text-black-1': !(value >= max)
                    }) }), dark: false, disabled: disabled || value >= max, onClick: () => onChange(value + 1) })] })));
};

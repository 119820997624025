var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { pickupLimitMerchantsState } from '@common/application/store/checkout';
import { classNames } from '@common/application/utils/classnames';
import { DeliveryTag } from '@common/components/common/delivery-tag';
import { DImage } from '@common/components/common/image';
import { Modal } from '@common/components/common/modal';
import { useTranslation } from 'next-i18next';
import { cloneElement } from 'react';
import { useRecoilState } from 'recoil';
const PickupLimitProduct = (props) => {
    const { csku, pictureUrl, name, freightType } = props;
    return (_jsxs("div", Object.assign({ className: "flex my-4 w-full" }, { children: [_jsx(DImage, { className: classNames('flex-shrink-0 mr-3 w-9 h-9 rounded-sm overflow-hidden', 'w-10 h-10'), src: pictureUrl, fill: true }), _jsxs("div", Object.assign({ className: classNames('flex-1 flex items-start') }, { children: [freightType && (_jsx(DeliveryTag, { className: classNames('flex-shrink-0 mr-1 py-0.5 text-3xs', 'pc:text-xs'), type: freightType })), _jsx("div", Object.assign({ className: classNames('text-1xs text-balck-1 line-clamp-2', 'pc:text-sm') }, { children: name }))] }))] }), csku));
};
const PickupLimitMerchant = (props) => {
    const { merchantName, hideMerchantName, pickLimitList, deliveryLimitList } = props;
    const { t } = useTranslation();
    return (_jsxs("div", Object.assign({ className: classNames('w-full', {
            'border-b border-black-6 last:border-none': hideMerchantName
        }) }, { children: [!hideMerchantName && (_jsx("div", Object.assign({ className: classNames('text-black-1 text-1xs font-medium', 'pc:text-sm') }, { children: merchantName }))), pickLimitList.map((product) => (_jsx(PickupLimitProduct, Object.assign({}, product), product.csku))), _jsx("div", Object.assign({ className: classNames('text-black-3 text-1xs', 'pc:text-sm') }, { children: t('common:pickupLimitModal.tips') })), deliveryLimitList.map((product) => (_jsx(PickupLimitProduct, Object.assign({}, product), product.csku)))] })));
};
export const PickupLimitModal = (props) => {
    const { icon, hideMerchantName } = props, madalPropa = __rest(props, ["icon", "hideMerchantName"]);
    const { t } = useTranslation();
    const [pickupLimitMerchants, setPickupLimitMerchants] = useRecoilState(pickupLimitMerchantsState);
    return (_jsx(Modal, Object.assign({}, madalPropa, { className: classNames('w-76', 'pc:w-85'), headerClassName: "flex flex-col items-center", bodyClassName: classNames('flex flex-col items-center px-5', 'pc:px-4'), title: _jsxs(_Fragment, { children: [icon &&
                    cloneElement(icon, {
                        className: classNames('w-8 h-8', 'pc:w-12 pc:h-12')
                    }), _jsx("div", Object.assign({ className: classNames('mt-4 text-sm font-medium', 'pc:mt-3 pc:text-base') }, { children: t('common:pickupLimitModal.title') }))] }), open: pickupLimitMerchants.length > 0, cancelable: false, onConfirm: () => {
            var _a;
            setPickupLimitMerchants([]);
            (_a = props.onConfirm) === null || _a === void 0 ? void 0 : _a.call(props);
        } }, { children: pickupLimitMerchants.map((merchant) => (_jsx(PickupLimitMerchant, Object.assign({}, merchant, { hideMerchantName: hideMerchantName }), merchant.merchantId))) })));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CommonApi } from '@common/api/common';
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useElementBounding } from '@common/application/hooks/use-element-bounding';
import { isEmpty } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';
export const useNavigation = () => {
    const [, setIndex] = useState(4);
    const triggerEl = useRef(null);
    /**
     * 出现在视口的元素List
     */
    const [inViewList, setInViewList] = useState([]);
    const [categoryList, setCategoryList] = useState([]);
    const bounding = useElementBounding(triggerEl);
    const boundingRigth = bounding.right;
    const { mobile } = useBreakpoint();
    useEffect(() => {
        function getData() {
            return __awaiter(this, void 0, void 0, function* () {
                const categoryMsgList = yield CommonApi.getNoticeMsgList({
                    type: 2
                });
                setCategoryList(categoryMsgList);
            });
        }
        getData();
    }, []);
    useEffect(() => {
        if (!isEmpty(inViewList)) {
            const fliterArr = inViewList.filter((item) => item);
            setIndex(fliterArr.length);
        }
    }, []);
    return {
        categoryList,
        triggerEl,
        inViewList,
        mobile,
        boundingRigth,
        setIndex,
        setInViewList
    };
};

import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const ColorLineIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  imageWithIcon: 20,
  icons: {
    copy: 1,
    email: 2,
    facebook: 3
  }
});

import { isFunction, isObject } from 'lodash-es';
/**
 * 对 ref 进行拆包
 *
 * @param maybeRef 可能是 ref 的值
 */
export function unref(maybeRef) {
    const isRef = isObject(maybeRef) && 'current' in maybeRef;
    try {
        return isRef ? maybeRef.current : isFunction(maybeRef) ? maybeRef() : maybeRef;
    }
    catch (err) {
        return undefined;
    }
}

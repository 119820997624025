var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ProductListApi } from '@common/api/product-list';
import { get } from 'lodash-es';
import { nowInRange } from '../../now-in-range';
export const sale = {
    protocol(original, ctx) {
        return __awaiter(this, void 0, void 0, function* () {
            const { setMethod = 1, sales = [], sale2 } = original.props;
            if (setMethod === 1) {
                const sale = sales[0];
                if (sale) {
                    const products = yield ProductListApi.getProductList({
                        page: 1,
                        perPage: 10,
                        promotionIds: sale.id,
                        sort: '1'
                    }, {
                        ctx
                    }).then(({ result }) => result);
                    original.props.dataset = {
                        products: {
                            loading: false,
                            data: products
                        }
                    };
                }
            }
            else {
                const cskus = sale2.goodsList.map((item) => item.csku).join(',');
                const products = yield ProductListApi.getProductListByIds({
                    ids: cskus,
                    isShowSellOut: 1
                }, {
                    ctx
                });
                original.props.dataset = {
                    products: {
                        loading: false,
                        data: products
                    }
                };
            }
            return original;
        });
    },
    renderable(props) {
        const { setMethod, time, dataset } = props;
        const product = get(dataset, 'products.data[0]');
        if (product && nowInRange(time)) {
            if (setMethod === 1) {
                return nowInRange([
                    +new Date(product.seckillStartTime.replace(/-/g, '/')).getTime(),
                    +new Date(product.seckillEndTime.replace(/-/g, '/')).getTime()
                ]);
            }
            return true;
        }
        return false;
    }
};

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { IS_CLIENT } from '@common/constants/common';
import { isFunction } from 'lodash-es';
import Notification from 'rc-notification';
import { classNames } from '../classnames';
let messageInstance;
const getRCNotificationInstance = (callback) => {
    if (messageInstance) {
        return callback(messageInstance);
    }
    Notification.newInstance({
        maxCount: 1,
        style: { zIndex: 9999 }
    }, (notification) => {
        if (messageInstance) {
            return callback(messageInstance);
        }
        messageInstance = notification;
        callback(notification);
    });
};
export const Toast = (msg, config) => {
    if (!IS_CLIENT)
        return;
    const type = (config === null || config === void 0 ? void 0 : config.type) || 'error';
    const target = (config === null || config === void 0 ? void 0 : config.key) || 'toast';
    const _duration = (config === null || config === void 0 ? void 0 : config.duration) || 1;
    const closePromise = new Promise((resolve) => {
        const callback = () => {
            if (isFunction(config === null || config === void 0 ? void 0 : config.onClose)) {
                config === null || config === void 0 ? void 0 : config.onClose();
            }
            return resolve(true);
        };
        getRCNotificationInstance((instance) => {
            const id = setTimeout(() => {
                instance.removeNotice(target);
                callback();
            }, _duration * 1000);
            instance.notice({
                key: target,
                content: (_jsx("div", Object.assign({ className: "absolute top-0 bottom-0 left-0 right-0 grid place-items-center", onClick: () => {
                        clearTimeout(id);
                        instance.removeNotice(target);
                        callback();
                    } }, { children: _jsxs("div", Object.assign({ className: "bg-white rounded-lg shadow-md flex flex-col items-center", style: { padding: '32px' } }, { children: [type !== 'none' &&
                                (type === 'success'
                                    ? Toast.defaultProps.successIcon
                                    : Toast.defaultProps.errorIcon), _jsx("span", Object.assign({ className: classNames('text-sm', 'pc:text-base') }, { children: msg }))] })) }))),
                duration: _duration,
                onClose: callback
            });
        });
    });
    const result = () => {
        if (messageInstance) {
            messageInstance.removeNotice(target);
        }
    };
    result.then = (filled, rejected) => closePromise.then(filled, rejected);
    result.promise = closePromise;
    return result;
};
Toast.defaultProps = {};

import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const WhiteLineIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    add: 1,
    cart: 2,
    right: 3,
    del: 4,
    search: 5,
    arrow: 6,
    garbage: 7,
    notice: 8
  }
});

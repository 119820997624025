var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * 會員Api
 */
// eslint-disable-next-line import/no-cycle
import request from '@common/application/utils/request';
import env from '@common/constants/env';
import { API_MAP } from './api-map';
export class MemberApi {
    /** 注册 */
    static register(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.REGISTER, Object.assign({}, params), config);
        });
    }
    /** 账号登出 */
    static logout(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.LOGOUT, {}, config);
        });
    }
    /** 账号密码登录 */
    static loginByPwd(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.LOGIN_PWD, params, Object.assign(Object.assign({}, config), { hideLoading: true }));
        });
    }
    /** 验证手机号 */
    static checkMobile(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.CHECK_MOBILE, Object.assign({}, params), config);
        });
    }
    /** 检查验证码 */
    static checkCaptcha(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.CHECK_CAPTCHA, Object.assign({}, params), config);
        });
    }
    /** 修改密码 */
    static editPwd(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.EDIT_PWD, Object.assign({}, params), config);
        });
    }
    /** 修改会员资料 */
    static editMemberInfo(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.MEMBER_EDIT_INFO, Object.assign({}, params), config);
        });
    }
    /** 登录修改密码 */
    static editPwdByPwd(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.MEMBER_EDIT_PWD, Object.assign({}, params), config);
        });
    }
    /** 获取登录认证配置 */
    static getKeycloakConfig(config) {
        return __awaiter(this, void 0, void 0, function* () {
            if (process.env.NODE_ENV === 'production') {
                return request.post(API_MAP.MEMBER_KEYCLOAK_CONFIG, {
                    // 1:登录场景,2:修改密码
                    type: 1
                }, Object.assign(Object.assign({}, config), { hideLoading: true }));
            }
            // 开发环境直接使用本地配置
            return Promise.resolve({
                url: 'https://loginsit.dchliving.com/',
                realm: 'DCHLiving',
                clientId: env.LOCAL_SSO_CLIENT
            });
            // 开发环境直接使用本地配置
            // return Promise.resolve({
            //   url: 'https://loginsit.dchliving.com/',
            //   realm: 'DCHLiving',
            //   clientId: 'dchliving-local-frontend-client'
            // });
        });
    }
    /** 二次注册 */
    static secondaryRegister(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.SECONDARY_REGISTER, Object.assign({}, params), config);
        });
    }
    /** 获取用户基础信息 */
    static getBaseMemberInfo(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.BASE_MEMBER_INFO, {}, config);
        });
    }
    /** 申请会员发送邮箱验证码 */
    static sendEmailCaptchaByMemberExamine(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.SEND_EMAIL_CAPTCHA_BY_MEMBER_EXAMINE, params, config);
        });
    }
    /** 申请会员 */
    static createMemberExamine(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.CREATE_MEMBER_EXAMINE, params, config);
        });
    }
    /**  获取会员审核状态 */
    static getMemberExamineStatus(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.GET_MEMBER_EXAMINE_STATUS, {}, config);
        });
    }
}

import { STORAGE_PREFIX } from '@common/constants/common';
import { AES, enc } from 'crypto-ts';
import { isEmpty } from 'lodash-es';
import { destroyCookie, parseCookies, setCookie } from 'nookies';
const cookiePrefix = STORAGE_PREFIX;
const cryptoKey = STORAGE_PREFIX;
export class Cookie {
    static getKey(key) {
        return cookiePrefix + key;
    }
    static set(ctx, key, value = '', expireDay = 365, options = {}) {
        const decryptValue = AES.encrypt(value, cryptoKey).toString();
        if (ctx === null || ctx === void 0 ? void 0 : ctx.req) {
            ctx.req.cookies = ctx.req.cookies || {};
            ctx.req.cookies[Cookie.getKey(key)] = decryptValue;
        }
        return setCookie(ctx, Cookie.getKey(key), decryptValue, Object.assign({ maxAge: expireDay * 24 * 60 * 60, path: '/' }, options));
    }
    static get(ctx, key, defaultValue = '') {
        var _a;
        const cookies = ((_a = ctx === null || ctx === void 0 ? void 0 : ctx.req) === null || _a === void 0 ? void 0 : _a.cookies) || parseCookies(ctx);
        if (!isEmpty(cookies[Cookie.getKey(key)])) {
            const value = AES.decrypt(cookies[Cookie.getKey(key)], cryptoKey).toString(enc.Utf8);
            return value.toString();
        }
        return defaultValue;
    }
    static del(key) {
        return destroyCookie(null, Cookie.getKey(key));
    }
    static parse(ctx) {
        var _a;
        return ((_a = ctx === null || ctx === void 0 ? void 0 : ctx.req) === null || _a === void 0 ? void 0 : _a.cookies) || parseCookies(ctx);
    }
}

import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-14 19:53:56
 * @Description: 吸顶组件
 */
import { useElementBounding } from '@common/application/hooks/use-element-bounding';
import { useWindowSize } from '@common/application/hooks/use-window-size';
import { classNames } from '@common/application/utils/classnames';
import { unref } from '@common/application/utils/unref';
import { useThrottleFn } from 'ahooks';
import { noop } from 'lodash-es';
import { useEffect, useMemo, useRef } from 'react';
export const Sticky = (props) => {
    const { id, style, className, targetClassName, retain = true, offset = 0, direction = 'top', scopeContainer, scopeOffset = 0, children, wait = 0, onFixed = noop, onScope = noop } = props;
    const windowSize = useWindowSize();
    const targetEl = useRef(null);
    const referenceEl = useRef(null);
    const scopeEl = unref(scopeContainer);
    const targetRect = useElementBounding(targetEl);
    const referenceRect = useElementBounding(referenceEl);
    const scopeRect = useElementBounding(scopeEl);
    function checkFixed() {
        if (referenceRect.isReady) {
            switch (direction) {
                case 'top':
                    return referenceRect.top <= offset;
                case 'bottom':
                    return referenceRect.bottom >= windowSize.height - offset;
                default:
                    return false;
            }
        }
        return false;
    }
    const { run } = useThrottleFn(checkFixed, { wait });
    const isFixed = useMemo(() => run(), [targetRect]);
    function checkScope() {
        if (scopeEl && isFixed) {
            switch (direction) {
                case 'top':
                    return scopeRect.bottom <= offset + targetRect.height - scopeOffset;
                case 'bottom':
                    // TODO 遇见此需求时再做实现
                    return false;
                default:
                    return false;
            }
        }
        return false;
    }
    const isScope = checkScope();
    useEffect(() => onFixed(isFixed), [isFixed]);
    useEffect(() => onScope(isScope), [isScope]);
    useEffect(() => {
        if (isScope && scopeEl) {
            const { position } = window.getComputedStyle(scopeEl, null);
            if (!position || position === 'static') {
                scopeEl.style.position = 'relative';
            }
            return () => {
                scopeEl.style.position = position;
            };
        }
    }, [isScope]);
    function getTargetStyle() {
        if (isScope) {
            return Object.assign(Object.assign({}, (style !== null && style !== void 0 ? style : {})), { [direction]: `${scopeRect.height - targetRect.height + scopeOffset}px`, left: retain ? referenceRect.left - scopeRect.left : 0, width: retain ? `${referenceRect.width}px` : undefined });
        }
        if (isFixed)
            return Object.assign(Object.assign({}, (style !== null && style !== void 0 ? style : {})), { [direction]: `${offset}px`, left: retain ? referenceRect.left : 0, width: retain ? `${referenceRect.width}px` : undefined });
    }
    return (_jsx(_Fragment, { children: _jsx("div", Object.assign({ id: id, ref: referenceEl, className: classNames(className, 'p-0 bg-transparent border-transparent'), style: Object.assign(Object.assign({}, (style !== null && style !== void 0 ? style : {})), { height: targetRect.isReady ? `${targetRect.height}px` : undefined }) }, { children: _jsx("div", Object.assign({ ref: targetEl, className: classNames('z-5', className, targetClassName, 'm-0', {
                    fixed: isFixed,
                    absolute: isScope
                }), style: getTargetStyle() }, { children: children })) })) }));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-03 11:16:31
 * @Description: 移动端的filter面板
 */
import { useCategoryTop } from '@common/application/hooks/use-category-top';
import { productListParams, useSearchProductList } from '@common/application/store/product-list';
import { classNames } from '@common/application/utils/classnames';
import { emitResetPdpPage, emitResetPriceFilter } from '@common/application/utils/event';
import { Button } from '@common/components/common/button';
import { useTranslation } from 'next-i18next';
import Drawer from 'rc-drawer';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useRecoilState } from 'recoil';
import { FilterContainer } from './filter-container';
import { MobileFilterContext } from './mobile-filter-context';
const DrawerPanel = ({ onOk, onCancel }) => {
    const { t } = useTranslation(['product-list', 'common']);
    return (_jsx("div", Object.assign({ className: "relative w-full h-full" }, { children: _jsxs("div", Object.assign({ className: "flex flex-col bg-white w-3/4 h-full relative overflow-x-auto" }, { children: [_jsx("div", Object.assign({ className: "grid auto-rows-min flex-1 px-5 pb-3 overflow-auto" }, { children: _jsx(FilterContainer, {}) })), _jsxs("div", Object.assign({ className: "flex justify-start items-center h-13.5 w-full shadow-btn px-4" }, { children: [_jsx(Button, Object.assign({ color: "white", className: classNames('h-10 bg-black-5 text-black-1 w-23 mr-2'), onClick: onCancel }, { children: t('reset') })), _jsx(Button, Object.assign({ color: "white", className: classNames('h-10 bg-primary text-white flex-1'), onClick: onOk }, { children: t('saveOk') }))] })), _jsx("style", Object.assign({ jsx: true }, { children: `
            .mobile-filter-button {
              right: calc(33.333% + 1.25rem);
            }
            .shadow-btn {
              box-shadow: 0px -2px 4px 0px rgba(0, 0, 0, 0.05);
            }
          ` }))] })) })));
};
export const MobileFilter = ({ filterIcon }) => {
    const { t } = useTranslation(['product-list', 'common']);
    const [showFilter, setShowFilter] = useState(false);
    const [filter, setFilter] = useRecoilState(productListParams);
    const paramsRef = useRef(null);
    const [active, setActive] = useState(false);
    const { categoryTop } = useCategoryTop();
    useEffect(() => {
        paramsRef.current = filter;
    }, []);
    const { search } = useSearchProductList();
    const filterChange = useCallback((type) => {
        search({ filterType: type });
    }, []);
    const handleSearch = () => __awaiter(void 0, void 0, void 0, function* () {
        filterChange('price');
        setShowFilter(false);
        emitResetPdpPage();
    });
    return (_jsxs(MobileFilterContext.Provider, Object.assign({ value: {
            closeDrawer: () => setShowFilter(false)
        } }, { children: [_jsx(Button, Object.assign({ color: "transparent", className: "flex-1 text-sm place-self-start self-center px-0", onClick: () => {
                    setShowFilter(true);
                    setActive(true);
                } }, { children: _jsxs("div", Object.assign({ className: "flex items-center justify-center" }, { children: [_jsx("span", Object.assign({ className: classNames('text-sm', 'text-black-2', { 'text-primary': active }) }, { children: t('advancedSearch') })), filterIcon === null || filterIcon === void 0 ? void 0 : filterIcon(active)] })) })), _jsx(Drawer, Object.assign({ open: showFilter, zIndex: 9999999, width: "100%", height: "100%", placement: "left", className: "bg-transparent", mask: true, rootStyle: {
                    height: `calc(100% - ${categoryTop})`,
                    top: `${categoryTop}px`
                } }, { children: _jsx(DrawerPanel, { onOk: handleSearch, onCancel: () => {
                        if (paramsRef.current) {
                            setFilter(Object.assign(Object.assign({}, paramsRef.current), { brands: [] }));
                        }
                        emitResetPriceFilter();
                        setActive(false);
                    } }) }))] })));
};
MobileFilter.defaultProps = {};

import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const ProductHighlightIcon = SpritesImage.createIcon({
  itemWidth: 380,
  images,
  icons: {
    cn: 1,
    en: 2,
    tc: 3
  }
});

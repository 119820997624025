import { useRafFn } from '@common/application/hooks/use-raf-fn';
import { useResizeObserver } from '@common/application/hooks/use-resize-observer';
import { unref } from '@common/application/utils/unref';
import { useEventListener } from 'ahooks';
import classNamesOriginal from 'classnames';
import { useState } from 'react';
import { twMerge } from 'tailwind-merge';
export const classNames = (...args) => twMerge(classNamesOriginal(...args));
export function useElementBounding(target) {
    const targetEl = unref(target);
    const [bounding, setBounding] = useState(() => ({
        height: 0,
        bottom: 0,
        left: 0,
        right: 0,
        top: 0,
        width: 0,
        x: 0,
        y: 0,
        isReady: false
    }));
    const update = useRafFn(() => {
        if (targetEl) {
            const bounding = targetEl.getBoundingClientRect();
            bounding.isReady = true;
            setBounding(bounding);
        }
    });
    useResizeObserver(update, targetEl);
    useEventListener('resize', update, { passive: true });
    return bounding;
}

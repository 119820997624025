import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const GreyLineIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    time: 1,
    tel: 2,
    location: 3,
    packup: 4,
    unfold: 5,
    del: 6,
    arrow: 7,
    add: 8,
    upload: 9
  }
});

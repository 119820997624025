import { RECOMMEND_PRODUCT_SOURCE } from '@common/constants/product-list';
import { last } from 'lodash-es';
export function getSourceParams(ctx, pageType) {
    var _a, _b;
    switch (pageType) {
        case 'brand':
            return {
                source: RECOMMEND_PRODUCT_SOURCE.BRAND,
                sourceId: (_a = ctx.params) === null || _a === void 0 ? void 0 : _a.brandId
            };
        case 'displayCategory':
            return {
                source: RECOMMEND_PRODUCT_SOURCE.DISPLAY_CATEGORY,
                sourceId: last(ctx.query.path)
            };
        case 'merchant':
            return {
                source: RECOMMEND_PRODUCT_SOURCE.MERCHANT,
                sourceId: (_b = ctx.params) === null || _b === void 0 ? void 0 : _b.merchantId
            };
    }
    return {};
}

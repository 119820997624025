import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { ICategory } from '@common/types/category';
import Link from 'next/link';
import Router from 'next/router';

import { Card } from '@/components/common/goods-category/card';

interface Data {
  key: string;
  firstData: ICategory;
  data: ICategory;
  className?: string;
  hasBrand?: boolean;
}
export const SecondCategory = (secondCategory: Data) => {
  const { data, firstData, className } = secondCategory;
  const titleClick = () => {
    Router.push(
      createPath(ROUTE_MAP.CATEGORY_LIST, {
        category: [firstData.name, data.name],
        id: data.id
      })
    );
  };
  return (
    <Card title={data.name} ontTitleClick={titleClick} className={classNames(className)}>
      {Boolean(data.childTree?.length) && (
        <ul className={classNames('my-3 w-full flex flex-col')}>
          {data.childTree.map((item) => (
            <Link
              key={item.id}
              href={createPath(ROUTE_MAP.CATEGORY_LIST, {
                category: [firstData.name, data.name, item.name],
                id: item.id
              })}
            >
              <li className="mt-3 flex items-center cursor-pointer group">
                <span className="flex-1 text-black-3 text-sm line-clamp-2 group-hover:text-primary">
                  {item.name}
                </span>
              </li>
            </Link>
          ))}
        </ul>
      )}
    </Card>
  );
};

import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useToggle } from 'ahooks';
import { cloneElement } from 'react';
import { createPortal } from 'react-dom';
import { classNames } from '@common/application/utils/classnames';
import { AspectRatioView } from '@common/components/common/aspect-ratio-view';
export const ImageViewer = (props) => {
    const { closeIcon, imgClassName, zIndex = 100, aspectRatio, children } = props;
    const [open, openActions] = useToggle();
    const { src } = children.props;
    return (_jsxs(_Fragment, { children: [cloneElement(children, {
                className: classNames(children.props.className, 'cursor-pointer'),
                onClick(e) {
                    var _a, _b;
                    if (src)
                        openActions.toggle();
                    (_b = (_a = children.props).onClick) === null || _b === void 0 ? void 0 : _b.call(_a, e);
                }
            }), open &&
                src &&
                createPortal(_jsx("div", Object.assign({ className: "fixed top-0 left-0 right-0 bottom-0 flex items-center justify-center bg-[rgba(0,0,0,0.5)]", style: {
                        zIndex
                    } }, { children: _jsxs("div", Object.assign({ className: "flex flex-col" }, { children: [_jsx("button", Object.assign({ className: classNames('flex items-center justify-center self-end mr-4 mb-5 w-6 h-6', 'pc:mr-0 pc:mb-4 pc:w-9 pc:h-9'), onClick: openActions.toggle }, { children: closeIcon })), aspectRatio ? (_jsx(AspectRatioView, Object.assign({ className: imgClassName, aspectRatio: aspectRatio }, { children: _jsx("img", { className: classNames('w-full h-full'), src: src }) }))) : (_jsx("img", { className: classNames('max-h-[80%]', imgClassName), src: src }))] })) })), document.body)] }));
};

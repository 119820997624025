export var CouponType;
(function (CouponType) {
    /**
     * 优惠券
     */
    CouponType[CouponType["COUPION"] = 1] = "COUPION";
    /**
     * 运费券
     */
    CouponType[CouponType["FREIGHT_COUPON"] = 2] = "FREIGHT_COUPON";
})(CouponType || (CouponType = {}));
export var MENU_TYPE;
(function (MENU_TYPE) {
    /**
     * 文字
     */
    MENU_TYPE["TEXT"] = "1";
    /**
     * 图片
     */
    MENU_TYPE["IMAGE"] = "2";
})(MENU_TYPE || (MENU_TYPE = {}));
export var UploadType;
(function (UploadType) {
    /**
     * 图片
     */
    UploadType["NORMAL"] = "normal";
    /**
     * 凭证图片
     */
    UploadType["PROOF"] = "proof";
})(UploadType || (UploadType = {}));

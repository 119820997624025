import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { prodcutListInitPrice, productListParams } from '@common/application/store/product-list';
import { classNames } from '@common/application/utils/classnames';
import { emitResetPriceFilter } from '@common/application/utils/event';
import { useTranslation } from 'next-i18next';
import { useEffect, useRef } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
export const FilterTitle = ({ onChange, className }) => {
    const [filter, setFilter] = useRecoilState(productListParams);
    const paramsRef = useRef(filter);
    const initPrice = useRecoilValue(prodcutListInitPrice);
    const { t } = useTranslation(['product-list']);
    useEffect(() => {
        paramsRef.current = filter;
    }, [filter]);
    return (_jsxs("div", Object.assign({ className: classNames('flex justify-between items-center mt-3 mb-4', className) }, { children: [_jsx("span", Object.assign({ className: classNames('text-sm font-medium text-black-1') }, { children: t('filterConditions') })), _jsx("div", Object.assign({ className: 'flex items-center cursor-pointer', onClick: () => {
                    if (paramsRef.current) {
                        setFilter((org) => {
                            const attributeKey = Object.keys(org).filter((i) => i.indexOf('attributes-') > -1);
                            const attributeObj = {};
                            attributeKey.forEach((item) => {
                                attributeObj[item] = [];
                            });
                            return Object.assign(Object.assign(Object.assign({}, paramsRef.current), attributeObj), { brands: [], category: org.category, categoryId: org.categoryId || '', price: { minPrice: initPrice.minPrice || '0', maxPrice: initPrice.maxPrice || '0' } });
                        });
                    }
                    emitResetPriceFilter();
                    onChange();
                } }, { children: _jsx("span", Object.assign({ className: "text-xs text-black-3 cursor-pointer mr-1" }, { children: t('reset') })) }))] })));
};

import { UserApi } from '@common/api/user';
import env from '@common/constants/env';
import { useMemoizedFn } from 'ahooks';
// eslint-disable-next-line import/no-extraneous-dependencies
import LogRocket from 'logrocket';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import { isGuest } from '../utils/is-guest';
import { RECOIL_KEY } from './key';
export const userState = atom({
    key: RECOIL_KEY.GET_USER_INFO,
    default: undefined
});
export const consigneeAddressList = atom({
    key: RECOIL_KEY.CONSIGNEE_ADDRESS_LIST,
    default: undefined
});
/**
 * 更新用户信息
 */
export function useUpdateUser() {
    const setUser = useSetRecoilState(userState);
    return useMemoizedFn(() => {
        return isGuest()
            ? setUser(undefined)
            : UserApi.getUser().then((res) => {
                if (env.ENV === 'production') {
                    LogRocket.identify(res === null || res === void 0 ? void 0 : res.phone, {
                        name: res === null || res === void 0 ? void 0 : res.name,
                        email: res === null || res === void 0 ? void 0 : res.email,
                        phone: res === null || res === void 0 ? void 0 : res.phone
                    });
                }
                setUser(res);
            });
    });
}
export function useUpdateAddressList() {
    const setAddressList = useSetRecoilState(consigneeAddressList);
    return useMemoizedFn(() => {
        UserApi.getConsigneeAddressList().then(setAddressList);
    });
}
export function useUser() {
    const user = useRecoilValue(userState);
    return { user };
}

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
/* eslint-disable global-require */
/*
 * @Author: 朱璐
 * @Date: 2022-11-07 11:22:27
 * @Description:
 */
import React, { useState } from 'react';
import Input from './input';
// eslint-disable-next-line react/display-name
const Password = React.forwardRef((props, _ref) => {
    const { iconRender, icon, closeIcon } = props, otherProps = __rest(props, ["iconRender", "icon", "closeIcon"]);
    const [pswShow, setPsw] = useState(false);
    return (_jsx(Input, Object.assign({}, otherProps, { ref: _ref, type: pswShow ? 'text' : 'password', renderRight: () => (_jsx("div", Object.assign({ className: "w-8 h-8 ml-2 cursor-pointer", onClick: () => {
                setPsw((prev) => !prev);
            } }, { children: iconRender ? iconRender(pswShow) : pswShow ? icon : closeIcon }))) })));
});
export default Password;

import { isNil } from 'lodash-es';
/**
 * 需要删除的符号
 */
const removeRE = /[°™"#),『』®$＂＇）]+/g;
/**
 * 需要替换的符号
 */
const changeRE = /(-{2,}|\s|-\/-|\/|-\(|\(|“|”|↑|-&-|\)\(|-—-|-–-|-\+-|－|\||-／-|／|-（|（|）（)+/g;
/**
 * 传入的字符如果存在需要处理的特殊符号，则对特殊符号进行替换
 *
 * @param value 需要处理的字符
 */
export function encode(value) {
    let s = isNil(value) ? '' : String(value);
    if (s) {
        s = s.replace(removeRE, '');
        while (changeRE.test(s)) {
            s = s.replace(changeRE, '-');
        }
    }
    return encodeURIComponent(s);
}

/*
 * @Author: 朱璐
 * @Date: 2022-11-09 13:43:40
 * @Description: api 接口名
 */
export const API_MAP = {
    /** 修改会员资料 */
    MEMBER_EDIT_INFO: '/member/editMemberInfo',
    /** 登录修改密码 */
    MEMBER_EDIT_PWD: '/member/editPasswordLogin',
    /** 用户信息 */
    USER_INFO: '/member/memberInfo',
    /** 配送地址列表 */
    MEMBER_ADDRESS_LIST: '/memberAddress/findByMember',
    /** 区号地址 */
    AREA_CODE: '/areaCode/findAll',
    /** 1级地址 */
    REGION_1: '/region/findDirectChildByHongKong',
    /** 2级地址 */
    REGION_2: '/region/findDirectChild',
    /** 3级地址 */
    REGION_3: '/region/findLevel3Child',
    /** 香港区域 */
    STORES_PARENT_REGION: '/region/stores/findDirectChildByHongKong',
    /** 香港地址 */
    STORES_REGION: '/region/stores/findDirectChild',
    /** 配送地址 */
    MEMBER_ADDRESS: '/memberAddress',
    /** 结算 */
    CHECKOUT_INFO: '/checkout',
    /** 展示分类列表 */
    CATEGORY_LIST: '/displayCategory/list',
    /** 通过id查展示分类 */
    CATEGORY_BY_PARENT: '/displayCategory/:id',
    /** 通过id查面包屑 */
    CATEGORY_BREADCRUMB: '/displayCategory/bread/:id',
    /** 商品列表查询搜索条件 */
    PRODUCT_FILTER: '/product/filter',
    /** 商品列表 */
    PRODUCT_LIST: '/product/open/filter',
    /** 发送验证码 */
    SEND_CAPTCHA: '/member/sendSmsCaptcha',
    /** 检查验证码 */
    CHECK_CAPTCHA: '/member/checkRandomCode',
    /** 会员注册 */
    REGISTER: '/member/register',
    /** 二次註冊 */
    SECONDARY_REGISTER: '/member/quadraticRegister',
    /** 发送邮件验证码 */
    SEND_EMAIL_CAPTCHA_BY_MEMBER_EXAMINE: '/member/sendEmailCaptchaByMemberExamine',
    /** 申请会员 */
    CREATE_MEMBER_EXAMINE: '/member/createMemberExamine',
    /** 获取会员审核状态 */
    GET_MEMBER_EXAMINE_STATUS: '/member/getMemberExamineStatus',
    /** 登出 */
    LOGOUT: '/member/loginOut',
    /** 账号密码登录 */
    LOGIN_PWD: '/member/loginByPassword',
    /** 验证手机号 */
    CHECK_MOBILE: '/member/checkMobile',
    /** 修改密码 */
    EDIT_PWD: '/member/editPassword',
    /** 创建订单 */
    CREATE_ORDER: '/checkout/orders',
    /** 商品详情 */
    PRODUCT_DETAIL: '/product/:csku/info',
    /** 商品详情面包屑 */
    PRODUCT_DETAIL_BREADCRUMB: '/displayCategory/goods/:csku',
    /** 推荐商品 */
    PRODUCT_RECOMMEND_PRODUCTS: '/product/:csku/recommendProduct',
    /** 获取AsiaPay的支付方式 */
    ASIA_PAY: '/payment/asiaPay/getMethod',
    /** 获取AsiaPay的支付参数 */
    GET_PAY_PARAM: '/payment/asiaPay/getPayParam',
    /** 购物车详情 */
    CART_DETAIL: '/carts/api/getCartInfo/new',
    /** 购物车加车 */
    CART_ADD: '/carts/api/item',
    /** 加入立即售卖特殊车 */
    CART_BUY_NOW_ADD: '/carts/api/singleCheckoutItem',
    /** 购物车数量 */
    CART_COUNT: '/carts/api/baskets/items/count',
    /** 购物车清空 */
    CART_CLEAN: '/carts/api/clearCart',
    /** 购物车修改商品数量 */
    CART_CHANGE_GOODS_COUNT: '/carts/api/replaceQuantity/:itemId/:goodsId/:quantity',
    /** 购物车选择商品 */
    CART_SELECT_GOODS: '/carts/api/:basketId/:itemId/selected',
    /** 购物删除商品 */
    CART_DELETE_GOODS: '/carts/api/deleteItem/:basketId/:itemId',
    /** 购物车全部选中 */
    CART_SELECTED_ALL: '/carts/api/selectedAll',
    /** 删除所有选中的商品 */
    CART_DELETE_SELECTED_ALL: '/carts/api/deleteMultiItem',
    /** 购物车切换门店 */
    CART_SYNC_STORE: '/carts/api/switchCartStore',
    /** 获取订单详情 */
    GET_ORDER_RECORD_DETAIL: '/orders/orderRecordDetail',
    /** 获取历史订单详情 */
    GET_HISTORY_ORDER_DETAIL: 'orders/historyOrderDetail',
    /** 查询订单状态数量 */
    ORDER_STATUS_NUM: '/orders/countWithStatus',
    /** 查询订单记录列表 */
    ORDER_LIST: '/orders/orderRecordList',
    /** 查询历史订单记录列表 */
    HISTORY_ORDER_LIST: '/orders/historyOrderList',
    /** 查询积分记录列表 */
    POINTS_RECORD_LIST: '/member/getMemberPointList',
    /** 首页配置 */
    CMS_HOME: '/homepage/new/homePage',
    /** 通过ids查询商品列表 */
    PRODUCT_LIST_IDS: '/product/index',
    CMS_TOPIC: '/homepage/new/topicPage/page/:id',
    /** 获取门店列表 */
    STORES: '/onlineStore',
    /** 获取当前门店 */
    CURRENT_STORE: '/onlineStore/detail',
    /** 查询優惠券列表 */
    COUPON_LIST: '/coupons/findOwnCoupons',
    /** 查询代金券列表 */
    VOUCHERS_LIST: '/vouchers/findAllOfOwn',
    /** 默认搜索词 */
    DEFAULT_KEYWORDS: '/homepage/searchWords',
    /** 获取首页通知信息 */
    NOTICE_MSG_LIST: '/homeFeature/type',
    /** 获取收藏商品列表 */
    GET_COLLECT_LIST: '/favorite',
    /** 刪除收藏商品 */
    DELETE_COLLECT: '/favorite/:sku',
    /** 收藏商品 */
    FAVORITE: '/favorite',
    /** 删除收藏商品 */
    DELFAVORITE: '/favorite/:sku',
    /** 获取热词列表 */
    HOT_WORDS: '/product/hotWords',
    /** 获取联想词列表 */
    PREDICTION_WORDS: 'product/search/words',
    /** 个人待评论列表 */
    WAIT_EVALUATION_LIST: '/product/evaluation/await/member',
    /** 查询已評價列表 */
    EVALUATED_LIST: '/product/evaluation/member',
    /** 添加评论 */
    ADD_EVALUATION: '/product/evaluation/add',
    /** 上传 */
    Upload: '/image_locations/1/images',
    /** 上传凭证图片 */
    UPLOAD_PROOF: '/image_locations/4/images',
    /** 查询可售后商品列表 */
    APPLY_AFTER_SALES_LIST: '/afterSales/findCanApplyList',
    /** 查询售后单列表 */
    AFTER_SALES_LIST: '/afterSales',
    /** miniCart */
    MINI_CART: '/carts/api/miniCart',
    /** (可使用)优惠券列表 */
    CAN_USE_COUPONS: '/coupons/findOwnCouponsCanUse',
    /** 结算积分详情 */
    CHECKOUT_POINT_DETAIL: '/checkout/point/detail',
    /** 结算积分核销 */
    CHECKOUT_POINT_WRITE_OFF: '/checkout/point/writeOff',
    /** 结算地址列表 */
    CHECKOUT_ADDRESS_LIST: '/checkout/memberAddress/findByMember',
    /** 选中购物车某一个多品促销  */
    CART_SELECT_PROMOTION: '/carts/api/:basketId/:promotionId/selectedByPromotion',
    /** 校验券码 */
    COUPON_CODE_CAN_USE: '/coupons/codeCanUse',
    /** 查询订单商品项 */
    ORDER_ITEM_PRODUCT: '/orders/orderItemProduct/:orderItemId',
    /** 查询领券中心列表 */
    RECEIVE_COUPON_LIST: '/coupons',
    /** 领券中心-领取 */
    RECEIVE_COUPON: '/coupons/check',
    /** 商品评论 */
    PRODUCT_EVALUATION: '/product/evaluation/:csku',
    /** 商品 seo 配置 */
    PRODUCT_SEO_CONFIG: '/seo/csku/:csku',
    /** 售后单详情 */
    AFTER_SALES: '/afterSales',
    /** 购物车多品换购加车 */
    CART_MULTI_ADD: '/carts/api/itemMulti',
    /** 登录认证配置 */
    MEMBER_KEYCLOAK_CONFIG: '/member/checkAuthorization',
    /** 用户基本信息 */
    BASE_MEMBER_INFO: '/member/basicsMemberInfo',
    /** 推荐品牌 */
    RECOMMENDED_BRAND: '/homeFeature/brand',
    /** 合作商户 */
    MERCHANT: '/product/merchant/:id',
    /** 站内信列表 */
    MESSAGE_LIST: '/stationMessage',
    /** 站内信详情 */
    MESSAGE_EDTAIL: '/stationMessage/:id',
    /** 站内信未读消息数量 */
    MESSAGE_COUNT: '/stationMessage/count',
    /** 站内信已读 */
    MESSAGE_READ: '/stationMessage/read/:id',
    /** 弹窗广告 */
    POPUP_AD: '/advertisements/highestPriorityAdvertisement',
    /** 搭配商品 */
    MATCHING_PRODUCTS: '/product/productMatch',
    /** 线下转账上传凭证 */
    UPLOAD_PAYMENT: '/payment/updatePaymentVoucher',
    /** 支付凭证回显 */
    PAYMENT_VOUCHER_IMG: '/orders/paymentVoucher/:parentOrderNumber',
    /** 到货通知 */
    DELIVERY_NOTICE: '/product/delivery/notice'
};

import { isEqual, throttle } from 'lodash-es';
/**
 * 响应式监听器
 */
export class ResponsiveObserver {
    /**
     *
     * @param callback 监听回调
     * @param breakpoint 断点信息
     */
    constructor(callback, options) {
        this._listener = () => {
            const width = this._target instanceof HTMLElement
                ? this._target.clientWidth
                : this._target.innerWidth ||
                    this._target.document.documentElement.clientWidth ||
                    this._target.document.body.clientWidth;
            const screens = this._getScreens(width);
            if (!isEqual(this._lastScreens, screens)) {
                this._lastScreens = screens;
                this._callback(screens);
            }
        };
        this._state = 'pending';
        this._callback = callback;
        this._mode = options.mode || 'latest';
        this._breakpoints = options.breakpoints;
        this._listener = throttle(this._listener, 20);
    }
    _getScreens(width = 0) {
        const breakpoints = Object.entries(this._breakpoints);
        const screens = Object.fromEntries(breakpoints.map(([name]) => [name, false]));
        for (let index = 0; index < breakpoints.length; index++) {
            const [name, min] = breakpoints[index];
            // 遇到不可激活的情况可直接跳过遍历，因为后续断点一定不符合可激活条件
            if (min > width)
                continue;
            screens[name] = true;
            // 启用激活离得最近的模式时，使前一个失活
            if (this._mode === 'latest' && index > 0) {
                const [name] = breakpoints[index - 1];
                screens[name] = false;
            }
        }
        return screens;
    }
    /**
     * 监听
     *
     * @param target 目标元素
     */
    observe(target) {
        if (this._state === 'pending') {
            this._state = 'activated';
            this._target = target;
            this._listener();
            this._target.addEventListener('resize', this._listener);
        }
    }
    /**
     * 取消监听
     */
    disconnect() {
        if (this._state === 'activated') {
            this._state = 'destroy';
            this._target.removeEventListener('resize', this._listener);
        }
    }
}

import { MessageApi } from '@common/api/message';
import { classNames } from '@common/application/utils/classnames';
import { ROUTE_MAP } from '@common/constants/route-map';
import Link from 'next/link';
import { useEffect, useState } from 'react';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';
import { WhiteLineIcon } from '@/resources/sprites-images/white-line-icon';

export const Notice: React.FC<
  React.PropsWithStyle<{
    dark?: boolean;
  }>
> = ({ className, dark }) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    MessageApi.count().then((res) => {
      setCount(res.count);
    });
  }, []);

  return (
    <Link href={ROUTE_MAP.MESSAGE}>
      <span
        className={classNames(
          'relative grid place-items-center',
          'w-7 h-7',
          'pc:w-9 pc:h-9',
          className
        )}
      >
        {dark ? (
          <BlackLineIcon className="w-full h-full" icon="notice" />
        ) : (
          <WhiteLineIcon className="w-full h-full" icon="notice" />
        )}
        {Boolean(count) && (
          <div
            className={classNames(
              'absolute right-2  grid place-items-center bg-primary rounded-full',
              'w-1 h-1 top-1 right-1.5',
              'pc:w-2 pc:h-2 pc:top-0.75 pc:right-2'
            )}
          ></div>
        )}
      </span>
    </Link>
  );
};

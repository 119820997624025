import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { useInRN } from '@common/application/hooks/use-in-rn';
import { useSSO } from '@common/application/store/common';
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
import { useRouter } from 'next/router';
import type { MutableRefObject } from 'react';
import { useMemo, useRef } from 'react';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';

import { Cart } from './cart';
import { Category } from './category';
import { CategoryMobile } from './category-mobile';
import { HeaderNotice } from './header-notice';
import { Logo } from './logo';
import { Navigation } from './navigation';
import { Notice } from './notice';
import { Search } from './search';
import { User } from './user';

export interface HeaderProps {
  destroyCategory?: boolean;
  showUser?: boolean;
  showNotice?: boolean;
  /** 是否展示移动端HEAD搜索区域 */
  showHeadSearch?: boolean;
  children?: React.ReactNode;
}
const SearchElement = (props: {
  parentTriggerEl: MutableRefObject<HTMLDivElement | null>;
  isSearchPage?: boolean;
}) => {
  const { pc } = useBreakpoint();
  return !pc ? (
    <div className="flex items-center justify-between">
      <CategoryMobile />
      {!props.isSearchPage && <Logo className="w-23.25 ml-3" />}
    </div>
  ) : (
    <Search />
  );
};
export const Header: React.FC<HeaderProps> = (props) => {
  const {
    destroyCategory,
    showUser = true,
    showNotice = true,
    showHeadSearch = true,
    children
  } = props;
  const { mobile, pc } = useBreakpoint();
  const router = useRouter();
  const isRnWebViewState = useInRN();
  const sso = useSSO();

  const parentTriggerEl = useRef<HTMLDivElement | null>(null);

  const isSearchPage = useMemo(() => {
    return router.pathname === ROUTE_MAP.SEARCH;
  }, [router]);

  return (
    <>
      <HeaderNotice />
      {!isRnWebViewState && (
        <header ref={parentTriggerEl} className="w-full bg-white relative z-5">
          <div className="container mx-auto h-full">
            <div
              className={classNames(
                'relative flex items-center',
                'h-14 justify-between',
                'pc:h-22.5 pc:grid pc:grid-cols-12 pc:justify-center'
              )}
            >
              {pc && <Category parentTriggerEl={parentTriggerEl} />}
              <div className={classNames('hidden', 'pc:grid w-33.5 h-9 -translate-x-8')}>
                <Logo />
              </div>
              <div className={classNames('pc:col-start-4 pc:col-end-9 pc:pb-2')}>
                {showHeadSearch ? (
                  <SearchElement parentTriggerEl={parentTriggerEl} isSearchPage={isSearchPage} />
                ) : (
                  children
                )}
              </div>
              {showHeadSearch && (
                <div
                  className={classNames(
                    'flex items-center',
                    'gap-3',
                    'pc:grid grid-flow-col pc:col-span-4 pc:grid-flow-col pc:justify-self-end pc:gap-6'
                  )}
                >
                  {mobile && isSearchPage && <Search />}
                  {!pc && !isSearchPage && (
                    <BlackLineIcon
                      icon="search"
                      onClick={() => {
                        router.push(createPath(ROUTE_MAP.SEARCH_RESULT, { q: '' }));
                      }}
                      className="w-7 h-7 cursor-pointer"
                    />
                  )}
                  {sso.settled && (
                    <>
                      {showUser && <User dark />}
                      {showNotice && <Notice dark />}
                      <Cart dark />
                    </>
                  )}
                </div>
              )}
            </div>
          </div>
        </header>
      )}
      {/* 分割线 */}
      <div className={classNames('w-full bg-black-6', 'h-0.25')}></div>
      {!destroyCategory && <Navigation />}
    </>
  );
};

import { EVENT_NAME } from '@common/constants/event-name';
import { createEvent } from 'react-event-hook';
/**
 * 初始化PLP商品列表数量显示
 */
export const { useCartNumberListener, emitCartNumber: _emitCartNumber } = createEvent(EVENT_NAME.CART_NUMBER)({
    crossTab: false
});
/**
 * 更新miniCart角标数量显示
 */
export const { useUpdateCartNumberListener, emitUpdateCartNumber } = createEvent(EVENT_NAME.UPDATE_CART_NUMBER)({
    crossTab: false
});
/**
 * 重置PLP筛选价格
 */
export const { useResetPriceFilterListener, emitResetPriceFilter } = createEvent(EVENT_NAME.RESET_PRICE_FILTER)({
    crossTab: false
});
/**
 * PDP使用浏览器会退状态
 */
export const { usePopStateListener, emitPopState } = createEvent(EVENT_NAME.POP_STATET)({
    crossTab: false
});
/**
 * 重置mob端从pdp返回时的page
 */
export const { useResetPdpPageListener, emitResetPdpPage } = createEvent(EVENT_NAME.RESET_PDP_PAGE)({ crossTab: false });
/**
 * 提交订单时，触发未填写的时间输入框以及付款方式输入框显示红色边框
 */
export const { useSubmitOrderListener, emitSubmitOrder } = createEvent(EVENT_NAME.SUBMIT_ORDER)({
    crossTab: false
});
/**
 * plp点击筛选项搜索时，防止plp筛选项二次更新
 */
export const { usePlpFilterUpdateListener, emitPlpFilterUpdate } = createEvent(EVENT_NAME.PLP_FILTER_UPDATE)({
    crossTab: false
});
/**
 * 更新plp价格筛选
 */
export const { useUpdatePriceFilterListener, emitUpdatePriceFilter } = createEvent(EVENT_NAME.UPDATE_PRICE_FILTER)({ crossTab: false });
export const { useUnauthorizedListener, emitUnauthorized } = createEvent('Unauthorized')({
    crossTab: false
});
/**
 * pdp底部button是否触底
 */
export const { usePdpBtnFixedListener, emitPdpBtnFixed } = createEvent(EVENT_NAME.PDP_BTN_FIXED)({
    crossTab: false
});

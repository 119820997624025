import { last } from 'lodash-es';
export function checkQueryDep(query, pageType) {
    switch (pageType) {
        case 'brand':
            return query.brandId;
        case 'displayCategory':
        case 'virtualCategory':
            return last(query.path);
        case 'merchant':
            return query.merchantId;
        case 'promotion':
            return query.promotionId;
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { isGuest } from '@common/application/utils/is-guest';
import { Toast } from '@common/application/utils/ui/toast';
import { Loading } from '@common/components/common/loading';
import { ACCESS_TOKEN_KEY, IS_CLIENT, USER_STORAGE_KEY } from '@common/constants/common';
import env, { global } from '@common/constants/env';
import { API_LANGUAGE } from '@common/constants/language';
import axios from 'axios';
import https from 'https';
import { camelCase, get, isArray, isEqual, isObject, snakeCase } from 'lodash-es';
import { i18n } from 'next-i18next';
import { cleanParams, createUnique, isValidValue } from '../common';
import { Cookie } from '../cookie';
import { emitUnauthorized } from '../event';
// eslint-disable-next-line import/no-cycle
import { isInRn, logout } from '../rn-utils';
import axiosConfig from './config';
import { logError, logRequest, logResponse } from './log';
const MATCH_PATH_PARAMS = /:(\w+)/g;
/**
 * 将对象中所有 key 转换为指定命名格式
 *
 * @param data 需要转换的对象
 * @param formatter 命名格式化方法
 */
export const formatObjectKeys = (data, formatter) => {
    if (isArray(data)) {
        return data.map((item) => formatObjectKeys(item, formatter));
    }
    if (isObject(data)) {
        return Object.fromEntries(Object.entries(data).map(([k, v]) => [formatter(k), formatObjectKeys(v, formatter)]));
    }
    return data;
};
const axiosInstance = axios.create({
    baseURL: axiosConfig.baseUrl,
    timeout: axiosConfig.timeout,
    responseType: axiosConfig.responseType,
    transformResponse(data) {
        try {
            return formatObjectKeys(JSON.parse(data), camelCase);
        }
        catch (e) {
            return data;
        }
    },
    httpsAgent: new https.Agent({
        rejectUnauthorized: false
    })
});
let cancelTokenList = [];
// 请求拦截器
axiosInstance.interceptors.request.use((config) => __awaiter(void 0, void 0, void 0, function* () {
    // 全局CancelToken
    const source = axios.CancelToken.source();
    cancelTokenList.push(source);
    config.cancelToken = source.token;
    const _config = config;
    _config.requestTime = Date.now();
    logRequest(_config);
    try {
        const { hideLoading } = config;
        if (!hideLoading)
            Loading.show();
    }
    catch (e) {
        logError(e);
        return Promise.reject(e);
    }
    return _config;
}));
// 响应拦截器
axiosInstance.interceptors.response.use((res) => {
    // 输出日志
    const requestTime = res.config.requestTime || 0;
    const responseTime = Date.now();
    const timing = responseTime - requestTime;
    // 清空并处理全局CancelToken List
    cancelTokenList = cancelTokenList.filter((x) => x.token !== res.config.cancelToken);
    logResponse(res, timing);
    const { hideLoading } = res.config;
    if (!hideLoading)
        Loading.hide();
    return res;
}, (error) => __awaiter(void 0, void 0, void 0, function* () {
    const requestError = error.response;
    logError(error);
    if (!requestError) {
        Loading.hide();
        return Promise.reject();
    }
    const { data } = requestError;
    const { hideToast = false, hideLoading = false } = error.config;
    if (!hideLoading)
        Loading.hide();
    if (data && !hideToast && requestError.status !== 401) {
        Toast(get(data, 'errMsg') || '抱歉，系統或網絡異常，請稍後再試');
    }
    if (requestError.status === 401 && IS_CLIENT) {
        cancelTokenList.forEach((item) => item.cancel());
        if (isInRn()) {
            return logout();
        }
        if (!isGuest()) {
            yield axios.get(`/api/login?t=${new Date().getTime()}`, {
                headers: {
                    [USER_STORAGE_KEY]: createUnique(),
                    [ACCESS_TOKEN_KEY]: ''
                }
            });
        }
        emitUnauthorized();
    }
    return Promise.reject(requestError);
}));
const checkAndSetUserSession = (ctx) => {
    return ctx ? Cookie.get(ctx, USER_STORAGE_KEY) : global.userSession;
};
export class Api {
    constructor() {
        this.defaultHeader = {
            'Content-Type': 'application/json;charset=utf-8',
            'Accept-Language': env.DEFAULT_LOCAL,
            userSession: '',
            accessToken: '',
            'dch-format': env.DCH_FORMAT,
            subsiteId: env.SUBSITE_ID,
            onlineStoreCode: env.ONLINE_STORE_CODE
        };
    }
    setRequestHeaders(customerHeader) {
        this.defaultHeader = Object.assign(Object.assign({}, this.defaultHeader), customerHeader);
    }
    getRequestHeaders(ctx) {
        const userSession = checkAndSetUserSession(ctx);
        if (!isEqual(this.defaultHeader.userSession, userSession)) {
            this.setRequestHeaders({
                userSession
            });
        }
        const accessToken = ctx
            ? Cookie.get(ctx, ACCESS_TOKEN_KEY)
            : get(global, 'accessToken', '') || '';
        if (!isEqual(this.defaultHeader.accessToken, accessToken)) {
            this.setRequestHeaders({
                accessToken: isValidValue(accessToken) && accessToken !== 'undefined' ? accessToken : ''
            });
        }
        this.setRequestHeaders({
            'Accept-Language': (ctx ? API_LANGUAGE[ctx.locale] : API_LANGUAGE[i18n === null || i18n === void 0 ? void 0 : i18n.language]) ||
                env.DEFAULT_LOCAL
        });
        return this.defaultHeader;
    }
    get(url, data, config) {
        return this.request({ url, method: 'GET', data, config });
    }
    post(url, data, config) {
        return this.request({ url, method: 'POST', data, config });
    }
    put(url, data, config) {
        var _a;
        return this.request({
            url,
            method: 'POST',
            data,
            config: Object.assign(Object.assign({}, (config !== null && config !== void 0 ? config : {})), { headers: Object.assign({ xmo: 'PUT' }, ((_a = config === null || config === void 0 ? void 0 : config.headers) !== null && _a !== void 0 ? _a : {})) })
        });
    }
    delete(url, data, config) {
        var _a;
        return this.request({
            url,
            method: 'POST',
            data,
            config: Object.assign(Object.assign({}, (config !== null && config !== void 0 ? config : {})), { headers: Object.assign({ xmo: 'DELETE' }, ((_a = config === null || config === void 0 ? void 0 : config.headers) !== null && _a !== void 0 ? _a : {})) })
        });
    }
    request(options) {
        return __awaiter(this, void 0, void 0, function* () {
            const config = options.config || {};
            const { ignoreFormatKeys, ignoreCleanParams, ctx, headers } = config, otherConfig = __rest(config, ["ignoreFormatKeys", "ignoreCleanParams", "ctx", "headers"]);
            const matchParams = options.url.match(MATCH_PATH_PARAMS);
            let { url } = options;
            let params = cleanParams(options.data);
            if (ignoreCleanParams) {
                params = options.data;
            }
            if (matchParams) {
                matchParams.forEach((match) => {
                    const key = match.replace(':', '');
                    if (Reflect.has(params, key)) {
                        url = url.replace(match, Reflect.get(params, key));
                        Reflect.deleteProperty(params, key);
                    }
                });
            }
            params = ignoreFormatKeys ? params : formatObjectKeys(params, snakeCase);
            const requestConfig = Object.assign({ url, method: options.method, headers: Object.assign(Object.assign({}, this.getRequestHeaders(ctx)), headers) }, otherConfig);
            if (options.method === 'GET') {
                requestConfig.params = params;
            }
            else {
                requestConfig.data = params;
            }
            return new Promise((resolve, reject) => {
                axiosInstance
                    .request(requestConfig)
                    .then((response) => {
                    resolve(response.data);
                })
                    .catch((e) => {
                    reject(e);
                });
            });
        });
    }
    static errorHandle(e) {
        Toast(get(e, 'data.errMsg') || '抱歉，系統或網絡異常，請稍後再試');
    }
}
export default new Api();

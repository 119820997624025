export function scrollTop(options = {}) {
    const { el = document.documentElement || document.body, from = el.scrollTop, to = 0, duration = 500 } = options;
    const difference = Math.abs(from - to);
    const step = Math.ceil((difference / duration) * 50);
    return new Promise((resolve) => {
        function scroll(start, end, step) {
            if (start === end) {
                return resolve(undefined);
            }
            let d = start + step > end ? end : start + step;
            if (start > end) {
                d = start - step < end ? end : start - step;
            }
            el.scrollTop = d;
            window.requestAnimationFrame(() => scroll(d, end, step));
        }
        scroll(from, to, step);
    });
}

import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { productListBanners } from '@common/application/store/product-list';
import { classNames } from '@common/application/utils/classnames';
import { Button } from '@common/components/common/button';
import { EqualScaleView } from '@common/components/common/equal-scale-view';
import { DImage } from '@common/components/common/image';
import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { Swiper, SwiperSlide } from 'swiper/react';
export const Indicator = (props) => {
    const { total, current } = props;
    return (_jsx("div", Object.assign({ className: "relative" }, { children: _jsx("ul", Object.assign({ className: "absolute right-2 -top-8 z-2 flex items-center justify-center mb-0 w-full h-10" }, { children: Array.from({
                length: total
            }).map((_, index) => (_jsx("li", { className: classNames('inline-block ml-2 w-2 h-2 bg-white opacity-50 shadow-md rounded-full', {
                    'ml-0': index === 0,
                    'opacity-100': index === current
                }) }, index))) })) })));
};
export const BannerComponent = (props) => {
    const { className, banners: userBanners = [] } = props;
    const [realIndex, setRealIndex] = useState(0);
    const { pc } = useBreakpoint();
    const banners = pc
        ? userBanners.filter((item) => !!item.pc)
        : userBanners.filter((item) => !!item.mobile);
    function renderItem(item) {
        return (_jsxs(_Fragment, { children: [_jsx(EqualScaleView, Object.assign({ className: "block pc:hidden", aspectRatio: "4/1" }, { children: _jsx(Button, Object.assign({ className: "w-full h-full !p-0 !border-0 !rounded-none", color: "transparent", href: item.target, textClassName: "w-full h-full" }, { children: _jsx(DImage, { src: item.mobile, className: "w-full h-full", imgClassName: "object-cover", fill: true }) })) })), _jsx(EqualScaleView, Object.assign({ className: "hidden pc:block", aspectRatio: "5/1" }, { children: _jsx(Button, Object.assign({ className: "w-full h-full !p-0 !border-0 !rounded-none", color: "transparent", href: item.target, textClassName: "w-full h-full" }, { children: _jsx(DImage, { src: item.pc, className: "w-full h-full", imgClassName: "object-cover", fill: true }) })) }))] }));
    }
    if (!banners.length) {
        return null;
    }
    if (banners.length === 1) {
        return _jsx("div", Object.assign({ className: classNames('w-full', className) }, { children: renderItem(banners[0]) }));
    }
    return (_jsxs("div", Object.assign({ className: classNames('relative w-full', className) }, { children: [_jsx(Swiper, Object.assign({ className: "w-full", autoplay: true, loop: true, resizeObserver: true, onRealIndexChange: (swiper) => setRealIndex(swiper.realIndex) }, { children: banners.map((item) => (_jsx(SwiperSlide, { children: renderItem(item) }, item.id))) })), _jsx(Indicator, { total: banners.length, current: realIndex })] })));
};
export const Banner = () => {
    return _jsx(BannerComponent, { className: "mb-3", banners: useRecoilValue(productListBanners) });
};

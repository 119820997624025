import { Cookie } from '@common/application/utils/cookie';
import {
  MEMBER_STORE_URL,
  ONLINE_STORE_CODE_STORAGE_KEY,
  STORAGE_PREFIX
} from '@common/constants/common';
import env, { cookieOptions } from '@common/constants/env';
import { parse } from 'cookie';
import { AES, enc } from 'crypto-ts';
import { get } from 'lodash-es';

export default async function setCookieMiddleware(ctx: any) {
  const { req } = ctx;
  const cryptoKey = STORAGE_PREFIX;

  const cookies = parse(req?.headers?.cookie || '');

  const cookieVserion = get(cookies, `${STORAGE_PREFIX}cookieVserion`);

  if (
    (cookieVserion &&
      AES.decrypt(cookieVserion, cryptoKey).toString(enc.Utf8) !== env.APP_VERSION) ||
    !cookieVserion
  ) {
    Object.keys(cookies).forEach((i) => {
      if (i.indexOf(STORAGE_PREFIX) > -1) {
        Cookie.set(ctx, i.replace(STORAGE_PREFIX, ''), '', -1, cookieOptions);
      }
    });
    Cookie.set(ctx, 'cookieVserion', env.APP_VERSION, 365, cookieOptions);
  }

  // 如果是elite路径且cookie中不含有门店信息，将elite门店设置在cookie中
  if (
    (req?.url || '').includes(`/${MEMBER_STORE_URL}`) &&
    get(cookies, `${STORAGE_PREFIX}${ONLINE_STORE_CODE_STORAGE_KEY}`) !== env.MEMBER_STORE_CODE
  ) {
    Cookie.set(ctx, ONLINE_STORE_CODE_STORAGE_KEY, env.MEMBER_STORE_CODE, 365, cookieOptions);
  }
}

import { CartApi } from '@common/api/cart';
import { useMemoizedFn } from 'ahooks';
import axios from 'axios';
import { useRef } from 'react';
import { atom, selector, useSetRecoilState } from 'recoil';
import { useInRN } from '../hooks/use-in-rn';
import { addCart } from '../utils/rn-utils';
import { RECOIL_KEY } from './key';
/**
 * 购物车详情
 */
export const cartDetail = atom({
    key: RECOIL_KEY.CART_DETAIL,
    default: undefined
});
/**
 * 购物车详情
 */
export const cartCount = atom({
    key: RECOIL_KEY.CART_COUNT,
    default: 0
});
/**
 * 用于展示的购物车数量 1 -> 99+
 */
export const displayCartCount = selector({
    key: RECOIL_KEY.DISPLAY_CART_COUNT,
    get({ get }) {
        const count = get(cartCount);
        const countStr = count > 99 ? '99+' : String(count);
        return count ? countStr : '';
    }
});
/**
 * 更新购物车数量
 */
export function useUpdateCartCount() {
    const setCartCount = useSetRecoilState(cartCount);
    const isRNWebViewState = useInRN();
    /**
     * 取消令牌
     *
     * 用于解决连续多次发送请求导致的请求重复响应问题
     */
    const cancelTokenSource = useRef();
    return useMemoizedFn(() => {
        var _a;
        (_a = cancelTokenSource.current) === null || _a === void 0 ? void 0 : _a.cancel();
        cancelTokenSource.current = axios.CancelToken.source();
        return CartApi.getCount({
            cancelToken: cancelTokenSource.current.token
        })
            .then((res) => {
            setCartCount(res);
            /**
             * 触发RN加车
             */
            if (isRNWebViewState) {
                addCart();
            }
        })
            .then(() => {
            cancelTokenSource.current = undefined;
        });
    });
}
/**
 * 更新购物车详情
 */
export function useUpdateCartDetail() {
    const setCartDetail = useSetRecoilState(cartDetail);
    return useMemoizedFn(() => CartApi.getDetail().then(setCartDetail));
}

import { useMemoizedFn, useUnmount } from 'ahooks';
import { useRef } from 'react';
import { IS_CLIENT } from '../../constants/common';
/**
 * 创建只会在浏览器下一次重绘之前执行的函数
 *
 * 此功能依赖 [requestAnimationFrame](https://developer.mozilla.org/zh-CN/docs/Web/API/Window/requestAnimationFrame) 而得以实现
 *
 * @param callback 回调函数
 * @param options 配置选项
 */
export function useRafFn(callback, options = {}) {
    const { singleCall = true } = options;
    const rafId = useRef();
    function clean() {
        if (rafId.current !== undefined) {
            window.cancelAnimationFrame(rafId.current);
            rafId.current = undefined;
        }
    }
    useUnmount(clean);
    return useMemoizedFn((...args) => {
        if (!IS_CLIENT)
            return callback(...args);
        if (singleCall)
            clean();
        rafId.current = window.requestAnimationFrame(() => {
            rafId.current = undefined;
            return callback(...args);
        });
    });
}

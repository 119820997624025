var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { DELIVERY_TYPE } from '@common/constants/checkout';
import { useTranslation } from 'next-i18next';
import { useMemo } from 'react';
export const DeliveryTag = (props) => {
    const { className, type, homeDeliveryBgColor, storePickupBgColor } = props, spanProps = __rest(props, ["className", "type", "homeDeliveryBgColor", "storePickupBgColor"]);
    const { t } = useTranslation();
    const tag = useMemo(() => {
        switch (+type) {
            case DELIVERY_TYPE.HOME_DELIVERY:
                return {
                    name: t('common:deliveryOnly'),
                    bgColor: homeDeliveryBgColor
                };
            case DELIVERY_TYPE.STORE_PICKUP:
                return {
                    name: t('common:pickupOnly'),
                    bgColor: storePickupBgColor
                };
            default:
                return null;
        }
    }, [type]);
    if (!tag)
        return null;
    return (_jsx("span", Object.assign({}, spanProps, { className: classNames('px-1.5 py-0.5 text-xs text-white !leading-none rounded-full', 'pc:px-2 pc:py-1 pc:text-sm', className, tag.bgColor) }, { children: tag.name })));
};

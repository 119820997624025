import { classNames } from '@common/application/utils/classnames';
import { useSearchHistory } from '@common/components/hook/header/use-search-history';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'next-i18next';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';

interface Props {
  onListChange?: (list: string[]) => void;
  clickItemHistory?(value: string): void;
}

export const SearchHistory = ({ onListChange, clickItemHistory }: Props) => {
  const { t } = useTranslation('common');
  const { historyList, handleClearSearchHistory, handleSearch } = useSearchHistory({
    onListChange,
    clickItemHistory
  });

  return isEmpty(historyList) ? (
    <></>
  ) : (
    <div className="w-full pc:p-4 pc:pb-3">
      <div className={'flex justify-between items-center'}>
        <div
          className={classNames(
            'text-base font-medium text-black-1',
            'pc:text-sm pc:font-normal pc:text-black-3'
          )}
        >
          {t('searchExtend.history')}
        </div>
        <div className="cursor-pointer" onClick={handleClearSearchHistory}>
          <div className="text-xs text-primary hidden pc:block">
            {t('searchExtend.deleteHistory')}
          </div>
          <BlackLineIcon className="w-4 h-4 pc:hidden" icon="garbage" />
        </div>
      </div>
      <div
        className={classNames('flex flex-wrap -mr-4 overflow-hidden', 'max-h-22', 'pc:max-h-16')}
      >
        {historyList.map((item, index) => (
          <div
            key={index}
            className={classNames(
              'mt-3 mr-3 text-black-1 cursor-pointer',
              'px-3 py-1.5 rounded-full bg-black-9 text-xs',
              'pc:p-0 pc:rounded-none pc:bg-transparent text-sm'
            )}
            onClick={() => handleSearch(item)}
          >
            <span className="line-clamp-1">{item}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

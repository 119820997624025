import { useUser } from '@common/application/store/user';
import { globalAuth } from '@common/application/utils/auth';
import { classNames } from '@common/application/utils/classnames';
import { DImage } from '@common/components/common/image';
import { ROUTE_MAP } from '@common/constants/route-map';
import Link from 'next/link';

import { BlackLineIcon } from '@/resources/sprites-images/black-line-icon';
import { WhiteLineIcon } from '@/resources/sprites-images/white-line-icon';

export const User: React.FC<{
  className?: string;
  dark?: boolean;
}> = ({ className, dark }) => {
  const { user } = useUser();

  return user?.id ? (
    <Link
      className={classNames(
        'relative grid place-items-center',
        'w-7 h-7',
        'pc:w-9 pc:h-9',
        className
      )}
      href={ROUTE_MAP.ACCOUNT}
    >
      {user.picUrl ? (
        <DImage className="w-full h-full" imgClassName="rounded-full" src={user.picUrl} fill />
      ) : dark ? (
        <BlackLineIcon className="w-full h-full" icon="user" />
      ) : (
        <WhiteLineIcon className="w-full h-full" icon="user" />
      )}
    </Link>
  ) : (
    <a
      onClick={() => globalAuth.login()}
      className={classNames(
        'pc:w-9 pc:h-9 relative grid place-items-center cursor-pointer',
        'w-6 h-6',
        className
      )}
    >
      {dark ? (
        <BlackLineIcon className="w-full h-full" icon="user" />
      ) : (
        <WhiteLineIcon className="w-full h-full" icon="user" />
      )}
    </a>
  );
};

import { classNames } from '@common/application/utils/classnames';
import { useSearchPrediction } from '@common/components/hook/header/use-search-prediction';
import { isEmpty } from 'lodash-es';

interface Props {
  keyword: string;
  onListChange?: (list: string[]) => void;
}

export const SearchPrediction = ({ keyword, onListChange }: Props) => {
  const { predictionList, handleSearch } = useSearchPrediction({ keyword, onListChange });

  return isEmpty(predictionList) ? (
    <></>
  ) : (
    <div className={classNames('w-full')}>
      {predictionList?.map((item, index) => (
        <div
          key={index}
          className={classNames(
            'flex items-center cursor-pointer text-black-1 text-sm border-b border-black-6 h-13',
            'px-3',
            'pc:px-4'
          )}
          onClick={() => handleSearch(item)}
        >
          <span className="line-clamp-1">{item}</span>
        </div>
      ))}
    </div>
  );
};

import { jsx as _jsx } from "react/jsx-runtime";
import { i18n, I18nContext } from 'next-i18next';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { safeElement } from './safe-element';
const containerMap = new WeakMap();
const I18nNext = (props) => {
    const { children } = props;
    return i18n ? (_jsx(I18nContext.Provider, Object.assign({ value: {
            i18n
        } }, { children: children }))) : (safeElement(children));
};
export function render(node, container) {
    const root = createRoot(container);
    root.render(_jsx(I18nNext, { children: node }));
    containerMap.set(container, root);
}
export function unmount(container) {
    return Promise.resolve().then(() => {
        var _a;
        (_a = containerMap.get(container)) === null || _a === void 0 ? void 0 : _a.unmount();
        containerMap.delete(container);
    });
}

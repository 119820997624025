import 'swiper/css';

import { classNames } from '@common/application/utils/classnames';
import { useSearch } from '@common/components/hook/header/use-search';
import { ROUTE_MAP } from '@common/constants/route-map';
import Link from 'next/link';
import { useTranslation } from 'next-i18next';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { SearchHistory } from '@/components/common/header/search-history';
import { SearchPrediction } from '@/components/common/header/search-prediction';
import { GreyFillIcon } from '@/resources/sprites-images/grey-fill-icon';
import { WhiteLineIcon } from '@/resources/sprites-images/white-line-icon';

SwiperCore.use([Autoplay]);

export const Search = () => {
  const { t } = useTranslation(['common']);
  const {
    searchBoxVisible,
    setSearchBoxVisible,
    hasDefaultKeywords,
    search,
    setSearch,
    handleSearch,
    defaultKeywordIndex,
    setDefaultKeywordIndex,
    hotList,
    defaultKeywords,
    pc
  } = useSearch();

  return (
    <div className={classNames('relative h-full', 'w-42.75 px-0 mr-1', 'pc:w-full pc:mr-0')}>
      <div
        className={classNames(
          'w-full flex justify-between items-center border border-black-7 pr-0',
          'h-8 pl-3 rounded-full',
          'pc:h-11 pc:rounded-full'
        )}
      >
        <div className="flex-1 relative h-full">
          <div className="relative w-full h-full flex items-center">
            <Link
              className="absolute top-0 bottom-0 left-0 right-0 z-3 pc:hidden"
              href={ROUTE_MAP.SEARCH}
            ></Link>
            <input
              className="relative flex-1 z-2 outline-none border-none bg-transparent placeholder:text-sm placeholder:text-black-4 text-black-1 w-4/5 pc:w-full"
              placeholder={!hasDefaultKeywords ? t('header.searchPlaceholder') : ''}
              value={search || ''}
              onInput={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                setSearch(e.target.value);
              }}
              onFocus={() => setSearchBoxVisible(true)}
              onBlur={() => setSearchBoxVisible(false)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleSearch();
                }
              }}
            />
            {search && (
              <div
                className={classNames('items-center', 'hidden', 'pc:flex')}
                onClick={() => {
                  setSearch('');
                }}
              >
                <GreyFillIcon icon="close" className="w-5 h-5 mr-3 cursor-pointer" />
              </div>
            )}
          </div>
          {hasDefaultKeywords && !search && pc && (
            <Swiper
              className={classNames('!absolute top-0 w-6/7 h-full', 'left-7', 'pc:left-1')}
              initialSlide={defaultKeywordIndex}
              direction="vertical"
              autoplay
              loop
              onRealIndexChange={(swiper) => setDefaultKeywordIndex(swiper.realIndex)}
            >
              {defaultKeywords.map((keyword, index) => (
                <SwiperSlide
                  key={`${keyword}_${index}`}
                  className="flex items-center w-full h-full overflow-hidden"
                >
                  <span className="line-clamp-1 h-5 text-sm text-black-4">{keyword}</span>
                </SwiperSlide>
              ))}
            </Swiper>
          )}
        </div>
        <div
          className={classNames(
            'bg-ahaa-color rounded-r-5.5 place-items-center cursor-pointer rounded-sm grid',
            'w-9.25 h-8',
            'pc:w-12 pc:h-11'
          )}
          onClick={() => handleSearch()}
        >
          <WhiteLineIcon icon="search" className="w-7.5 h-7.5" />
        </div>
      </div>
      {pc && (
        <>
          <div className="absolute left-0 right-0 px-4 text-black-4 text-sm mt-1 overflow-hidden line-clamp-1 overflow-ellipsis">
            {hotList.map((value, index) => (
              <span
                className="cursor-pointer mr-4"
                onClick={() => {
                  setSearch(value);
                  setTimeout(() => {
                    handleSearch(value);
                  }, 200);
                }}
                key={index}
              >
                {value}
              </span>
            ))}
          </div>
          <div
            className={classNames(
              'hover:block absolute z-10 left-0 right-0 bg-white text-sm text-black-1 shadow-search-sm',
              {
                hidden: !searchBoxVisible
              }
            )}
          >
            {search ? (
              <SearchPrediction keyword={search} />
            ) : (
              <SearchHistory clickItemHistory={(value) => setSearch(value)} />
            )}
          </div>
        </>
      )}
    </div>
  );
};

import { useEventListener, useMemoizedFn } from 'ahooks';
import { isBoolean } from 'lodash-es';
import { useRef, useState } from 'react';
import getScrollBarSize from '../utils/get-scroll-bar-size';
import { unref } from '../utils/unref';
import { useClientLayoutEffect } from './use-client-layout-effect';
/**
 * 锁定元素的滚动
 *
 * @param target 目标元素
 * @param initialState 初始状态
 */
export function useScrollLock(target, initialState = false) {
    const targetEl = unref(target);
    const [isLocked, setIsLocked] = useState(initialState);
    const initialOverflow = useRef();
    const initialWidth = useRef();
    const lock = () => {
        if (targetEl) {
            let scrollBarSize = 0;
            if ((targetEl === document.body &&
                window.innerWidth - document.documentElement.clientWidth > 0) ||
                targetEl.scrollHeight > targetEl.clientHeight) {
                scrollBarSize = getScrollBarSize();
            }
            initialOverflow.current = targetEl.style.overflow;
            initialWidth.current = targetEl.style.width;
            targetEl.style.overflow = 'hidden';
            targetEl.style.width = scrollBarSize !== 0 ? `calc(100% - ${scrollBarSize}px)` : '';
        }
    };
    const unlock = () => {
        if (targetEl) {
            targetEl.style.overflow = initialOverflow.current;
            targetEl.style.width = initialWidth.current;
        }
    };
    useClientLayoutEffect(() => {
        if (isLocked) {
            lock();
        }
        else {
            unlock();
        }
        return unlock;
    }, [isLocked, targetEl]);
    const toggle = useMemoizedFn((nextValue) => {
        setIsLocked((prevValue) => (isBoolean(nextValue) ? nextValue : !prevValue));
    });
    const preventDefault = useMemoizedFn((rawEvent) => {
        if (isLocked) {
            const e = rawEvent || window.event;
            if (e.touches.length > 1)
                return true;
            if (e.stopPropagation)
                e.stopPropagation();
            return false;
        }
    });
    useEventListener('touchmove', preventDefault, {
        passive: false,
        target
    });
    return [isLocked, toggle];
}

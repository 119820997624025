import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const BlackLineIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    user: 1,
    notice: 2,
    search: 3,
    promotion: 4,
    service: 5,
    collect: 6,
    share: 7,
    catergory: 8,
    del: 9,
    material: 10,
    cart: 11,
    garbage: 12,
    edit: 13,
    plus: 14,
    'back-top': 15,
    store: 16
  }
});

/*
 * @Author: 朱璐
 * @Date: 2022-10-28 15:54:31
 * @Description: 根據value name生成options
 */
export function generateOptions(values, names, generator = (value, name) => ({
    id: value,
    name
})) {
    const options = [];
    for (const key of Object.keys(values)) {
        // 取出值
        const value = values[key];
        // 用值取出名
        const name = names[value];
        // 名存在就生成选项
        if (name) {
            const option = generator(value, name);
            options.push(option);
        }
    }
    return options;
}

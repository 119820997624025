import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-01 14:39:20
 * @Description: 滚动到顶部
 */
import { classNames } from '@common/application/utils/classnames';
import { scrollTop } from '@common/application/utils/scroll-top';
import { Button } from '@common/components/common/button';
import { throttle } from 'lodash-es';
import React, { useEffect, useState } from 'react';
export const ScrollToTop = ({ icon, className }) => {
    const [showBackToTop, setBackToTop] = useState(false);
    useEffect(() => {
        window.addEventListener('scroll', throttle(() => {
            const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            setBackToTop(scrollTop >= 500);
        }, 200));
    }, []);
    const scrollToTop = () => {
        scrollTop();
    };
    return (_jsx(_Fragment, { children: showBackToTop ? (_jsx(Button, Object.assign({ className: classNames('relative px-0 py-0 rounded-xl border-none bg-white opacity-90  shadow-back-top flex flex-col', 'my-2 w-10 h-10', 'pc:my-4 pc:w-15 pc:h-15', className), onClick: scrollToTop }, { children: icon }))) : null }));
};

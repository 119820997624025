import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const ColorFillIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    success: 1,
    error: 2,
    compare: 3,
    tip: 4,
    message: 5,
    star: 6,
    'grey-star': 7,
    'icon-tip': 8,
    warning: 9,
    whatsapp: 10
  }
});

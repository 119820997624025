import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { MinusLine } from '@common/components/icon/minus-line';
import { PlusLine } from '@common/components/icon/plus-line';
import { noop } from 'lodash-es';
import React from 'react';
import { Button } from '../button';
import { classNames } from './utils';
export const InputNumber = (props) => {
    const { className, btnClassName, disabled, value = 1, max = 99, min = 1, onChange = noop } = props;
    return (_jsxs("div", Object.assign({ className: classNames('inline-flex items-center w-31 h-9 border border-ahaa-black-4 bg-white rounded-sm', {
            'cursor-not-allowed': disabled
        }, className), onClick: (event) => {
            event.stopPropagation();
        } }, { children: [_jsx(Button, Object.assign({ className: classNames('flex items-center justify-center w-9 h-full bg-ahaa-black-6 border-none', btnClassName), disabled: disabled || value <= min, onClick: () => {
                    onChange(value - 1);
                } }, { children: _jsx(MinusLine, { className: "w-4 h-4 text-black-1 flex items-center" }) })), _jsx("span", Object.assign({ className: classNames('flex-1 flex items-center justify-center h-full text-sm text-black-1 border-l border-r border-ahaa-black-4', {
                    'opacity-50': disabled
                }) }, { children: value })), _jsx(Button, Object.assign({ className: classNames('flex items-center justify-center w-9 h-full bg-ahaa-black-6 border-none', btnClassName), disabled: disabled || value >= max, onClick: () => {
                    onChange(value + 1);
                } }, { children: _jsx(PlusLine, { className: "w-4 h-4 text-black-1 flex items-center" }) }))] })));
};

export function getServerSideName(ctx, pageType) {
    const { query } = ctx;
    switch (pageType) {
        case 'displayCategory':
        case 'virtualCategory':
            return query.path.at(-3) || '';
        case 'search':
            return query.keywords || query.q || '';
        default:
            return query.name || '';
    }
}

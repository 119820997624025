import { USER_STORAGE_KEY } from '@common/constants/common';
import { global } from '@common/constants/env';
import { Cookie } from './cookie';
const guestRE = /^dch-[0-9]{30}$/;
/**
 * 当前处于游客登录环境
 */
export function isGuest(ctx) {
    const userSession = ctx ? Cookie.get(ctx, USER_STORAGE_KEY) : global.userSession;
    return userSession ? guestRE.test(userSession) : true;
}

/*
 * @Author: 朱璐
 * @Date: 2022-10-27 17:38:58
 * @Description: ServerSide方法 处理接口错误跳转 多语言
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CategoryApi } from '@common/api/category';
import { pageHomeCategory } from '@common/application/store/category';
import { ACCESS_TOKEN_KEY, ONLINE_STORE_CODE_STORAGE_KEY, USER_STORAGE_KEY } from '@common/constants/common';
import { get, isEmpty } from 'lodash-es';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { createUnique } from './common';
import { Cookie } from './cookie';
import { isGuest } from './is-guest';
export class ServerSide {
    static getServerSideProps(ctx) {
        return __awaiter(this, void 0, void 0, function* () {
            const i18nProps = yield serverSideTranslations(ctx.locale);
            const { query, params, req } = ctx;
            const referer = get(req, 'headers.referer', '');
            return {
                i18nProps,
                query,
                params,
                referer,
                error: {
                    destination: `/exceptional`,
                    permanent: false
                }
            };
        });
    }
    static createException(ctx, err) {
        return __awaiter(this, void 0, void 0, function* () {
            const error = {
                statusCode: get(err, 'status', get(err, 'statusCode', 500)),
                message: get(err, 'data.errMsg', get(err, 'message', '')),
                // image: get(err, 'image', ''),
                hideButton: get(err, 'hideButton', false)
            };
            const i18nProps = yield ServerSide.getTranslations(ctx, ['common']);
            if (error.statusCode === 401) {
                if (!isGuest(ctx)) {
                    Cookie.set(ctx, USER_STORAGE_KEY, createUnique());
                    Cookie.set(ctx, ACCESS_TOKEN_KEY, '');
                }
                ctx.res.writeHead(301, { Location: ctx.req.url });
                ctx.res.end();
                return {
                    props: Object.assign({}, i18nProps)
                };
            }
            const categoryList = yield ServerSide.getCategoryList(ctx);
            return {
                props: Object.assign(Object.assign({ redirect: error }, i18nProps), { recoilState: {
                        [pageHomeCategory.key]: categoryList
                    } })
            };
        });
    }
    static getTranslations(ctx, namespaces) {
        return __awaiter(this, void 0, void 0, function* () {
            const start = Date.now();
            const accessToken = Cookie.get(ctx, ACCESS_TOKEN_KEY, '');
            const userSession = Cookie.get(ctx, USER_STORAGE_KEY);
            const onlineStoreCode = Cookie.get(ctx, ONLINE_STORE_CODE_STORAGE_KEY);
            const i18nProps = yield serverSideTranslations(ctx.locale, namespaces);
            return Object.assign(Object.assign({}, i18nProps), { userSession, accessToken, onlineStoreCode, _speed: Date.now() - start });
        });
    }
    static getCategoryList(ctx) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const start = Date.now();
                const [categoryRes, recommendBrandList] = yield Promise.all([
                    CategoryApi.getList({ ctx }),
                    CategoryApi.getRecommendBrand({ ctx })
                ]);
                const _speed = Date.now() - start;
                if (isEmpty(categoryRes)) {
                    return { categoryList: [], recommendBrandList: recommendBrandList || [], _speed };
                }
                return {
                    categoryList: categoryRes[0].childTree,
                    recommendBrandList: recommendBrandList || [],
                    _speed
                };
            }
            catch (err) {
                return { categoryList: [], recommendBrandList: [] };
            }
        });
    }
}

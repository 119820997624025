var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CartApi } from '@common/api/cart';
import { PRODUCT_CART_COUNT } from '@common/constants/common';
import { isEmpty } from 'lodash-es';
import { _emitCartNumber } from './event';
import LocalStorage from './storage';
export function emitCacheCartNumber() {
    return __awaiter(this, void 0, void 0, function* () {
        const data = LocalStorage.getWithExpire(PRODUCT_CART_COUNT);
        if (isEmpty(data)) {
            const countStatistics = yield CartApi.getCartCountStatistics();
            LocalStorage.setWithExpire(PRODUCT_CART_COUNT, countStatistics, 1000 * 60 * 5);
            _emitCartNumber(countStatistics);
            return;
        }
        _emitCartNumber(data);
    });
}
export function emitCartNumber() {
    return __awaiter(this, void 0, void 0, function* () {
        const countStatistics = yield CartApi.getCartCountStatistics();
        LocalStorage.setWithExpire(PRODUCT_CART_COUNT, countStatistics, 1000 * 60 * 5);
        _emitCartNumber(countStatistics);
    });
}

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { IconCheckboxChecked } from '@common/components/icon/checkbox-checked';
import { IconCheckboxUnCheck } from '@common/components/icon/checkbox-unchecked';
import React from 'react';
export const Checkbox = ({ iconClassName, round, onChange, className, disabledClassName = 'text-gray-300', checked, disabled, children }) => {
    return (_jsxs("div", Object.assign({ className: classNames('inline-flex items-center', className) }, { children: [_jsx("button", Object.assign({ type: "button", className: classNames('flex-shrink-0 w-5 h-5 mr-2 cursor-pointer flex items-center disabled:cursor-not-allowed', {
                    'rounded-sm': round
                }, iconClassName), disabled: disabled, onClick: (e) => {
                    e.preventDefault();
                    if (onChange) {
                        onChange(!checked);
                    }
                } }, { children: checked && !disabled ? (_jsx(IconCheckboxChecked, { className: classNames('text-primary', iconClassName) })) : (_jsx(IconCheckboxUnCheck, { className: classNames(iconClassName, {
                        [disabledClassName]: disabled
                    }) })) })), children] })));
};
Checkbox.defaultProps = {
    checked: false,
    round: true
};

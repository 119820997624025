import { searchHistory, useAddSearchHistory, useClearSearchHistory } from '@common/application/store/search';
import { createPath } from '@common/application/utils/create-path';
import LocalStorage from '@common/application/utils/storage';
import { SEARCH_HISTORY_KEY } from '@common/constants/common';
import { ROUTE_MAP } from '@common/constants/route-map';
import Router from 'next/router';
import { useEffect } from 'react';
import { useRecoilState } from 'recoil';
export const useSearchHistory = ({ onListChange, clickItemHistory }) => {
    const [historyList, setHistoryList] = useRecoilState(searchHistory);
    const handleClearSearchHistory = useClearSearchHistory();
    const handleAddSearchHistory = useAddSearchHistory();
    const setList = (list) => {
        onListChange === null || onListChange === void 0 ? void 0 : onListChange(list);
        setHistoryList(list);
    };
    useEffect(() => {
        setList(LocalStorage.get(SEARCH_HISTORY_KEY, []));
    }, []);
    const handleSearch = (value) => {
        if (clickItemHistory)
            clickItemHistory(value);
        handleAddSearchHistory(value);
        Router.push(createPath(ROUTE_MAP.SEARCH_RESULT, { q: value }));
    };
    return {
        historyList,
        handleClearSearchHistory,
        handleSearch
    };
};

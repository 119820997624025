'use client';
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useClientValue } from '@common/application/hooks/use-client-value';
import { useScrollLock } from '@common/application/hooks/use-scroll-lock';
import { classNames } from '@common/application/utils/classnames';
import { isString, noop } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { Button } from './button';
import { DImage } from './image';
export const Modal = (props) => {
    const { t } = useTranslation();
    const { className, style, headerClassName, bodyClassName, footerClassName, destroyHeader, destroyFooter, destroyCloseIcon = true, color = 'primary', open, title, subTitle, zIndex = 10, children, confirmable = true, cancelable = true, maskCancelable = false, confirmText = t('common:confirm'), cancelText = t('common:cancel'), confirmProps = {}, cancelProps = {}, fixedBottomOnMobile, buttonRetainOnMobile = true, onConfirm = noop, onCancel = noop, closeIcon, closeIconClass, getContainer } = props;
    const container = useMemo(() => {
        try {
            return getContainer === null || getContainer === void 0 ? void 0 : getContainer();
        }
        catch (_a) {
            return null;
        }
    }, [getContainer]);
    const bodyEl = useClientValue(() => document.body);
    const [, toggle] = useScrollLock(bodyEl);
    useEffect(() => toggle(!!open), [open]);
    if (!open)
        return null;
    const buttonSizeChanged = fixedBottomOnMobile || !buttonRetainOnMobile;
    const modalEl = (_jsx("div", Object.assign({ className: "fixed top-0 left-0 flex items-center justify-center p-4 w-full h-full bg-[rgba(51,51,51,0.5)]", style: {
            zIndex
        }, onClick: () => {
            if (maskCancelable) {
                if (typeof (props === null || props === void 0 ? void 0 : props.onClose) === 'function') {
                    props.onClose();
                }
                else
                    onCancel();
            }
        } }, { children: _jsxs("section", Object.assign({ style: style, className: classNames('relative flex flex-col w-full max-h-full bg-white rounded-lg overflow-hidden pc:w-176', {
                'fixed left-0 right-0 bottom-0 rounded-bl-none rounded-br-none pc:relative pc:rounded-bl-lg pc:rounded-br-lg': fixedBottomOnMobile
            }, className), onClick: (e) => {
                e.preventDefault();
                e.stopPropagation();
            } }, { children: [!destroyCloseIcon && (_jsx(Button, { className: classNames('absolute top-3 right-4 px-0 py-0 w-6 h-6 border-none bg-transparent', 'pc:top-5', closeIconClass), icon: isString(closeIcon) ? _jsx(DImage, { src: closeIcon }) : closeIcon, onClick: () => {
                        if (typeof (props === null || props === void 0 ? void 0 : props.onClose) === 'function') {
                            props.onClose();
                        }
                        else
                            onCancel();
                    } })), !destroyHeader && (_jsxs("header", Object.assign({ className: classNames('flex-shrink-0 flex items-center justify-between font-medium text-black-1', 'p-4 text-base', 'pc:px-7.5 pc:pt-5 pc:pb-8 pc:text-xl', headerClassName) }, { children: [title, subTitle] }))), _jsx("main", Object.assign({ className: classNames('flex-1 w-full overflow-y-auto', bodyClassName) }, { children: children })), !destroyFooter && (confirmable || cancelable) && (_jsxs("footer", Object.assign({ className: classNames('flex-shrink-0 flex justify-center items-center', 'p-4', {
                        'py-3 ': buttonSizeChanged
                    }, 'pc:pt-6 pc:pb-5', footerClassName) }, { children: [cancelable && (_jsx(Button, Object.assign({ color: "black-5", dark: false }, cancelProps, { className: classNames('min-w-39 pc:w-auto h-10.5 pc:h-11 border-black-6 bg-black-6 text-black-1', {
                                'w-1/2': buttonSizeChanged
                            }, cancelProps.className), onClick: onCancel }, { children: cancelText }))), confirmable && (_jsx(Button, Object.assign({ color: color }, confirmProps, { className: classNames('ml-2 min-w-39 pc:ml-3 pc:w-auto h-10.5 pc:h-11 first:ml-0', {
                                'ml-2 w-1/2 first:ml-0': buttonSizeChanged
                            }, confirmProps.className), onClick: onConfirm }, { children: confirmText })))] })))] })) })));
    return container ? createPortal(modalEl, container) : modalEl;
};

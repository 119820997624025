import { CommonApi } from '@common/api/common';
import { ONLINE_STORE_CODE_STORAGE_KEY, ONLINE_STORE_ID_STORAGE_KEY } from '@common/constants/common';
import { useMemoizedFn } from 'ahooks';
import { useEffect, useRef } from 'react';
import { atom, useRecoilValue, useSetRecoilState } from 'recoil';
import LocalStorage from '../utils/storage';
import { RECOIL_KEY } from './key';
export const pageTitleParams = atom({
    key: RECOIL_KEY.PAGE_TITLE_PARAMS,
    default: {}
});
/**
 * 当前选中门店信息
 */
export const currentStore = atom({
    key: RECOIL_KEY.CURRENT_STORE,
    default: undefined
});
/**
 * sso认证状态
 */
export const ssoAuthenticatedStore = atom({
    key: RECOIL_KEY.SSO_AUTHENTICATED,
    default: { settled: false, login: false }
});
export function useUpdateCurrentStore() {
    const setCurrentStore = useSetRecoilState(currentStore);
    return useMemoizedFn(() => {
        const code = LocalStorage.get(ONLINE_STORE_CODE_STORAGE_KEY);
        return code
            ? CommonApi.getCurrentStore().then((store) => {
                // 兼容老用户
                LocalStorage.set(ONLINE_STORE_ID_STORAGE_KEY, store.id);
                setCurrentStore(store);
            })
            : setCurrentStore(undefined);
    });
}
export const globalIsRobot = atom({
    key: RECOIL_KEY.GLOBAL_IS_ROBOT,
    default: false
});
export function useSetSSOAuthenticated() {
    const setSSO = useSetRecoilState(ssoAuthenticatedStore);
    return {
        setSettled: (login) => {
            setSSO({ settled: true, login });
        }
    };
}
export function useSSO() {
    const isRobot = useRecoilValue(globalIsRobot);
    const ssoAuthenticated = useRecoilValue(ssoAuthenticatedStore);
    if (isRobot) {
        return { settled: true, login: false };
    }
    return ssoAuthenticated;
}
// 给个兜底，如果 30s 没有登录完成则认为未登录
export function useHandleFailureFallback() {
    const timer = useRef();
    const { settled } = useSSO();
    const { setSettled } = useSetSSOAuthenticated();
    useEffect(() => {
        if (settled) {
            clearTimeout(timer.current);
            return;
        }
        if (timer.current) {
            return;
        }
        timer.current = +setTimeout(() => {
            setSettled(false);
        }, 30 * 1000);
    }, [settled]);
}

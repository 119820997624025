import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-12 15:06:16
 * @Description: 价格组件
 */
import { currentStore } from '@common/application/store/common';
import { classNames } from '@common/application/utils/classnames';
import { formatPrice } from '@common/application/utils/common';
import { useRecoilValue } from 'recoil';
export const Price = ({ price = '0', className, symbolClassName, priceClassName, prefix = '' }) => {
    const store = useRecoilValue(currentStore);
    return (_jsxs("span", Object.assign({ className: classNames('text-sm text-black', className) }, { children: [prefix, _jsx("span", Object.assign({ className: symbolClassName }, { children: store ? store.currencyIsoCode : '$' })), _jsx("span", Object.assign({ className: priceClassName }, { children: formatPrice(price) }))] })));
};

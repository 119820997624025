var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-03 11:51:40
 * @Description: filter筛选区域
 */
import { productListBaseFilter, productListParams, useSearchProductList } from '@common/application/store/product-list';
import { classNames } from '@common/application/utils/classnames';
import { isEmpty } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import { useCallback, useMemo } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { BaseFilter } from './base-filter';
import { FilterTitle } from './filter-title';
import { PriceFilter } from './price-filter';
export const FilterContainer = () => {
    const filterOptions = useRecoilValue(productListBaseFilter);
    const otherFilter = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { brands, price } = filterOptions, attributes = __rest(filterOptions, ["brands", "price"]);
        return Object.keys(attributes).map((key) => (Object.assign({ type: key }, attributes[key])));
    }, [filterOptions]);
    const [filter, setFilter] = useRecoilState(productListParams);
    const { t } = useTranslation(['product-list']);
    const { search } = useSearchProductList();
    const filterChange = useCallback((type) => {
        search({ filterType: type });
    }, []);
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: "hidden pc:block" }, { children: _jsx(FilterTitle, { onChange: () => filterChange('price') }) })), _jsx(PriceFilter, { onChange: () => filterChange('price') }), _jsx(BaseFilter, { className: classNames('border-t border-black-7', 'pc:border-t-0'), type: "brand", title: t('brand'), expanded: true, options: filterOptions.brands, value: filter.brands, onChange: (values, isSearch) => {
                    if (isSearch)
                        filterChange('price');
                    setFilter((org) => (Object.assign(Object.assign({}, org), { brands: JSON.parse(JSON.stringify(values)) })));
                } }), (otherFilter || []).map((att, index) => {
                if (isEmpty(att.attributeValues))
                    return null;
                return (_jsx(BaseFilter, { type: "other", className: classNames({
                        'border-b border-black-7': index !== otherFilter.length - 1
                    }), title: att.attributeName, expanded: false, options: att.attributeValues || [], value: filter[att.type], onChange: (values, isSearch) => {
                        if (isSearch)
                            filterChange(att.type);
                        setFilter((org) => {
                            const a = new Set(org.attributesCategoryIds);
                            if (values.length <= 0) {
                                a.delete(att.offlineCategoryId);
                            }
                            else {
                                a.add(att.offlineCategoryId);
                            }
                            return Object.assign(Object.assign({}, org), { [att.type]: JSON.parse(JSON.stringify(values)), attributesCategoryIds: [...a] });
                        });
                    } }, att.attributeId + att.attributeName));
            })] }));
};

import { createPath } from '@common/application/utils/create-path';
import env from '@common/constants/env';
import { ROUTE_MAP } from '@common/constants/route-map';
import { i18n } from 'next-i18next';

/**
 * topic 文章页判断
 */
export const generatePath = (id: string, name?: string) => {
  if (!id) return '';
  return createPath(ROUTE_MAP.TOPIC, { id, name });
};

export const GlobalConfig = () => {
  return {
    footerLink: [
      {
        title: 'common:footerLink.aboutUs',
        list: [
          {
            text: 'common:footerLink.aboutahaa',
            href: generatePath(env.ABOUT_AHAA, i18n?.t('common:footerLink.aboutahaa'))
          },
          {
            text: 'common:footerLink.privacyPolicy',
            href: generatePath(env.SERVICE_POLICY, i18n?.t('common:footerLink.privacyPolicy'))
          },
          {
            text: 'common:footerLink.terms',
            href: generatePath(env.TERMS, i18n?.t('common:footerLink.terms'))
          },
          {
            text: 'common:footerLink.notice',
            href: generatePath(env.NOTICE, i18n?.t('common:footerLink.notice'))
          }
        ]
      },
      {
        title: 'common:footerLink.customerSupport',
        list: [
          {
            text: 'common:footerLink.FAQ',
            href: generatePath(env.FAQ, i18n?.t('common:footerLink.FAQ'))
          },
          {
            text: 'common:footerLink.send',
            href: generatePath(env.SEND, i18n?.t('common:footerLink.send'))
          },
          {
            text: 'common:footerLink.install',
            href: generatePath(env.INTALL, i18n?.t('common:footerLink.install'))
          },
          {
            text: 'common:footerLink.clean',
            href: generatePath(env.CLEAN, i18n?.t('common:footerLink.clean'))
          },
          {
            text: 'common:footerLink.refund',
            href: generatePath(env.REFUND, i18n?.t('common:footerLink.refund'))
          }
        ]
      },
      {
        title: 'common:footerLink.connectUs',
        list: [
          {
            text: 'common:footerLink.connectahaa',
            href: generatePath(env.CONNECT_AHAA, i18n?.t('common:footerLink.connectahaa'))
          },
          {
            text: 'common:footerLink.address',
            href: generatePath(env.ADDRESS, i18n?.t('common:footerLink.address'))
          }
        ]
      }
    ],
    footerPayLogo: [
      {
        icon: 'visa',
        href: ''
      },
      {
        icon: 'mostercard',
        href: ''
      },
      {
        icon: 'wechatpay',
        href: ''
      },

      {
        icon: 'payme',
        href: ''
      },
      {
        icon: 'alipay',
        href: ''
      }
      // {
      //   icon: 'unionpay',
      //   href: ''
      // },
      // {
      //   icon: 'octpay',
      //   href: ''
      // },
      // {
      //   icon: 'ame',
      //   href: ''
      // }
    ]
  };
};

import { useCategoryTop } from '@common/application/hooks/use-category-top';
import { DImage } from '@common/components/common/image';
import { useToggle } from 'ahooks';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

import { GoodsCategory } from '@/components/common/goods-category';
import { IMG_MENU, IMG_MENU_CLOSE } from '@/resources/images';

export const CategoryMobile = () => {
  const [open, openActions] = useToggle();

  const { isReady, categoryTop } = useCategoryTop();

  const router = useRouter();

  useEffect(() => {
    openActions.set(false);
  }, [router.query.path]);

  return (
    <div className="w-8 h-8 text-base">
      <div className="h-full flex items-center" onClick={openActions.toggle}>
        {!open ? <DImage src={IMG_MENU}></DImage> : <DImage src={IMG_MENU_CLOSE}></DImage>}
      </div>
      {isReady && (
        <GoodsCategory open={open} top={categoryTop} onHide={() => openActions.set(false)} />
      )}
    </div>
  );
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 朱璐
 * @Date: 2022-11-10 13:36:28
 * @Description: 分类api
 */
import request from '@common/application/utils/request';
import { noop } from 'lodash-es';
import { API_MAP } from './api-map';
export function formatBreadcrumb(obj, config, addPath = noop) {
    return __awaiter(this, void 0, void 0, function* () {
        if (!obj || !obj.id)
            return [];
        addPath(obj.name);
        const path = [obj.name];
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        const menu = yield CategoryApi.getListById(obj.id, config);
        return [
            ...(yield formatBreadcrumb(obj.parents, config, (pathItem) => {
                path.unshift(pathItem);
                addPath(pathItem);
            })),
            {
                id: obj.id,
                name: obj.name,
                path,
                menu,
                type: 'category'
            }
        ];
    });
}
export class CategoryApi {
    static getList(config) {
        return request.get(API_MAP.CATEGORY_LIST, {}, config);
    }
    static getListById(id, config) {
        return request.get(API_MAP.CATEGORY_BY_PARENT, { id }, config);
    }
    /**
     * 通过id查分类面包屑
     */
    static getBreadcrumb(id, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request
                .get(API_MAP.CATEGORY_BREADCRUMB, { id }, config)
                .then((res) => formatBreadcrumb(res, config));
        });
    }
    /**
     * 查询推荐品牌
     */
    static getRecommendBrand(config) {
        return request.get(API_MAP.RECOMMENDED_BRAND, {}, config);
    }
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ACCESS_TOKEN_KEY, ADD_CART, USER_STORAGE_KEY } from '@common/constants/common';
import axios from 'axios';
import { createUnique } from './common';
export function isInRn() {
    return typeof window !== 'undefined' && window.isRNWebView;
}
export function getToken() {
    return typeof window !== 'undefined' && window.user;
}
export function logout() {
    return __awaiter(this, void 0, void 0, function* () {
        yield axios.get(`/api/login?t=${new Date().getTime()}`, {
            headers: {
                [USER_STORAGE_KEY]: createUnique(),
                [ACCESS_TOKEN_KEY]: ''
            }
        });
        return window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'logout' }));
    });
}
export function login(options) {
    return __awaiter(this, void 0, void 0, function* () {
        return window.ReactNativeWebView.postMessage(JSON.stringify({
            type: 'login',
            params: {
                url: window.location.href,
                keycloakOption: options
            }
        }));
    });
}
export function addCart() {
    return window.ReactNativeWebView.postMessage(JSON.stringify({ type: ADD_CART }));
}
/**
 * 二次补全成功事件
 */
export function secondaryRegister() {
    return window.ReactNativeWebView.postMessage(JSON.stringify({ type: 'secondaryRegister' }));
}
/**
 * 获取支付参数
 * @param res 支付参数
 * @returns 触发webview监听函数
 */
export function getPayParams(res) {
    return window.ReactNativeWebView.postMessage(JSON.stringify(Object.assign({ type: 'payParams' }, res)));
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 朱璐
 * @Date: 2022-10-31 16:44:32
 * @Description: 公共api
 */
import request from '@common/application/utils/request';
import { UploadType } from '@common/types/common';
import { API_MAP } from './api-map';
export class CommonApi {
    /**
     * 获取手机号区号
     * @param config
     * @returns
     */
    static getAreaCode(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.AREA_CODE, {}, Object.assign({}, config));
        });
    }
    /**
     * 获取所有1级地址
     */
    static getRegion1s() {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.REGION_1, {}, { hideLoading: true });
        });
    }
    /**
     * 获取所有2级地址
     */
    static getRegion2s(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.REGION_2, params, { hideLoading: true });
        });
    }
    /**
     * 获取所有3级地址
     */
    static getRegion3s(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.REGION_3, params, { hideLoading: true });
        });
    }
    static getStoresParentRegions(type) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.STORES_PARENT_REGION, { type }, { hideLoading: true });
        });
    }
    static getStoresChildRegions({ parentRegionId = '', type = 0 }) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.STORES_REGION, { parentRegionId, type }, { hideLoading: true });
        });
    }
    /** 发送验证码 */
    static sendSmsCaptcha(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.SEND_CAPTCHA, Object.assign({}, params), config);
        });
    }
    /** 获取门店列表 */
    static getStores(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.STORES, params, config);
        });
    }
    /** 获取当前门店信息 */
    static getCurrentStore(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.CURRENT_STORE, {}, Object.assign({ hideLoading: true, hideToast: true }, config));
        });
    }
    /** 默认搜索词 */
    static getDefaultKeywords() {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.DEFAULT_KEYWORDS);
        });
    }
    /** 首页通知,导航信息 */
    static getNoticeMsgList(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.NOTICE_MSG_LIST, Object.assign({}, params), config);
        });
    }
    /** 获取热词列表 */
    static getHotWords(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.HOT_WORDS, {}, Object.assign({ hideLoading: true, hideToast: true }, config));
        });
    }
    /** 获取联系词列表 */
    static getPredictionWords(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.PREDICTION_WORDS, Object.assign({}, params), Object.assign({ hideLoading: true, hideToast: true }, config));
        });
    }
    /** 上传图片 */
    static upload(data, uploadType, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(uploadType === UploadType.NORMAL ? API_MAP.Upload : API_MAP.UPLOAD_PROOF, data, Object.assign({ hideLoading: true, hideToast: true, ignoreCleanParams: true, ignoreFormatKeys: true, headers: { 'Content-Type': 'multipart/form-data' } }, config));
        });
    }
    /** miniCart */
    static getminiCartList(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.MINI_CART, {}, Object.assign({ hideLoading: true }, config));
        });
    }
    /** 查询订单商品项 */
    static getOrderItemProduct(orderItemId, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.ORDER_ITEM_PRODUCT, { orderItemId }, Object.assign({}, config));
        });
    }
}

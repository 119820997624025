import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-15 16:36:37
 * @Description: 错误组件
 */
import { classNames } from '@common/application/utils/classnames';
import { ROUTE_MAP } from '@common/constants/route-map';
import { get } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import React, { cloneElement } from 'react';
import { Button } from './button';
export const ErrorPanel = (props) => {
    const { t } = useTranslation();
    const { className, icon, title, content, destroyMainButton, destroyButtons, destroyViceButton, mainButtonProps = {
        href: ROUTE_MAP.HOME
    }, mainButtonText = t('common:pageNotFound.backToHome'), viceButtonProps = {}, viceButtonText = '', contentClassName } = props;
    const iconEl = icon
        ? cloneElement(icon, {
            className: classNames('w-16 h-16', get(icon, 'props.className'))
        })
        : null;
    const showButtons = !destroyButtons && (!destroyMainButton || !destroyViceButton);
    return (_jsxs("div", Object.assign({ className: classNames('flex flex-col items-center my-8 px-4 pt-20 pb-30 bg-white rounded-lg', className) }, { children: [iconEl, title && _jsx("div", Object.assign({ className: "pt-6 text-[18px] text-black-1 font-semibold" }, { children: title })), content && (_jsx("div", Object.assign({ className: classNames('pt-2 text-base text-black-4 text-center', 'pc:w-80', contentClassName) }, { children: content }))), showButtons && (_jsxs("div", Object.assign({ className: "flex pt-10" }, { children: [!destroyViceButton && (_jsx(Button, Object.assign({ dark: false, color: "black-1" }, viceButtonProps, { className: classNames('w-34 h-9 text-sm border-black-7', viceButtonProps.className) }, { children: viceButtonText }))), !destroyMainButton && (_jsx(Button, Object.assign({}, mainButtonProps, { color: "black-1", className: classNames('ml-3 w-34 h-9 text-sm first:ml-0', mainButtonProps.className) }, { children: mainButtonText })))] })))] })));
};

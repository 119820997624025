import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const ProductFilterIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    'filter-default': 1,
    'filter-active': 2
  }
});

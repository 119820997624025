var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-10-28 09:49:07
 * @Description: Button
 */
import { classNames } from '@common/application/utils/classnames';
import Link from 'next/link';
import React from 'react';
export const Button = (props) => {
    const { className, textClassName, color = 'primary', dark = true, icon, rightIcon, children, href, as, replace, scroll, shallow, passHref, prefetch, locale, legacyBehavior } = props, buttonProps = __rest(props, ["className", "textClassName", "color", "dark", "icon", "rightIcon", "children", "href", "as", "replace", "scroll", "shallow", "passHref", "prefetch", "locale", "legacyBehavior"]);
    const colorClassName = classNames(`text-${color} border-${color}`, {
        [`text-white bg-${color}`]: dark
    });
    const wrapClassName = classNames('relative inline-flex items-center justify-center px-2 py-1 text-sm bg-white border rounded-sm active:opacity-80 disabled:cursor-not-allowed disabled:opacity-50', colorClassName, className);
    const wrapChildren = (_jsxs(_Fragment, { children: [icon, Boolean(children) && (_jsx("span", Object.assign({ className: classNames('mx-1 first:ml-0 last:mr-0', textClassName) }, { children: children }))), rightIcon] }));
    return href ? (_jsx(Link, Object.assign({}, buttonProps, { className: wrapClassName, href: href, as: as, replace: replace, scroll: scroll, shallow: shallow, passHref: passHref, prefetch: prefetch, locale: locale, legacyBehavior: legacyBehavior }, { children: wrapChildren }))) : (_jsx("button", Object.assign({}, buttonProps, { className: wrapClassName }, { children: wrapChildren })));
};

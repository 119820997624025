import { useState } from 'react';
import { useRecoilValue } from 'recoil';
import { breakpoints as defaultBreakpoints } from '../../constants/common';
import { globalDefaultBreakpoint } from '../store/global';
import { ResponsiveObserver } from '../utils/responsive-observer';
import { unref } from '../utils/unref';
import { useClientLayoutEffect } from './use-client-layout-effect';
/**
 * 获取容器断点信息
 */
export function useBreakpoint(target = () => window, options = {}) {
    const targetEl = unref(target);
    const { mode, breakpoints = defaultBreakpoints } = options;
    const defaultBreakpoint = useRecoilValue(globalDefaultBreakpoint);
    const [screens, setScreens] = useState(() => {
        return Object.fromEntries(Object.keys(breakpoints).map((key) => [key, key === defaultBreakpoint]));
    });
    useClientLayoutEffect(() => {
        if (targetEl) {
            const observer = new ResponsiveObserver(setScreens, {
                mode,
                breakpoints
            });
            observer.observe(targetEl);
            return () => observer.disconnect();
        }
    }, [targetEl, mode, breakpoints]);
    return screens;
}

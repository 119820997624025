import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const FooterIcon = SpritesImage.createIcon({
  itemWidth: 48,
  itemHeight: 48,
  images,
  icons: {
    open: 1,
    close: 2
  }
});

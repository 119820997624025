import { useElementBounding } from '@common/application/hooks/use-element-bounding';
import { IS_CLIENT } from '@common/constants/common';
import { useMemo } from 'react';
const getElement = (targetId) => {
    if (IS_CLIENT && targetId) {
        return document.getElementById(targetId);
    }
    return undefined;
};
export const useCategoryTop = () => {
    const { bottom, height, isReady } = useElementBounding(getElement('page-header'));
    const { height: noticeHeight } = useElementBounding(getElement('notice-header'));
    const { height: navigationBarHeight } = useElementBounding(getElement('navigation-bar'));
    const categoryTop = useMemo(() => {
        if (bottom > 0) {
            if (bottom > height - noticeHeight) {
                return bottom - navigationBarHeight;
            }
            return height - noticeHeight - navigationBarHeight;
        }
        return height - noticeHeight - navigationBarHeight;
    }, [bottom, noticeHeight, navigationBarHeight, isReady]);
    if (!IS_CLIENT)
        return {
            isReady: false,
            categoryTop: 0
        };
    return {
        isReady,
        categoryTop
    };
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import request from '@common/application/utils/request';
import { API_MAP } from './api-map';
export class MessageApi {
    /**
     * 站内信分頁
     */
    static page(page, perPage, config) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.MESSAGE_LIST, Object.assign(Object.assign({}, ((_a = config === null || config === void 0 ? void 0 : config.params) !== null && _a !== void 0 ? _a : {})), { page,
                perPage }), config);
        });
    }
    /**
     * 站内信详情
     */
    static get(id, config) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.MESSAGE_EDTAIL, Object.assign(Object.assign({}, ((_a = config === null || config === void 0 ? void 0 : config.params) !== null && _a !== void 0 ? _a : {})), { id }), config);
        });
    }
    /**
     * 站内信未读消息数量
     */
    static count(config) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.MESSAGE_COUNT, Object.assign({}, ((_a = config === null || config === void 0 ? void 0 : config.params) !== null && _a !== void 0 ? _a : {})), config);
        });
    }
    /**
     * 站内信已读
     */
    static read(id, config) {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            return request.put(API_MAP.MESSAGE_READ, Object.assign(Object.assign({}, ((_a = config === null || config === void 0 ? void 0 : config.params) !== null && _a !== void 0 ? _a : {})), { id }), config);
        });
    }
}

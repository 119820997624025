import { isFunction } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import { createElement, Fragment } from 'react';
/**
 * 将字符串翻译为节点，相当于高级插值版的 i18n t 函数
 */
export function useTranslationNode(ns) {
    const { t } = useTranslation(ns);
    return function translationNode(key, options, matchRE = /{{([^{}]+)}}/) {
        const nodes = [];
        let template = t(key, {
            interpolation: {
                skipOnVariables: true
            }
        });
        let matchResult;
        // eslint-disable-next-line no-cond-assign
        while ((matchResult = template.match(matchRE))) {
            const [{ length: matchLength }, propName] = matchResult;
            const beginIndex = matchResult.index;
            const endIndex = beginIndex + matchLength;
            if (beginIndex) {
                nodes.push(template.slice(0, beginIndex));
            }
            const nodeOrRenderer = options[propName];
            const node = isFunction(nodeOrRenderer) ? nodeOrRenderer() : nodeOrRenderer;
            if (node) {
                nodes.push(node);
            }
            template = template.slice(endIndex);
        }
        if (template) {
            nodes.push(template);
        }
        return createElement(Fragment, null, ...nodes);
    };
}

import { jsx as _jsx } from "react/jsx-runtime";
import { Modal } from '@common/components/common/modal';
import { noop } from 'lodash-es';
import { i18n } from 'next-i18next';
import React, { useRef } from 'react';
import { classNames } from '../classnames';
import { render, unmount } from '../render';
export const InnerModal = (props) => {
    const { value, onSuccess, onCancel, cancelProps, confirmProps, ContentComponent } = props;
    const formRef = useRef();
    const title = ((value === null || value === void 0 ? void 0 : value.id) ? i18n === null || i18n === void 0 ? void 0 : i18n.t('common:consigneeForm.editTitle') : i18n === null || i18n === void 0 ? void 0 : i18n.t('common:consigneeForm.addTitle'));
    return (_jsx(Modal, Object.assign({ title: title, open: true, className: "flex flex-col h-140 pc:h-auto", headerClassName: "flex-shrink-0 pc:py-6 pc:px-5", bodyClassName: "flex-1 overflow-auto", footerClassName: "flex-shrink-0 shadow-nav-sm py-2 px-3 pc:py-4", maskCancelable: true, fixedBottomOnMobile: true, getContainer: () => document.body, onConfirm: () => { var _a; return (_a = formRef.current) === null || _a === void 0 ? void 0 : _a.submit(); }, onCancel: onCancel, cancelProps: Object.assign({ className: classNames('pc:min-w-39 bg-black-6 border-0') }, cancelProps), confirmProps: Object.assign({ className: classNames('pc:min-w-39 bg-primary border-0') }, confirmProps) }, { children: _jsx(ContentComponent, { ref: formRef, values: value, onOk: onSuccess }) })));
};
// 新增或修改配送信息彈框
export const editDeliveryByModal = (options = {}) => {
    return new Promise((resolve) => {
        const { value, ContentComponent, onSuccess = noop, onCancel = noop } = options;
        const container = document.createDocumentFragment();
        const handleSuccess = () => {
            unmount(container);
            onSuccess();
            resolve(true);
        };
        const handleCancel = () => {
            unmount(container);
            onCancel();
            resolve(false);
        };
        render(_jsx(InnerModal, { value: value, onSuccess: handleSuccess, onCancel: handleCancel, ContentComponent: ContentComponent, confirmProps: options.confirmProps, cancelProps: options.cancelProps }), container);
    });
};

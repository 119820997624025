import { useLatest } from 'ahooks';
import { unref } from '../utils/unref';
import { useClientLayoutEffect } from './use-client-layout-effect';
/**
 * 监听元素尺寸变化
 */
export function useResizeObserver(callback, target) {
    const targetEl = unref(target);
    const callbackRef = useLatest(callback);
    useClientLayoutEffect(() => {
        if (targetEl) {
            const update = () => callbackRef.current(targetEl.getBoundingClientRect());
            update();
            const observer = new window.ResizeObserver(update);
            observer.observe(targetEl);
            return () => observer.disconnect();
        }
    }, [targetEl]);
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { inRange } from 'lodash-es';
export const imageAd = {
    protocol(original) {
        return __awaiter(this, void 0, void 0, function* () {
            const { mode, imageAdList = [] } = original.props;
            const now = Date.now();
            switch (mode) {
                case 'swiper':
                case 'scroll':
                case 'flow':
                case 'single':
                    original.props.imageAdList = imageAdList.filter((imageAd) => Array.isArray(imageAd.time) ? inRange(now, imageAd.time[0], imageAd.time[1]) : true);
                    break;
                case 'l1r2':
                    break;
                case 'l1r3':
                    break;
                case 'picNav':
                    break;
                case 'textNav':
                    break;
                default:
            }
            return original;
        });
    },
    renderable(props) {
        const { mode, imageAdList = [], left1Images = [], right1Images = [], right2Images = [], right3Images = [], picNavList = [], textNavList = [] } = props;
        switch (mode) {
            case 'swiper':
            case 'scroll':
            case 'flow':
            case 'single':
                return Boolean(imageAdList.length);
            case 'l1r2':
                return (Boolean(left1Images.length) &&
                    Boolean(right1Images.length) &&
                    Boolean(right2Images.length));
            case 'l1r3':
                return (Boolean(left1Images.length) &&
                    Boolean(right1Images.length) &&
                    Boolean(right2Images.length) &&
                    Boolean(right3Images.length));
            case 'picNav':
                return Boolean(picNavList.length);
            case 'textNav':
                return Boolean(textNavList.length);
            default:
                return false;
        }
    }
};

import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const FeatureIcon = SpritesImage.createIcon({
  itemWidth: 48,
  images,
  icons: {
    exchange: 1,
    material: 2,
    quality: 3,
    service: 4,
    code: 5,
    stages: 6
  }
});

import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-12 15:44:29
 * @Description: 错误组件
 */
import { classNames } from '@common/application/utils/classnames';
import { createPath } from '@common/application/utils/create-path';
import { encode } from '@common/application/utils/seo';
import { ROUTE_MAP } from '@common/constants/route-map';
import Head from 'next/head';
import NextRouter from 'next/router';
import { useTranslation } from 'next-i18next';
import React, { useMemo } from 'react';
import { Button } from './button';
import { DImage } from './image';
export const Exceptional = ({ CustomLayout, message = '', statusCode = 500, image = '', hideButton = false, imageClassName, textClassName }) => {
    const { t } = useTranslation(['common']);
    const title = useMemo(() => {
        if (message)
            return message;
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (statusCode) {
            case 500:
            case 501:
                return t('exceptional.wrong');
            default:
                return t('pageNotFound.title');
        }
    }, [statusCode]);
    const ButtonComp = useMemo(() => {
        if (hideButton)
            return null;
        // eslint-disable-next-line sonarjs/no-small-switch
        switch (statusCode) {
            case 500:
                return (_jsx(Button, Object.assign({ className: "min-w-38 w-fit px-4 h-11 mt-6 !bg-primary text-white", onClick: () => {
                        NextRouter.reload();
                    } }, { children: _jsx("span", Object.assign({ className: classNames('uppercase', 'text-xs', 'pc:text-sm') }, { children: t('exceptional.backToHome') })) })));
            default:
                return (_jsx(Button, Object.assign({ className: "min-w-38 w-fit px-4 h-9 mt-6 !bg-primary text-white", onClick: () => {
                        window.location.href = createPath(ROUTE_MAP.HOME, {}, { withLocale: true });
                    } }, { children: _jsx("span", Object.assign({ className: classNames('uppercase', 'text-xs', 'xl:text-base') }, { children: t('pageNotFound.backToHome') })) })));
        }
    }, [statusCode]);
    return (_jsxs(CustomLayout, { children: [_jsx(Head, { children: _jsx("title", { children: t('common:pageTitle.error', {
                        error: decodeURIComponent(encode(title))
                    }) }) }), _jsx("div", Object.assign({ className: "container mx-auto pc:py-6 py-4" }, { children: _jsxs("div", Object.assign({ className: "bg-white h-100 flex items-center flex-col" }, { children: [_jsx(DImage, { src: image, fill: true, className: classNames('relative w-75 min-h-42.5 h-fit mt-27', imageClassName), priority: true }), _jsx("p", Object.assign({ className: classNames('text-black-1 text-base mt-6', textClassName) }, { children: t(title) })), ButtonComp] })) }))] }));
};
Exceptional.defaultProps = {};

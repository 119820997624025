/*
 * @Author: 朱璐
 * @Date: 2022-10-28 15:40:38
 * @Description: language 常量
 */
import { generateOptions } from '@common/application/utils/generate-options';
export const DEFAULT_LANGUAGE = 'tc';
export var LANGUAGE_VALUES;
(function (LANGUAGE_VALUES) {
    /** 繁體 */
    LANGUAGE_VALUES["TC"] = "tc";
    /** 簡體 */
    LANGUAGE_VALUES["CN"] = "cn";
    /** 英文 */
    LANGUAGE_VALUES["EN"] = "en";
})(LANGUAGE_VALUES || (LANGUAGE_VALUES = {}));
export const KEYCLOAK_LANGUAGE = {
    [LANGUAGE_VALUES.TC]: 'hk',
    [LANGUAGE_VALUES.CN]: 'cn',
    [LANGUAGE_VALUES.EN]: 'en'
};
export const API_LANGUAGE = {
    [LANGUAGE_VALUES.TC]: 'tc',
    [LANGUAGE_VALUES.CN]: 'zh',
    [LANGUAGE_VALUES.EN]: 'en'
};
export const LANGUAGE_NAMES = {
    [LANGUAGE_VALUES.TC]: '繁中',
    [LANGUAGE_VALUES.CN]: '简中',
    [LANGUAGE_VALUES.EN]: 'ENG'
};
export const LANGUAGE_OPTIONS = generateOptions(LANGUAGE_VALUES, LANGUAGE_NAMES);

import { CommonApi } from '@common/api/common';
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { currentStore } from '@common/application/store/common';
import { useAddSearchHistory } from '@common/application/store/search';
import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
import { get } from 'lodash-es';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useRecoilValue } from 'recoil';
export const useSearch = () => {
    const [searchBoxVisible, setSearchBoxVisible] = useState(false);
    const router = useRouter();
    const [search, setSearch] = useState('');
    const store = useRecoilValue(currentStore);
    const [hotList, setHotList] = useState([]);
    const { pc } = useBreakpoint();
    useEffect(() => {
        if ((get(router, 'query.keywords') || '') !== search) {
            setSearch(get(router, 'query.keywords'));
        }
    }, []);
    const [defaultKeywords, setDefaultKeywords] = useState([]);
    const [defaultKeywordIndex, setDefaultKeywordIndex] = useState(0);
    const hasDefaultKeywords = Boolean(defaultKeywords.length);
    useEffect(() => {
        CommonApi.getDefaultKeywords().then(setDefaultKeywords);
    }, []);
    useEffect(() => {
        if (router.route === ROUTE_MAP.BRANCH) {
            setSearch(router.query.name);
        }
        else if (router.route === ROUTE_MAP.SEARCH) {
            setSearch(router.query.q);
        }
    }, [router.query]);
    useEffect(() => {
        CommonApi.getHotWords().then((res) => {
            setHotList(res.slice(0, 8));
        });
    }, [store]);
    const searchWords = useMemo(() => {
        if (search)
            return search.trim();
        if (hasDefaultKeywords)
            return defaultKeywords[defaultKeywordIndex];
        return '';
    }, [search, hasDefaultKeywords, defaultKeywords, defaultKeywordIndex]);
    const handleAddSearchHistory = useAddSearchHistory();
    const handleSearch = (value) => {
        if (pc) {
            handleAddSearchHistory(value !== null && value !== void 0 ? value : searchWords);
            router.push(createPath(ROUTE_MAP.SEARCH_RESULT, { q: value !== null && value !== void 0 ? value : searchWords }));
        }
        else {
            router.push(createPath(ROUTE_MAP.SEARCH));
        }
    };
    return {
        searchBoxVisible,
        setSearchBoxVisible,
        hasDefaultKeywords,
        search,
        setSearch,
        handleSearch,
        defaultKeywords,
        defaultKeywordIndex,
        setDefaultKeywordIndex,
        hotList,
        pc
    };
};

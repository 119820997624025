var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 朱璐
 * @Date: 2022-10-31 16:44:32
 * @Description: 用户api
 */
import request from '@common/application/utils/request';
import { API_MAP } from './api-map';
export class UserApi {
    /**
     * 获取用户信息
     */
    static getUser() {
        return request.get(API_MAP.USER_INFO, {}, {
            hideLoading: true
        });
    }
    static getConsigneeAddressList(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.MEMBER_ADDRESS_LIST, {}, Object.assign({}, config));
        });
    }
    /** 新增配送地址 */
    static addConsigneeAddress(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.MEMBER_ADDRESS, params);
        });
    }
    /** 编辑配送地址 */
    static updateConsigneeAddress(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.put(`${API_MAP.MEMBER_ADDRESS}/:id`, params);
        });
    }
    /** 刪除配送地址 */
    static deleteConsigneeAddress(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.delete(`${API_MAP.MEMBER_ADDRESS}/${params}`);
        });
    }
}

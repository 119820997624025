/*
 * @Author: zhulu
 * @Date: 2022-09-29 15:05:06
 * @Description: 公用工具类
 */
import { LANGUAGE_VALUES } from '@common/constants/language';
import emojiRegex from 'emoji-regex';
import { cloneDeep, fill, isArray, isEmpty, isNaN, isPlainObject, isString } from 'lodash-es';
import { i18n } from 'next-i18next';
/**
 * 检测参数值是否是有效的，以下参数值将被视为是无效的：
 * - 自身没有任何可枚举数据的对象
 * - 空字符串
 * - null、undefined
 * - NaN
 * @param {*} value 待验证的参数值
 * @return {boolean} 该参数值是否有效
 */
export function isValidValue(value) {
    if (isPlainObject(value) || isString(value)) {
        return !isEmpty(value);
    }
    if (isArray(value))
        return value.length > 0;
    return value !== null && value !== undefined && !Number.isNaN(value);
}
/**
 * 格式化参数对象，将空数据从参数对象中移除
 *
 * @param {object} params - 对象
 * @returns {object} 干净对象
 */
export function cleanParams(params) {
    const newParams = cloneDeep(params);
    // eslint-disable-next-line guard-for-in
    for (const name in newParams) {
        const value = newParams[name];
        if (!isValidValue(value)) {
            delete newParams[name];
        }
    }
    return newParams;
}
// 创建一个标识码
export function createUnique() {
    const now = new Date();
    return (
    // eslint-disable-next-line prefer-template
    'dch-' +
        now.getFullYear() +
        fill([now.getMonth() + 1], 2) +
        fill([now.getDate()], 2) +
        fill([now.getHours()], 2) +
        fill([now.getMinutes()], 2) +
        fill([now.getSeconds()], 2) +
        fill([now.getMilliseconds()], 3) +
        Math.random().toFixed(20).substring(2));
}
export const formatPrice = (price, fillDecimals = true) => {
    let _price = (`${price}` || '').replace(/,/g, '');
    if (isNaN(Number(_price)))
        _price = `0`;
    return Number(_price).toLocaleString(undefined, {
        minimumFractionDigits: fillDecimals ? 2 : 0,
        maximumFractionDigits: 2
    });
};
export const isMobile = (userAgent) => {
    return !!/Mobi|Android|iPhone/i.test(userAgent || (navigator === null || navigator === void 0 ? void 0 : navigator.userAgent) || '');
};
/**
 * 获取首图
 *
 * @param images 图片信息或图片信息列表
 */
export function getFirstImage(images) {
    const first = (Array.isArray(images) ? images : [images])[0];
    return first ? (first.video ? first.poster : first.url) : undefined;
}
/**
 * 检测是否为售罄商品
 */
export function checkSoldOutProduct(product) {
    return Boolean((product === null || product === void 0 ? void 0 : product.soldOutStatus) || (product === null || product === void 0 ? void 0 : product.isSellOut));
}
/**
 * 检测是否为下架商品
 */
export function checkUnShelveProduct(product) {
    return Boolean((product === null || product === void 0 ? void 0 : product.onShelves) === 2 || (product === null || product === void 0 ? void 0 : product.isOffShelves));
}
/**
 * 检测是否为预售商品
 */
export function checkPreSaleProduct(product) {
    return Boolean((product === null || product === void 0 ? void 0 : product.sellFlag) || (product === null || product === void 0 ? void 0 : product.isPresale));
}
/**
 * 检测是否为无效商品(售罄或者下架)
 */
export function checkInvalidProduct(product) {
    return (checkUnShelveProduct(product) || checkSoldOutProduct(product) || checkPreSaleProduct(product));
}
/**
 * 获取购物车中该商品总数量
 *
 * @param product 商品
 * @param countStatistics 数量统计
 */
export function getProductCartCount(product, countStatistics) {
    var _a;
    // 普通商品 key 等于 csku
    // 称重商品 key 等于 csku-goodsSpec
    const key = [product.csku, product.goodsSpec].filter(Boolean).join('-');
    return (_a = countStatistics[key]) !== null && _a !== void 0 ? _a : 0;
}
export function cleanWhiteSpace(value) {
    return value.replace(/\s+/g, '');
}
const inputNumberRE = /([0-9]|Backspace)/;
/**
 * 禁用数字键和删除键以外的键盘事件
 */
export function disabledInputNotNumber(e) {
    if (!inputNumberRE.test(e.key)) {
        e.preventDefault();
        e.stopPropagation();
    }
}
export function isEngLanguage() {
    return (i18n === null || i18n === void 0 ? void 0 : i18n.language) === LANGUAGE_VALUES.EN;
}
/**
 * 判断空富文本
 */
export function isEmptyRichText(richText) {
    var _a;
    if (isEmpty(richText)) {
        return true;
    }
    // 判断内容是否为空
    let template = document.createElement('template');
    try {
        // 判断是否包含图片
        if (richText.match(/<img.*?src="(.*?)".*?>/)) {
            return false;
        }
        // 判断是否包含iframe视频
        if (richText.match(/<iframe.*?src="(.*?)".*?>.*?<\/iframe>/)) {
            return false;
        }
        // 检查是否包含 video
        if (richText.match(/<video.*?src="(.*?)".*?>.*?<\/video>/)) {
            return false;
        }
        template.innerHTML = richText;
        // @ts-ignore
        const content = (_a = template === null || template === void 0 ? void 0 : template.content) === null || _a === void 0 ? void 0 : _a.textContent;
        return !content.trim();
    }
    finally {
        template = null;
    }
}
export function filterEmoji(str) {
    if (typeof str === 'string') {
        const regex = emojiRegex();
        return str.replace(regex, '');
    }
    return str;
}
export const isRobot = (userAgent) => {
    const robotRegex = /(googlebot|bingbot|yandexbot|baiduspider|duckduckbot|google-inspectiontool)/i;
    return robotRegex.test(userAgent.toLowerCase());
};
export function getCookie(name) {
    const arr = document.cookie.match(new RegExp(`(^| )${name}=([^;]*)(;|$)`));
    if (arr != null)
        return unescape(arr[2]);
    return null;
}

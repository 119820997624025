var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CartApi } from '@common/api/cart';
import { CommonApi } from '@common/api/common';
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { displayCartCount, useUpdateCartCount } from '@common/application/store/cart';
import { emitCartNumber } from '@common/application/utils/cart-number';
import { useUpdateCartNumberListener } from '@common/application/utils/event';
import { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
export const useCart = () => {
    const count = useRecoilValue(displayCartCount);
    const { mobile } = useBreakpoint();
    const updateCartCount = useUpdateCartCount();
    const [visible, setVisible] = useState(false);
    const [cartCount, setCartCount] = useState([]);
    const [disabled, setDisabled] = useState(false);
    useUpdateCartNumberListener(() => {
        updateCartCount();
    });
    function addCart(goods, count) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield CartApi.changeGoodsCount({
                itemId: goods.id,
                goodsId: goods.goodsId,
                quantity: count
            }, { hideLoading: true });
            updateCartCount();
            if (!res) {
                emitCartNumber();
            }
        });
    }
    function getData() {
        return __awaiter(this, void 0, void 0, function* () {
            setDisabled(true);
            const res = yield CommonApi.getminiCartList();
            if (res.item) {
                setCartCount(res.item.map((item, index) => (Object.assign({ [index]: item.quantity }, item))));
            }
            else {
                setCartCount([]);
            }
            setDisabled(false);
        });
    }
    useEffect(() => {
        getData();
    }, []);
    const hanldChange = (show) => {
        if (show) {
            setVisible(show);
            getData();
        }
        setVisible(show);
    };
    return {
        mobile,
        visible,
        cartCount,
        disabled,
        count,
        setCartCount,
        addCart,
        hanldChange
    };
};

export var CATEGORY_PARAMS;
(function (CATEGORY_PARAMS) {
    /**
     * 展示分类
     */
    CATEGORY_PARAMS["DISPLAY_CATEGORY"] = "displayCategoryIds";
    /**
     * 虚拟分类
     */
    CATEGORY_PARAMS["VIRTUAL_CATEGORY"] = "virtualCategoryIds";
    /**
     * 展示分类
     */
    CATEGORY_PARAMS["CATEGORY"] = "categoryIds";
})(CATEGORY_PARAMS || (CATEGORY_PARAMS = {}));
/**
 * 来源类型;1:品牌,2:展示分类,3:合作商户"
 */
export var RECOMMEND_PRODUCT_SOURCE;
(function (RECOMMEND_PRODUCT_SOURCE) {
    /**
     * 品牌
     */
    RECOMMEND_PRODUCT_SOURCE["BRAND"] = "1";
    /**
     * 展示分类
     */
    RECOMMEND_PRODUCT_SOURCE["DISPLAY_CATEGORY"] = "2";
    /**
     * 合作商户
     */
    RECOMMEND_PRODUCT_SOURCE["MERCHANT"] = "3";
})(RECOMMEND_PRODUCT_SOURCE || (RECOMMEND_PRODUCT_SOURCE = {}));
/**
 * 1:推荐,2:降序,3:升序,4:销量降序,5:销量升序,6:创建时间降序,7:创建时间升序,8:评分降序，9:评分升序 100:随机排序
 */
export var PRODUCT_LIST_SORT;
(function (PRODUCT_LIST_SORT) {
    /**
     * 推荐
     */
    PRODUCT_LIST_SORT["RECOMMEND"] = "1";
    /**
     * 价格降序
     */
    PRODUCT_LIST_SORT["PRICE_DESC"] = "2";
    /**
     * 价格升序
     */
    PRODUCT_LIST_SORT["PRICE_ASC"] = "3";
    /**
     * 销量降序
     */
    PRODUCT_LIST_SORT["SALES_VOLUME_DESC"] = "4";
    /**
     * 销量升序
     */
    PRODUCT_LIST_SORT["SALES_VOLUME_ASC"] = "5";
    /**
     * 创建时间降序
     */
    PRODUCT_LIST_SORT["CREATE_TIME_DESC"] = "6";
    /**
     * 创建时间升序
     */
    PRODUCT_LIST_SORT["CREATE_TIME_ASC"] = "7";
    /**
     * 评分降序
     */
    PRODUCT_LIST_SORT["RATING_DESC"] = "8";
    /**
     * 评分升序
     */
    PRODUCT_LIST_SORT["RATING_ASC"] = "9";
    /**
     * 随机排序
     */
    PRODUCT_LIST_SORT["RANDOM"] = "100";
})(PRODUCT_LIST_SORT || (PRODUCT_LIST_SORT = {}));

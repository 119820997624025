import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { categoryBreadcrumb, prodcutListPageTypeState, productList, productListParams } from '@common/application/store/product-list';
import { createPath } from '@common/application/utils/create-path';
import { ROUTE_MAP } from '@common/constants/route-map';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useRecoilValue } from 'recoil';
export const NextBreadcrumbBtn = () => {
    var _a, _b, _c;
    const breadcrumb = useRecoilValue(categoryBreadcrumb);
    const products = useRecoilValue(productList);
    const params = useRecoilValue(productListParams);
    const pageType = useRecoilValue(prodcutListPageTypeState);
    const router = useRouter();
    const { t } = useTranslation(['product-list', 'common']);
    if (pageType !== 'displayCategory')
        return null;
    const menu = (_a = breadcrumb.find((item) => item.id === params.categoryId)) === null || _a === void 0 ? void 0 : _a.menu;
    const path = (_b = breadcrumb.find((item) => item.id === params.categoryId)) === null || _b === void 0 ? void 0 : _b.path;
    if (!isEmpty(breadcrumb) && products.totalPage === products.page && menu && path) {
        const index = menu.findIndex((item) => item.id === params.categoryId);
        if (index < menu.length - 1) {
            const pathName = path.map((item) => {
                var _a, _b;
                if (item === ((_a = menu[index]) === null || _a === void 0 ? void 0 : _a.name)) {
                    return (_b = menu[index + 1]) === null || _b === void 0 ? void 0 : _b.name;
                }
                return item;
            });
            return (_jsxs("div", Object.assign({ className: "flex flex-col items-center justify-center mt-6" }, { children: [_jsxs("div", { children: [_jsx("span", Object.assign({ className: "text-black-3 text-[13px]" }, { children: t('browse') })), _jsxs("span", Object.assign({ className: "text-black-1 text-[13px]" }, { children: ["\u201C", (_c = menu[index + 1]) === null || _c === void 0 ? void 0 : _c.name, "\u201D"] }))] }), _jsx("div", Object.assign({ className: "bg-black-1 w-15 h-8 rounded-full flex items-center justify-center text-white text-sm mt-3", onClick: () => {
                            var _a;
                            router.push(createPath(ROUTE_MAP.CATEGORY_LIST, {
                                category: [...(pathName !== null && pathName !== void 0 ? pathName : [])],
                                id: (_a = menu[index + 1]) === null || _a === void 0 ? void 0 : _a.id
                            }));
                        } }, { children: t('goTo') }))] })));
        }
        return _jsx(_Fragment, {});
    }
    return _jsx(_Fragment, {});
};

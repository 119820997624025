var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Date: 2022-11-16 15:43:43
 * @Author: zhulu@co-mall.com
 * @FilePath: /dch-web/src/application/utils/transform/map/goods.ts
 * @Description:
 */
import { ProductListApi } from '@common/api/product-list';
import { isEmpty } from 'lodash-es';
import { nowInRange } from '../../now-in-range';
/**
 * filterSoldOut 0 是过滤
 */
export const goods = {
    protocol(original, ctx) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!original) {
                return null;
            }
            const { mode, setMethod = 1, goodsList = [], filterSoldOut, goodsCardCustomList = [] } = original.props;
            switch (mode) {
                case 'goodsCard':
                case 'ahaaGoodsCard':
                case 'foodGoodsCard':
                case 'withImage': {
                    if (goodsList.length > 0) {
                        const cskus = goodsList.map((item) => item.csku).join(',');
                        const products = yield ProductListApi.getProductListByIds({
                            ids: cskus,
                            isShowSellOut: filterSoldOut ? 0 : 1
                        }, {
                            ctx
                        });
                        original.props.dataset = {
                            loading: false,
                            data: products
                        };
                    }
                    break;
                }
                case 'withTitle1':
                case 'withTitle2': {
                    if (setMethod === 1) {
                        const defaultActive = original.props.goodsGroupList[0];
                        const products = yield goods.requests.loadGoodsList(Object.assign(Object.assign({}, defaultActive.target), { page: 1, perPage: 12, filterSoldOut }), {
                            ctx
                        });
                        original.props.dataset = {
                            [defaultActive.id]: {
                                loading: false,
                                data: products
                            }
                        };
                    }
                    else {
                        const defaultActive = original.props.goodsGroupList2[0];
                        const products = yield goods.requests.loadGoodsList2({
                            ids: defaultActive.goodsList.map((item) => item.csku).join(','),
                            filterSoldOut
                        }, {
                            ctx
                        });
                        original.props.dataset = {
                            [defaultActive.id]: {
                                loading: false,
                                data: products
                            }
                        };
                    }
                    break;
                }
                case 'goodsCardCustom': {
                    if (goodsCardCustomList && goodsCardCustomList.length) {
                        const cskus = goodsCardCustomList.map((item) => item.good.csku).join(',');
                        const products = yield ProductListApi.getProductListByIds({
                            ids: cskus,
                            isShowSellOut: filterSoldOut ? 0 : 1
                        }, {
                            ctx
                        });
                        const data = products.map((good) => {
                            const findTargetIndex = goodsCardCustomList.findIndex((i) => i.good.csku === good.csku && !i.matched);
                            goodsCardCustomList[findTargetIndex].matched = true;
                            return Object.assign(Object.assign({}, goodsCardCustomList[findTargetIndex]), { good });
                        });
                        original.props.dataset = {
                            loading: false,
                            data
                        };
                    }
                    break;
                }
            }
            return original;
        });
    },
    renderable(props) {
        const { mode, time, goodsList, setMethod = 1, goodsGroupList, goodsGroupList2, goodsCardCustomList } = props;
        switch (mode) {
            case 'goodsCard':
            case 'ahaaGoodsCard':
            case 'foodGoodsCard':
            case 'withImage':
                return !isEmpty(goodsList) && nowInRange(time);
            case 'withTitle1':
            case 'withTitle2':
                return ((setMethod === 1 ? !isEmpty(goodsGroupList) : !isEmpty(goodsGroupList2)) &&
                    nowInRange(time));
            case 'goodsCardCustom':
                return !isEmpty(goodsCardCustomList) && nowInRange(time);
            default:
                return false;
        }
    },
    requests: {
        /**
         * 加载当前商品组的商品列表
         */
        loadGoodsList(requestData, config) {
            const params = {
                page: requestData.page,
                perPage: Math.max(requestData.perPage, 10),
                sort: '1',
                isShowSellOut: requestData.filterSoldOut ? 0 : 1
            };
            switch (requestData.linkType) {
                case 'displayCategory':
                    params.displayCategoryIds = requestData.linkParams.id;
                    break;
                case 'virtualCategory':
                    params.virtualCategoryIds = requestData.linkParams.id;
                    break;
                case 'brands':
                    params.brandIds = requestData.linkParams.id;
                    break;
            }
            return ProductListApi.getProductList(params, config).then(({ result }) => result);
        },
        /**
         * 方式2加载当前商品组的商品列表
         */
        loadGoodsList2(requestData, config) {
            return ProductListApi.getProductListByIds({
                ids: requestData.ids,
                isShowSellOut: requestData.filterSoldOut ? 0 : 1
            }, config);
        }
    },
    events: {}
};

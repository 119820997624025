import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { pageTitleParams } from '@common/application/store/common';
import { encode } from '@common/application/utils/seo';
import { PATH_NAME_MAP } from '@common/constants/route-map';
import { get, isObject } from 'lodash-es';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useEffect, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
export const PageTitle = () => {
    const { t } = useTranslation();
    const router = useRouter();
    const params = useRecoilValue(pageTitleParams);
    function encodeParams(params) {
        if (!isObject(params))
            return {};
        const entries = Object.entries(params).map(([k, v]) => [
            k,
            v ? decodeURIComponent(encode(v)) : ''
        ]);
        return Object.fromEntries(entries);
    }
    const title = useMemo(() => {
        const encodedParams = encodeParams(params);
        const pathName = get(PATH_NAME_MAP, router.route, 'homePage');
        if (pathName === 'searchPage' && Object.keys(encodedParams).length === 0) {
            return t(`common:pageTitle.searchPage`, { searchQuery: t('common:search') });
        }
        return t(`common:pageTitle.${pathName}`, encodedParams);
    }, [router.route, params]);
    useEffect(() => {
        window.document.title = title;
    }, [title]);
    return (_jsxs(Head, { children: [_jsx("meta", { name: "viewport", content: "width=device-width,initial-scale=1.0,minimum-scale=1.0,maximum-scale=1.0,user-scalable=no" }), _jsx("title", { children: title })] }));
};

var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { useMemoizedFn } from 'ahooks';
import { isString } from 'lodash-es';
import Image from 'next/image.js';
const DEFAULT_LOADER = (src, width, height) => `${src}?imageView2/1/w/${width}/h/${height}/q/60`;
export const DImage = (props) => {
    const { src, className, imgClassName, alt, sizes, loader = DEFAULT_LOADER, defaultImage = '' } = props, restProps = __rest(props, ["src", "className", "imgClassName", "alt", "sizes", "loader", "defaultImage"]);
    const imageLoader = useMemoizedFn((props) => props.src);
    if (src && isString(src) && sizes) {
        return (_jsxs("div", Object.assign({ className: classNames('relative flex items-center justify-center', className) }, { children: [_jsx(Image, Object.assign({ className: classNames(imgClassName, '!inline-block pc:!hidden'), src: loader(src, sizes.mobile.width, sizes.mobile.height), alt: alt || 'DCHLiving', loader: imageLoader, draggable: "false" }, restProps)), _jsx(Image, Object.assign({ className: classNames(imgClassName, '!hidden pc:!inline-block'), src: loader(src, sizes.pc.width, sizes.pc.height), alt: alt || 'DCHLiving', loader: imageLoader, draggable: "false" }, restProps))] })));
    }
    return (_jsx("div", Object.assign({ className: classNames('relative flex items-center justify-center', className) }, { children: _jsx(Image, Object.assign({ className: imgClassName, src: src || defaultImage, alt: alt || 'DCHLiving', loader: imageLoader, draggable: "false" }, restProps)) })));
};

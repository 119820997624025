import { SpritesImage } from '@common/components/common/sprites-image';

import images from './images';

export const PrimaryLineIcon = SpritesImage.createIcon({
  itemWidth: 120,
  itemHeight: 84,
  images,
  icons: {
    cart: 1,
    del: 2,
    tip: 3,
    material: 4,
    store: 5,
    follow: 6,
    message: 7
  }
});

import { useEventListener } from 'ahooks';
import { useState } from 'react';
import { useClientLayoutEffect } from './use-client-layout-effect';
/**
 * Reactive window size.
 */
export function useWindowSize() {
    const [size, setSize] = useState({
        width: Infinity,
        height: Infinity
    });
    const update = () => {
        if (window) {
            setSize({
                width: window.innerWidth,
                height: window.innerHeight
            });
        }
    };
    useClientLayoutEffect(update, []);
    useEventListener('resize', update, { passive: true });
    return size;
}

import { jsx as _jsx } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-11 11:36:15
 * @Description: 翻页组件
 */
import { RightArrowLine } from '@common/components/icon/right-arrow-line';
import { omit } from 'lodash-es';
import { useRouter } from 'next/router';
import qs from 'qs';
import RCPagination from 'rc-pagination';
export const Pagination = (props) => {
    const router = useRouter();
    return (_jsx("div", Object.assign({ className: props.className }, { children: _jsx(RCPagination, Object.assign({ prevIcon: _jsx("div", Object.assign({ className: "pagination-prev w-6 h-6 flex items-center justify-center cursor-pointer" }, { children: _jsx(RightArrowLine, { className: "w-4 h-4 text-black-3 rotate-180" }) })), nextIcon: _jsx("div", Object.assign({ className: "pagination-next w-6 h-6 rounded flex items-center justify-center cursor-pointer" }, { children: _jsx(RightArrowLine, { className: "w-4 h-4 text-black-3 " }) })), itemRender: (page, type, element) => {
                if (type === 'page') {
                    const [path, query] = router.asPath.split('?');
                    const queryString = qs.stringify(Object.assign({ page }, omit(qs.parse(query), 'page')));
                    return (_jsx("a", Object.assign({ href: `${path}?${queryString}`, onClick: (e) => e.preventDefault() }, { children: _jsx("span", { children: page }) })));
                }
                return element;
            } }, props)) })));
};

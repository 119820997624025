import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import React, { useMemo } from 'react';
/**
 * 等比例视图组件（高度随宽度的变化等比例变化）
 */
export const EqualScaleView = (props) => {
    const { className, aspectRatio, children } = props;
    const spaceStyle = useMemo(() => {
        if (aspectRatio) {
            const [w = 1, h = 1] = aspectRatio.split('/');
            return {
                paddingBottom: `${(Number(h) / Number(w)) * 100}%`
            };
        }
    }, [aspectRatio]);
    return (_jsxs("div", Object.assign({ className: classNames(className, 'relative p-0') }, { children: [_jsx("div", { style: spaceStyle }), _jsx("div", Object.assign({ className: classNames(className, 'absolute top-0 left-0 right-0 bottom-0 m-0') }, { children: children }))] })));
};
EqualScaleView.displayName = 'EqualScaleView';

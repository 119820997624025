import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import React, { useMemo } from 'react';
/**
 * 纵横比视图组件（高度随宽度的变化等比例变化）
 */
export const AspectRatioView = (props) => {
    const { className, aspectRatio = '1/1', children } = props;
    const spaceStyle = useMemo(() => {
        const [w = 1, h = 1] = aspectRatio.split('/');
        return {
            padding: `0 0 ${(Number(h) / Number(w)) * 100}% 0`
        };
    }, [aspectRatio]);
    return (_jsx("div", Object.assign({ className: classNames(className, 'relative'), style: spaceStyle }, { children: _jsx("div", Object.assign({ className: classNames(className, 'absolute bottom-0 top-0 left-0 right-0'), style: {
                margin: '0'
            } }, { children: children })) })));
};
AspectRatioView.displayName = 'AspectRatioView';
AspectRatioView.defaultProps = {
    aspectRatio: '1/1'
};

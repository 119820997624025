var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
/*
 * @Author: 朱璐
 * @Date: 2022-11-15 19:06:57
 * @Description:
 */
import { inRange } from 'lodash-es';
export const coupon = {
    protocol(original, ctx) {
        return __awaiter(this, void 0, void 0, function* () {
            console.log(ctx);
            if (!original) {
                return null;
            }
            const currentComponent = Object.assign({}, original);
            // const { coupons = [] } = currentComponent.props;
            // if (coupons.length > 0) {
            //     const promotions = coupons.map((item) => item.promotionNo).join(',');
            //     const data = await CouponAPI.byPromotions(
            //         {
            //             promotions,
            //         },
            //         {
            //             ctx,
            //             pageInfo,
            //         }
            //     );
            //     currentComponent.props.coupons = data;
            // }
            return currentComponent;
        });
    },
    renderable(props) {
        const { time = [], coupons = [] } = props;
        return (coupons.length > 0 && (!time || time.length === 0 || inRange(Date.now(), time[0], time[1])));
    },
    events: {}
};

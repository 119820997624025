var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CommonApi } from '@common/api/common';
import { get } from 'lodash-es';
export const topMenu = {
    protocol(original, ctx) {
        return __awaiter(this, void 0, void 0, function* () {
            const menus = yield CommonApi.getNoticeMsgList({
                type: 2
            }, {
                ctx
            });
            original.props.dataset = {
                menus: {
                    loading: false,
                    data: menus
                }
            };
            return original;
        });
    },
    renderable(props) {
        const { dataset } = props;
        const menus = get(dataset, 'menus.data', []);
        return Boolean(menus.length);
    }
};

import { Fragment as _Fragment, jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { get, isEmpty } from 'lodash-es';
import RcDropdown from 'rc-dropdown';
import { useCallback, useEffect, useState } from 'react';
export function Dropdown(props) {
    const { value, data, onChange, hasSelect = true, valueKey, onVisibleChange, textClassName, trigger = ['click', 'contextMenu', 'hover'] } = props;
    const [currentItem, setCurrentItem] = useState(data[0]);
    const onClick = useCallback((item) => {
        setCurrentItem(item);
        onChange(item);
    }, [onChange]);
    useEffect(() => {
        const target = data.find((item) => item[valueKey] === value);
        if (target) {
            setCurrentItem(target);
        }
        else {
            setCurrentItem(data[0]);
        }
    }, [value, data]);
    return (_jsx(RcDropdown, Object.assign({ onVisibleChange: onVisibleChange, trigger: trigger, placement: props.placement, overlay: _jsx("div", Object.assign({ className: classNames('bg-white py-4 w-fit h-fit shadow-md', props.className) }, { children: _jsx("ul", { children: !isEmpty(props.data) &&
                    props.data.map((item) => {
                        const selected = currentItem && currentItem[valueKey] === item[valueKey];
                        return (_jsxs("li", Object.assign({ className: classNames('flex items-center cursor-pointer text-sm h-6.5 px-4 mb-3 w-fit text-black-3', 'hover:text-primary', 'last:mb-0', {
                                'font-medium text-primary': hasSelect && selected
                            }), onClick: () => {
                                onClick(item);
                            } }, { children: [_jsx("span", Object.assign({ className: textClassName }, { children: get(item, 'name') || '' })), _jsxs("span", Object.assign({ className: "flex-shrink-0" }, { children: ["\uFF08", get(item, 'countNum') || '', "\uFF09"] }))] }), `${item[valueKey]}`));
                    }) }) })), animation: "slide-up", alignPoint: false }, { children: (props === null || props === void 0 ? void 0 : props.children) || _jsx(_Fragment, {}) })));
}
Dropdown.defaultProps = {
    data: [],
    valueKey: 'id',
    action: ['hover'],
    placement: 'topCenter',
    open: false,
    onChange: () => null,
    hasSelect: true
};

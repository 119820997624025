import { classNames } from '@common/application/utils/classnames';
import { DImage } from '@common/components/common/image';
import { ROUTE_MAP } from '@common/constants/route-map';
import Link from 'next/link';

import { IMG_LOGO } from '@/resources/images';

export const Logo = ({ className }: React.PropsWithStyle) => {
  return (
    <div className={classNames('w-full h-full grid place-items-center', className)}>
      <Link href={ROUTE_MAP.HOME}>
        <DImage src={IMG_LOGO} alt="" />
      </Link>
    </div>
  );
};

Logo.defaultProps = {
  type: 'white'
};

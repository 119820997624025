import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import { IconArrow } from '@common/components/icon/arrow';
import { get, isEmpty } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import RcDropdown from 'rc-dropdown';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
const Select = (props, ref) => {
    const { t } = useTranslation(['common']);
    const { selectClassName, overlayClassName, placeholderClassName, value, options, label, disabled, className, arrowClassName, onChange, showError = false, labelKey = 'name', placeholder = t('form.selectPlaceholder'), required, error = '', selectItemClassName, itemSelectedBgColor = false, align, solideArrow = false, renderSolideArrow } = props;
    const [errMsg, setError] = useState(error);
    const [visible, setVisible] = useState(false);
    const onClick = useCallback((item) => {
        onChange(item);
    }, [onChange]);
    const selectRef = useRef();
    useImperativeHandle(ref, () => ({
        setError: (msg) => {
            setError(msg);
        }
    }));
    useEffect(() => {
        setError(error || '');
    }, [error]);
    const errStatus = useMemo(() => {
        return !isEmpty(errMsg) || showError;
    }, [errMsg]);
    return (_jsxs("div", Object.assign({ className: classNames(className), ref: selectRef }, { children: [label && (_jsx("div", Object.assign({ className: classNames('text-base text-gray-500 pb-2', {
                    'text-primary-1': errStatus
                }) }, { children: label }))), _jsx(RcDropdown, Object.assign({ align: align, onVisibleChange: (value) => {
                    if (disabled)
                        return setVisible(false);
                    setVisible(value);
                }, visible: visible, trigger: ['click', 'contextMenu'], placement: "bottom", overlay: _jsx("div", Object.assign({ className: classNames('bg-white w-full max-h-[216px] h-fit shadow-md text-sm overflow-y-auto', props.className, overlayClassName) }, { children: _jsx("ul", { children: !isEmpty(options) &&
                            options.map((item) => {
                                const selected = value && value.id === item.id;
                                return (_jsx("li", Object.assign({ className: classNames('flex items-center cursor-pointer text-sm h-11 pc:h-13.5 p-5 w-full border-b last:border-none border-black-4', 'hover:bg-gray-light', 'last:mb-0', {
                                        'bg-gray-light text-primary': selected,
                                        'bg-black-8': selected && itemSelectedBgColor
                                    }, selectItemClassName), onClick: () => {
                                        setVisible(false);
                                        onClick(item);
                                    } }, { children: _jsx("span", { children: ((item === null || item === void 0 ? void 0 : item[labelKey]) || item.name) }) }), item.id));
                            }) }) })), alignPoint: false }, { children: _jsxs("button", Object.assign({ className: classNames('w-full h-11 pc:h-13.5 text-sm flex flex-row items-center justify-between pl-3 pr-3 box-border rounded border-solid border-gray shadow-sm bg-white border disabled:cursor-not-allowed', {
                        'text-black-4': isEmpty(value)
                    }, {
                        'bg-gray-lightest text-gray-300': disabled
                    }, {
                        'border-red-form': errStatus
                    }, selectClassName), disabled: disabled }, { children: [_jsx("span", { children: get(value, `${[labelKey]}`) || (value === null || value === void 0 ? void 0 : value.name) || (_jsxs(_Fragment, { children: [required && _jsx("span", Object.assign({ className: "text-primary" }, { children: "* " })), _jsx("span", Object.assign({ className: placeholderClassName }, { children: placeholder }))] })) }), solideArrow ? (renderSolideArrow === null || renderSolideArrow === void 0 ? void 0 : renderSolideArrow(visible)) : (_jsx(IconArrow, { className: classNames('w-4.5 rotate-180 text-black-3', {
                                'text-black-3': disabled,
                                'rotate-0': visible
                            }, arrowClassName) }))] })) })), errMsg ? (_jsx("div", Object.assign({ className: classNames('w-full h-7 flex items-center text-sm text-primary-1 box-border break-words') }, { children: errMsg || '' }))) : (_jsx(React.Fragment, {}))] })));
};
export default forwardRef(Select);

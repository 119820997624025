import { useEffect, useRef } from 'react';
/**
 * 仅客户端获取值
 *
 * @param getValue 获取值的回调
 */
export function useClientValue(getValue) {
    const ref = useRef();
    useEffect(() => {
        ref.current = getValue();
    }, []);
    return ref.current;
}

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ProductListApi } from '@common/api/product-list';
import { useUser } from '@common/application/store/user';
import { Toast } from '@common/application/utils/ui/toast';
import { useTranslation } from 'next-i18next';
/**
 * 商品卡收藏按钮操作
 * @returns 返回接口内容
 */
export const useCollection = () => {
    const { t } = useTranslation();
    const { user } = useUser();
    const collectionHandle = (favorite, sku) => __awaiter(void 0, void 0, void 0, function* () {
        if (user) {
            if (favorite) {
                const res = yield ProductListApi.delCollectionFavorite({ sku });
                Toast(t('product-list:uncollect'));
                return new Promise((resolve) => resolve(Boolean(!res)));
            }
            const res = yield ProductListApi.collectionFavorite({ sku });
            Toast(t('product-list:successfulCollection'), { type: 'success' });
            return new Promise((resolve) => resolve(Boolean(!res)));
        }
        Toast(t('register.pleaseSign'));
        return new Promise((resolve) => resolve(Boolean(false)));
    });
    return {
        collectionHandle
    };
};

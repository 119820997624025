import { classNames } from '@common/application/utils/classnames';
import { RightArrowLine } from '@common/components/icon/right-arrow-line';
import Router from 'next/router';

interface Props {
  className?: string;
  imageClassName?: string;
  title?: string;
  path?: string;
  rightRender?: React.ReactNode;
}

export const MobileBackHeader = (props: Props) => {
  const { className, title, path, rightRender } = props;

  const navigateToPath = () => {
    if (!path) return Router.back();
    Router.push(path);
  };

  return (
    <div
      className={classNames(
        'flex justify-between items-center h-11 bg-white',
        'pc:hidden',
        className
      )}
    >
      <div className="flex text-base text-black-1 font-medium" onClick={navigateToPath}>
        <RightArrowLine className="w-6 h-6 mr-2 rotate-180" />
        {title}
      </div>
      <div>{rightRender}</div>
    </div>
  );
};

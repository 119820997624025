function modifyURL(url) {
    const urlObj = new URL(url);
    // 获取原始 URL 中的 page 参数值
    const searchParams = new URLSearchParams(urlObj.search);
    const pageValue = searchParams.get('page');
    // 清空参数列表
    urlObj.search = '';
    // 如果 page 参数存在，则添加新的 page 参数
    if (pageValue !== null) {
        urlObj.searchParams.set('page', pageValue);
    }
    else {
        urlObj.searchParams.set('page', '1');
    }
    return urlObj.toString();
}
export default modifyURL;

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { CheckoutApi } from '@common/api/checkout';
import env from '@common/constants/env';
import { useMemoizedFn } from 'ahooks';
import { useRouter } from 'next/router';
import { atom, useRecoilState, useSetRecoilState } from 'recoil';
import { RECOIL_KEY } from './key';
export const checkoutState = atom({
    key: RECOIL_KEY.CHECKOUT,
    default: undefined
});
export const pointDetailState = atom({
    key: RECOIL_KEY.POINT_DETAIL,
    default: undefined
});
export const checkoutAddressList = atom({
    key: RECOIL_KEY.CHECKOUT_ADDRESS_LIST,
    default: undefined
});
export function useUpdateCheckout() {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define, react-hooks/rules-of-hooks
    return env.DCH_FORMAT === 'AHAA' ? useUpdateCheckoutAhaa() : useUpdateCheckoutLiving();
}
export const pickupLimitMerchantsState = atom({
    key: RECOIL_KEY.PICKUP_LIMIT_MERCHANTS,
    default: []
});
/** 更新结算信息 刷新结算信息 */
export const useUpdateCheckoutLiving = () => {
    const [checkout, setCheckout] = useRecoilState(checkoutState);
    const router = useRouter();
    return {
        update: useMemoizedFn((params = {}) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            // 纯前端字段，结算需要一直带着
            const localParams = {
                couponIdList: params.couponIdList || checkout.couponIdList,
                freightIdList: params.freightIdList || checkout.freightIdList,
                pointQuantity: params.pointQuantity || checkout.pointQuantity
            };
            const checkoutParamsItemList = (_a = checkout.checkoutItemList) === null || _a === void 0 ? void 0 : _a.map((item) => {
                var _a, _b, _c, _d, _e;
                const checkoutParamsItem = (_a = params.checkoutParamsItemList) === null || _a === void 0 ? void 0 : _a.find((i) => i.merchantId === item.checkoutMerchant.merchantId);
                return {
                    merchantId: item.checkoutMerchant.merchantId,
                    deliveryType: (_b = checkoutParamsItem === null || checkoutParamsItem === void 0 ? void 0 : checkoutParamsItem.deliveryType) !== null && _b !== void 0 ? _b : item.deliveryType,
                    pickOnlineStoreId: (_c = checkoutParamsItem === null || checkoutParamsItem === void 0 ? void 0 : checkoutParamsItem.pickOnlineStoreId) !== null && _c !== void 0 ? _c : item.pickOnlineStoreId,
                    pickStoreId: checkoutParamsItem === null || checkoutParamsItem === void 0 ? void 0 : checkoutParamsItem.pickStoreId,
                    memberAddressId: (_d = checkoutParamsItem === null || checkoutParamsItem === void 0 ? void 0 : checkoutParamsItem.memberAddressId) !== null && _d !== void 0 ? _d : (_e = item.defaultedAddress) === null || _e === void 0 ? void 0 : _e.id
                };
            });
            const fetchParams = Object.assign(Object.assign(Object.assign({ singleCheckout: router.query.sc === 'true', selectedVoucherNums: checkout.selectedVoucherNums }, params), localParams), { checkoutParamsItemList });
            try {
                const result = yield CheckoutApi.getCheckoutInfo(fetchParams);
                setCheckout((checkout) => {
                    return Object.assign(Object.assign(Object.assign(Object.assign({}, checkout), result), { orderMemberInfo: checkout.orderMemberInfo, checkoutItemList: checkout.checkoutItemList.map((item) => (Object.assign(Object.assign({}, item), result.checkoutItemList.find((i) => i.checkoutMerchant.merchantId === item.checkoutMerchant.merchantId)))) }), localParams);
                });
            }
            catch (e) {
                console.log('%c ZHULU-[ e ]-31', 'font-size:13px; background:#b88a45; color:#fcce89;', e);
            }
        }))
    };
};
/** 更新结算信息 刷新结算信息 */
export const useUpdateCheckoutAhaa = () => {
    const router = useRouter();
    const [checkout, setCheckout] = useRecoilState(checkoutState);
    const setPickupLimitMerchants = useSetRecoilState(pickupLimitMerchantsState);
    return {
        update: useMemoizedFn((params = {}) => __awaiter(void 0, void 0, void 0, function* () {
            var _a;
            // 纯前端字段，结算需要一直带着
            const localParams = {
                couponIdList: params.couponIdList || checkout.couponIdList,
                freightIdList: params.freightIdList || checkout.freightIdList,
                pointQuantity: params.pointQuantity || checkout.pointQuantity
            };
            const checkoutParamsItemList = (_a = checkout.checkoutItemList) === null || _a === void 0 ? void 0 : _a.map((item) => {
                var _a, _b, _c, _d, _e;
                const checkoutParamsItem = (_a = params.checkoutParamsItemList) === null || _a === void 0 ? void 0 : _a.find((i) => i.merchantId === item.checkoutMerchant.merchantId);
                return {
                    merchantId: item.checkoutMerchant.merchantId,
                    deliveryType: (_b = checkoutParamsItem === null || checkoutParamsItem === void 0 ? void 0 : checkoutParamsItem.deliveryType) !== null && _b !== void 0 ? _b : item.deliveryType,
                    pickOnlineStoreId: (_c = checkoutParamsItem === null || checkoutParamsItem === void 0 ? void 0 : checkoutParamsItem.pickOnlineStoreId) !== null && _c !== void 0 ? _c : item.pickOnlineStoreId,
                    pickStoreId: checkoutParamsItem === null || checkoutParamsItem === void 0 ? void 0 : checkoutParamsItem.pickStoreId,
                    memberAddressId: (_d = checkoutParamsItem === null || checkoutParamsItem === void 0 ? void 0 : checkoutParamsItem.memberAddressId) !== null && _d !== void 0 ? _d : (_e = item.defaultedAddress) === null || _e === void 0 ? void 0 : _e.id
                };
            });
            const fetchParams = Object.assign(Object.assign(Object.assign({ singleCheckout: router.query.sc === 'true', selectedVoucherNums: checkout.selectedVoucherNums }, params), localParams), { checkoutParamsItemList });
            try {
                const result = yield CheckoutApi.getCheckoutInfo(fetchParams);
                if (result.errorCode === 50010) {
                    setPickupLimitMerchants(result.errorMsg);
                    return;
                }
                setCheckout((checkout) => {
                    return Object.assign(Object.assign(Object.assign(Object.assign({}, checkout), result), { orderMemberInfo: checkout.orderMemberInfo, checkoutItemList: checkout.checkoutItemList.map((item) => (Object.assign(Object.assign({}, item), result.checkoutItemList.find((i) => i.checkoutMerchant.merchantId === item.checkoutMerchant.merchantId)))) }), localParams);
                });
            }
            catch (e) {
                console.log('%c ZHULU-[ e ]-31', 'font-size:13px; background:#b88a45; color:#fcce89;', e);
            }
        }))
    };
};
export function useUpdateCheckoutAddressList() {
    const setAddressList = useSetRecoilState(checkoutAddressList);
    return useMemoizedFn((params) => {
        return CheckoutApi.getAddressList(params).then((res) => {
            setAddressList(res);
            return new Promise((resolve) => {
                resolve(res);
            });
        });
    });
}

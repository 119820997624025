import { jsx as _jsx } from "react/jsx-runtime";
import { classNames } from '@common/application/utils/classnames';
import React, { useMemo } from 'react';
/**
 * 纵横比视图组件（高度随宽度的变化等比例变化）
 */
export const AspectRatioView = (props) => {
    const { className, style, aspectRatio = '1/1', children } = props;
    const spaceStyle = useMemo(() => {
        const [w = 1, h = 1] = aspectRatio.split('/');
        return {
            paddingBottom: `${(Number(h) / Number(w)) * 100}%`
        };
    }, [aspectRatio]);
    return (_jsx("div", Object.assign({ className: classNames(className, 'relative p-0'), style: Object.assign(Object.assign({}, style), spaceStyle) }, { children: _jsx("div", Object.assign({ className: classNames(className, 'absolute top-0 left-0 right-0 bottom-0 m-0') }, { children: children })) })));
};
AspectRatioView.displayName = 'AspectRatioView';
AspectRatioView.defaultProps = {
    aspectRatio: '1/1'
};

import { jsxs as _jsxs, jsx as _jsx } from "react/jsx-runtime";
import { useBreakpoint } from '@common/application/hooks/use-breakpoint';
import { merchantInfoState } from '@common/application/store/product-detail';
import { productList, productListBaseFilter } from '@common/application/store/product-list';
import { classNames } from '@common/application/utils/classnames';
import { TextCollapse } from '@common/components/common/text-collapse';
import { ROUTE_MAP } from '@common/constants/route-map';
import { isEmpty } from 'lodash-es';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { useRecoilValue } from 'recoil';
import { usePage } from '../hooks/use-page';
import { Banner } from './banner';
import { NotProduct, ProductList } from './base-component';
import { MobileFilter } from './mobile-filter';
import { NextBreadcrumbBtn } from './next-breadcrumb-btn';
import { Sort } from './sort';
export const MobileBasePage = () => {
    const products = useRecoilValue(productList);
    const filterOptions = useRecoilValue(productListBaseFilter);
    const { pc } = useBreakpoint();
    const merchantInfo = useRecoilValue(merchantInfoState);
    const { t } = useTranslation(['product-list', 'common']);
    const router = useRouter();
    usePage();
    return (_jsx("div", Object.assign({ className: "w-full h-full", id: "plp-container" }, { children: _jsxs("div", Object.assign({ className: "container mx-auto flex flex-col bg-transparent pb-3 h-full" }, { children: [products.total !== 0 && router.query.q && (_jsxs("div", Object.assign({ className: "text-sm text-black-4 py-3 flex" }, { children: [_jsxs("span", Object.assign({ className: "flex-shrink-0" }, { children: [" ", t('searchPrefix')] })), _jsx("span", Object.assign({ className: "text-black-1 flex-shrink-0" }, { children: `"` })), _jsx("span", Object.assign({ className: "text-black-1 line-clamp-1" }, { children: `${router.query.q}` })), _jsx("span", Object.assign({ className: "text-black-1 flex-shrink-0" }, { children: `"` }))] }))), _jsxs("div", Object.assign({ className: "grid grid-cols-3 justify-between content-center gap-4 bg-transparent h-11" }, { children: [!isEmpty(filterOptions) && _jsx(MobileFilter, {}), _jsx("div", Object.assign({ className: "col-span-2 justify-end" }, { children: _jsx(Sort, { className: "justify-end" }) }))] })), !pc && _jsx(Banner, {}), (merchantInfo === null || merchantInfo === void 0 ? void 0 : merchantInfo.description) && router.route === ROUTE_MAP.MERCHANT && (_jsx(TextCollapse, Object.assign({ lineHeight: {
                        pc: 1.5,
                        mobile: 1.25
                    }, lineNumber: {
                        pc: 2,
                        mobile: 1
                    }, color: "black-1", className: "mb-6 w-full text-sm text-black-1" }, { children: merchantInfo.description }))), products.total === 0 && (_jsx("div", Object.assign({ className: "flex-1 flex justify-center items-center bg-white h-full" }, { children: _jsx(NotProduct, {}) }))), _jsxs("div", Object.assign({ className: classNames('flex-1 bg-transparent', { hidden: products.total === 0 }) }, { children: [_jsx("div", Object.assign({ className: "auto-rows-max auto-cols-max" }, { children: _jsx(ProductList, { mobile: true }) })), _jsx(NextBreadcrumbBtn, {})] }))] })) })));
};

var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import request from '@common/application/utils/request';
import { isEmpty } from 'lodash-es';
import { API_MAP } from './api-map';
import { CommonApi } from './common';
export class CartApi {
    /**
     * 购物车详情
     */
    static getDetail(config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.CART_DETAIL, {}, config);
        });
    }
    /**
     * 获取购物车各品数量统计
     */
    static getCartCountStatistics() {
        var _a;
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield CommonApi.getminiCartList({ hideLoading: true });
            const countStatistics = {};
            if (!isEmpty(res.item)) {
                (_a = res.item) === null || _a === void 0 ? void 0 : _a.forEach((product) => {
                    var _a;
                    const key = [product.csku, product.goodsSpec].filter(Boolean).join('-');
                    countStatistics[key] = ((_a = countStatistics[key]) !== null && _a !== void 0 ? _a : 0) + product.quantity;
                });
            }
            return countStatistics;
        });
    }
    /**
     * 加入购物车
     */
    static add(data, config) {
        return __awaiter(this, void 0, void 0, function* () {
            const { buyNow } = data, restData = __rest(data, ["buyNow"]);
            const apiPath = buyNow ? API_MAP.CART_BUY_NOW_ADD : API_MAP.CART_ADD;
            return request.post(apiPath, restData, config);
        });
    }
    /**
     * 购物车数量
     */
    static getCount(config = {}) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.CART_COUNT, {}, Object.assign(Object.assign({}, config), { headers: {
                    'Cache-Control': 'no-cache'
                }, hideLoading: true }));
        });
    }
    /**
     * 清空数量
     */
    static clean() {
        return __awaiter(this, void 0, void 0, function* () {
            return request.delete(API_MAP.CART_CLEAN);
        });
    }
    /**
     * 购物车修改商品数量
     */
    static changeGoodsCount(data, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.put(API_MAP.CART_CHANGE_GOODS_COUNT, data, config);
        });
    }
    /**
     * 购物车选择商品
     */
    static selectGoods(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { selected } = data, restData = __rest(data, ["selected"]);
            return request[selected ? 'put' : 'delete'](API_MAP.CART_SELECT_GOODS, restData);
        });
    }
    /**
     * 购物车移除商品
     */
    static deleteGoods(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.delete(API_MAP.CART_DELETE_GOODS, data);
        });
    }
    /**
     * 购物车选择促销
     */
    static selectPromotion(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { selected } = data, restData = __rest(data, ["selected"]);
            return request[selected ? 'put' : 'delete'](API_MAP.CART_SELECT_PROMOTION, restData);
        });
    }
    /**
     * 购物车选择商品
     */
    static selectAll(data) {
        return __awaiter(this, void 0, void 0, function* () {
            const { selected } = data;
            return request[selected ? 'put' : 'delete'](API_MAP.CART_SELECTED_ALL);
        });
    }
    /**
     * 切换门店同步购物车
     */
    static syncStoreCart(data) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.CART_SYNC_STORE, data);
        });
    }
    /**
     * 删除所有选中的商品
     */
    static deleteSelectedAllGoods() {
        return __awaiter(this, void 0, void 0, function* () {
            return request.delete(API_MAP.CART_DELETE_SELECTED_ALL);
        });
    }
    /**
     * 多品加车
     */
    static addMultiGoods(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.CART_MULTI_ADD, params);
        });
    }
}

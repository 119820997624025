import { classNames } from '@common/application/utils/classnames';
import { DImage } from '@common/components/common/image';
import { useNavigation } from '@common/components/hook/header/use-navigation';
import { MENU_TYPE } from '@common/types/common';
import { isEmpty } from 'lodash-es';
import { InView } from 'react-intersection-observer';

export const Navigation: React.FC = () => {
  const { categoryList, triggerEl, inViewList, mobile, boundingRigth, setIndex, setInViewList } =
    useNavigation();

  return (
    !isEmpty(categoryList) && (
      <div className="w-full bg-white border-b border-black-6" id="navigation-bar">
        <div
          className={classNames('w-full container mx-auto grid grid-flow-col', 'h-11', 'pc:h-12')}
        >
          <div
            ref={triggerEl}
            className={classNames(
              'grid grid-flow-col h-full overflow-y-hidden overflow-x-auto justify-self-start max-w-full navigation-scrollbar',
              'gap-4',
              'pc:gap-10'
            )}
          >
            {
              // eslint-disable-next-line sonarjs/cognitive-complexity
              categoryList.map((item, i) => (
                // InView组件当目标元素出现在视口内会是inView变为true
                <InView
                  key={i}
                  onChange={(inView, entry) => {
                    const categoryLength = inViewList.filter((item) => item).length;
                    if (mobile) {
                      /**
                       * 页面首次加载时，根据boundingRigth判断渐变文字，
                       * 滚动时大于boundingRigth设置index渐变文字
                       */
                      if (entry && entry.intersectionRect.right >= boundingRigth) {
                        // target.id是根据list的index设置
                        setIndex(Number(entry.target.id));
                        // 元素往回滚动时，视口内最后一个文字渐变
                      } else if (
                        entry &&
                        entry.target.id === '1' &&
                        entry.isIntersecting &&
                        categoryLength
                      ) {
                        setIndex(Number(entry.target.id) + categoryLength - 1);
                      }
                    } else if (entry && entry.intersectionRect.right >= boundingRigth) {
                      setIndex(Number(entry.target.id));
                    } else if (entry.target.id === '1' && entry.isIntersecting && categoryLength) {
                      setIndex(Number(entry.target.id) + categoryLength - 1);
                    }
                    // 只在页面首次加载时，收集inViewList
                    if (inViewList && inViewList?.length < categoryList.length) {
                      setInViewList((value) => {
                        return value.concat(inView);
                      });
                    }
                  }}
                >
                  {
                    // @ts-ignore
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    ({ inView, ref }) => {
                      return (
                        <div
                          id={(i + 1).toString()}
                          ref={ref}
                          className={classNames(
                            'inline-block place-self-center text-ahaa-black-0 cursor-pointer w-full font-semibold',
                            'text-1xs',
                            'pc:text-base'
                            // 去掉模糊效果
                            // {
                            //   'bg-clip-text bg-gradient-to-l from-transparent to-black text-transparent':
                            //     inView && i + 1 >= index && i + 1 !== categoryList.length
                            // }
                          )}
                          onClick={() => {
                            if (item.url) window.location.href = item.url;
                          }}
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          {item.menuType === MENU_TYPE.TEXT ? (
                            <span>{item.title}</span>
                          ) : (
                            <DImage
                              src={item?.image?.path}
                              fill
                              className="h-7 pc:h-8 relative block !z-1"
                              imgClassName="object-contain !max-w-[initial] !relative !w-[initial]"
                            />
                          )}
                        </div>
                      );
                    }
                  }
                </InView>
              ))
            }
          </div>
        </div>
        <style jsx>
          {`
            .navigation-scrollbar {
              &::-webkit-scrollbar {
                display: none;
              }
            }
          `}
        </style>
      </div>
    )
  );
};

/*
 * @Author: 朱璐
 * @Date: 2022-10-31 16:44:32
 * @Description: 商品api
 */
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import request from '@common/application/utils/request';
import { PRODUCT_LIST_SORT } from '@common/constants/product-list';
import { omit } from 'lodash-es';
import { API_MAP } from './api-map';
export const PRODUCT_LIST_PAGE_SIZE = 20;
export class ProductListApi {
    static getFilters(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.PRODUCT_FILTER, params, config).then((result) => {
                const attributes = {};
                (result.categoryAttributes || []).forEach((i) => {
                    (i.attributes || []).forEach((att) => {
                        const attribute = {
                            attributeId: att.attributeId,
                            attributeName: att.attributeName,
                            offlineCategoryId: att.offlineCategoryId,
                            attributeValues: []
                        };
                        attribute.attributeValues = att.attributeValues.map((e) => ({
                            id: e.attributeKey,
                            name: e.attributeValue,
                            count: e.count
                        }));
                        attributes[`attributes-${att.attributeId}`] = attribute;
                    });
                });
                return Object.assign({ price: result.resultPrice || { minPrice: '0', maxPrice: '0' }, brands: result.resultBrand || [], merchantDisplayCategories: result.merchantDisplayCategories }, attributes);
            });
        });
    }
    static getProductList(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            params.page || (params.page = 1);
            params.perPage || (params.perPage = PRODUCT_LIST_PAGE_SIZE);
            params.sort || (params.sort = PRODUCT_LIST_SORT.RECOMMEND);
            // 仅综合排序需要展示推荐商品，其余情况将推荐商品参数移除
            if (params.sort !== PRODUCT_LIST_SORT.RECOMMEND) {
                params = omit(params, 'source', 'sourceId');
            }
            return request.get(API_MAP.PRODUCT_LIST, params, config).then((result) => ({
                resultBrand: result.resultBrand,
                page: result.page || 1,
                total: result.totalNum || 0,
                result: result.result || [],
                webResult: result.result || [],
                totalPage: result.totalPage || 0,
                resultPrice: result.resultPrice || { minPrice: '0', maxPrice: '0' }
            }));
        });
    }
    /**
     *  首页商品查询
     * @param param0 isShowSellOut 0 过滤售罄 1不过滤
     * @param config
     * @returns
     */
    static getProductListByIds({ ids, isShowSellOut }, config) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.get(API_MAP.PRODUCT_LIST_IDS, { ids, isShowSellOut }, config);
        });
    }
    /**
     * 收藏商品
     */
    static collectionFavorite(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.post(API_MAP.FAVORITE, params);
        });
    }
    /**
     * 删除收藏商品
     */
    static delCollectionFavorite(params) {
        return __awaiter(this, void 0, void 0, function* () {
            return request.delete(API_MAP.DELFAVORITE, params);
        });
    }
    /**
     * 合作商户
     */
    static getCooperationMerchant(params, config) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = yield request.get(API_MAP.MERCHANT, params, config);
            return {
                name: res.nameMap,
                banner: res.banner ? JSON.parse(res.banner) : undefined,
                description: res.descriptionMap
            };
        });
    }
}

import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import Link from 'next/link';
/**
 * href为空时不让跳转
 */
const LinkOrDiv = ({ children, href }) => {
    if (href)
        return _jsx(Link, Object.assign({ href: href }, { children: children }));
    return _jsx(_Fragment, { children: children });
};
export default LinkOrDiv;

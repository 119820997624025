var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { CommonApi } from '@common/api/common';
import { UserApi } from '@common/api/user';
import { classNames } from '@common/application/utils/classnames';
import { disabledInputNotNumber, filterEmoji } from '@common/application/utils/common';
import { Checkbox } from '@common/components/common/checkbox';
import { Input } from '@common/components/common/input';
import Select from '@common/components/common/select';
import { useFormik } from 'formik';
import { noop } from 'lodash-es';
import { useTranslation } from 'next-i18next';
import React, { useEffect, useImperativeHandle, useMemo, useState } from 'react';
import * as Yup from 'yup';
export const ConsigneeForm = React.forwardRef(
// eslint-disable-next-line sonarjs/cognitive-complexity
function ConsigneeForm(props, ref) {
    const { t } = useTranslation();
    const { className, values: initialValues, onOk = noop } = props;
    const genderMap = [t('common:consigneeForm.sex.0'), t('common:consigneeForm.sex.1')];
    const genders = [
        { id: 1, name: genderMap[1] },
        { id: 0, name: genderMap[0] }
    ];
    const [parentRegions, setParentRegions] = useState([]);
    const [childRegions, setChildRegions] = useState([]);
    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: Yup.object().shape({
            gender: Yup.number().required(t('common:consigneeForm.consigneeGender.emptyMsg')),
            firstName: Yup.string()
                .trim()
                .required(t('common:consigneeForm.consigneeFirstName.emptyMsg')),
            lastName: Yup.string()
                .trim()
                .required(t('common:consigneeForm.consigneeLastName.emptyMsg')),
            mobile: Yup.string()
                .trim()
                .length(8, t('common:consigneeForm.consigneeMobile.lenMsg'))
                .required(t('common:consigneeForm.consigneeMobile.emptyMsg')),
            parentRegionId: Yup.string()
                .trim()
                .required(t('common:consigneeForm.consigneeZone.emptyMsg')),
            childRegionId: Yup.string()
                .trim()
                .required(t('common:consigneeForm.consigneeArea.emptyMsg')),
            streetAddress: Yup.string()
                .trim()
                .required(t('common:consigneeForm.consigneeAddress.emptyMsg')),
            houseNumber: Yup.string().trim().required(t('common:consigneeForm.houseNumber.emptyMsg'))
        }),
        onSubmit: (values) => __awaiter(this, void 0, void 0, function* () {
            Object.keys(values).forEach((item) => {
                // @ts-ignore
                values[item] = filterEmoji(values[item]);
            });
            if (formik.values.id) {
                yield UserApi.updateConsigneeAddress(values);
            }
            else {
                yield UserApi.addConsigneeAddress(values);
            }
            onOk();
        })
    });
    useImperativeHandle(ref, () => ({
        submit: () => formik.handleSubmit()
    }), [formik]);
    useEffect(() => {
        CommonApi.getRegion1s().then(setParentRegions);
    }, []);
    useEffect(() => {
        if (!formik.values.parentRegionId) {
            formik.setFieldValue('childRegionId', undefined);
        }
        if (formik.values.parentRegionId) {
            CommonApi.getRegion3s({
                level1RegionId: formik.values.parentRegionId
            }).then(setChildRegions);
        }
    }, [formik.values.parentRegionId]);
    const gender = useMemo(() => genders.find((item) => item.id === formik.values.gender), [formik.values.gender]);
    const parentRegion = useMemo(() => parentRegions.find((item) => item.id === formik.values.parentRegionId), [parentRegions, formik.values.parentRegionId]);
    const childRegion = useMemo(() => childRegions.find((item) => item.id === formik.values.childRegionId), [childRegions, formik.values.childRegionId]);
    useEffect(() => {
        formik.setFieldValue('regionId', childRegion === null || childRegion === void 0 ? void 0 : childRegion.parentId);
    }, [childRegion]);
    function cleanWhiteSpace(value) {
        return value.replace(/\s+/g, '');
    }
    return (_jsxs("form", Object.assign({ className: classNames('w-full px-3 pt-3 pb-13.5 bg-black-8', 'pc:px-5 pc:pb-5', className), onSubmit: (e) => e.preventDefault() }, { children: [_jsx("div", Object.assign({ className: classNames('py-4 text-sm font-medium', 'hidden', 'pc:block') }, { children: _jsx("div", Object.assign({ className: "" }, { children: t('common:consigneeForm.consigneeGender.label') })) })), _jsxs("div", Object.assign({ className: "grid grid-cols-2 pc:grid-cols-3 gap-2" }, { children: [_jsx(Select, { className: classNames({
                            'pb-2': !formik.touched.gender || !formik.errors.gender
                        }), name: "gender", options: genders, placeholder: t('common:consigneeForm.consigneeGender.placeholder'), value: gender, onChange: (value) => formik.setFieldValue('gender', value.id), error: formik.touched.gender ? formik.errors.gender : '' }), _jsx(Input, Object.assign({ className: classNames('hidden pc:block', {
                            'pb-2': !formik.touched.lastName || !formik.errors.lastName
                        }), maxLength: 20, placeholder: t('common:consigneeForm.consigneeLastName.placeholder') }, formik.getFieldProps('lastName'), { onChange: (e) => {
                            formik.setFieldValue('lastName', cleanWhiteSpace(e.target.value));
                        }, error: formik.touched.lastName ? formik.errors.lastName : '' })), _jsx(Input, Object.assign({ className: classNames({
                            'pb-2': !formik.touched.firstName || !formik.errors.firstName
                        }), maxLength: 20, placeholder: t('common:consigneeForm.consigneeFirstName.placeholder') }, formik.getFieldProps('firstName'), { onChange: (e) => {
                            formik.setFieldValue('firstName', cleanWhiteSpace(e.target.value));
                        }, error: formik.touched.firstName ? formik.errors.firstName : '' }))] })), _jsx(Input, Object.assign({ className: classNames('pc:hidden', {
                    'pb-2': !formik.touched.lastName || !formik.errors.lastName
                }), maxLength: 20, placeholder: t('common:consigneeForm.consigneeLastName.placeholder') }, formik.getFieldProps('lastName'), { onChange: (e) => {
                    formik.setFieldValue('lastName', cleanWhiteSpace(e.target.value));
                }, error: formik.touched.lastName ? formik.errors.lastName : '' })), _jsx(Input, Object.assign({ className: classNames({
                    'pb-5': !formik.touched.mobile || !formik.errors.mobile
                }), type: "text", maxLength: 8, placeholder: t('common:consigneeForm.consigneeMobile.placeholder') }, formik.getFieldProps('mobile'), { onKeyDown: disabledInputNotNumber, error: formik.touched.mobile ? formik.errors.mobile : '' })), _jsx("div", Object.assign({ className: classNames('text-sm font-medium', 'pt-0 pb-2', 'pc:pt-1 pc:pb-4') }, { children: t('common:consigneeForm.consigneeRegion.label') })), _jsxs("div", Object.assign({ className: "grid grid-cols-2 gap-2" }, { children: [_jsx(Select, { className: classNames({
                            'pb-2': !formik.touched.parentRegionId || !formik.errors.parentRegionId
                        }), name: "parentRegionId", options: parentRegions, placeholder: t('common:consigneeForm.consigneeZone.placeholder'), value: parentRegion, onChange: (value) => {
                            if (value)
                                formik.setFieldValue('parentRegionId', value.id);
                        }, error: formik.touched.parentRegionId ? formik.errors.parentRegionId : '' }), _jsx(Select, { name: "childRegionId", options: childRegions, placeholder: t('common:consigneeForm.consigneeArea.placeholder'), value: childRegion, onChange: (value) => {
                            if (value)
                                formik.setFieldValue('childRegionId', value.id);
                        }, error: formik.touched.childRegionId ? formik.errors.childRegionId : '' })] })), _jsx(Input, Object.assign({ className: classNames({
                    'pb-2': !formik.touched.streetAddress || !formik.errors.streetAddress
                }), maxLength: 100, placeholder: t('common:consigneeForm.consigneeAddress.placeholder') }, formik.getFieldProps('streetAddress'), { onChange: (e) => {
                    formik.setFieldValue('streetAddress', e.target.value);
                }, error: formik.touched.streetAddress ? formik.errors.streetAddress : '' })), _jsx(Input, Object.assign({ className: classNames({
                    'pb-4': !formik.touched.houseNumber || !formik.errors.houseNumber
                }), maxLength: 100, placeholder: t('common:consigneeForm.houseNumber.placeholder') }, formik.getFieldProps('houseNumber'), { onChange: (e) => {
                    formik.setFieldValue('houseNumber', e.target.value);
                }, error: formik.touched.houseNumber ? formik.errors.houseNumber : '' })), _jsx(Checkbox, Object.assign({ className: classNames('mt-3', 'pc:mt-4'), iconClassName: "w-4 h-4", checked: formik.values.defaulted, onChange: (value) => formik.setFieldValue('defaulted', value) }, { children: _jsx("div", Object.assign({ className: "text-sm text-dark-light" }, { children: t('common:consigneeForm.consigneeAddress.defaultAddress') })) }))] })));
});
ConsigneeForm.defaultProps = {
    values: {
        id: '',
        gender: undefined,
        firstName: '',
        lastName: '',
        mobile: '',
        regionId: undefined,
        parentRegionId: undefined,
        childRegionId: undefined,
        streetAddress: '',
        houseNumber: '',
        defaulted: true
    }
};

import request from '@common/application/utils/request';
import { DisplayLocation } from '@common/types/popup-ad';
import { API_MAP } from './api-map';
export class PopupAdApi {
    static get(params, config) {
        switch (params.displayLocation) {
            case DisplayLocation.SPECIFIC_BRAND:
                params.brandId = params.id;
                break;
            case DisplayLocation.SPECIFIC_CATEGORY:
                params.categoryId = params.id;
                break;
            case DisplayLocation.SPECIFIC_MERCHANT:
                params.merchantId = params.id;
                break;
        }
        params.id = undefined;
        return request.get(API_MAP.POPUP_AD, params, config);
    }
}

import { atom } from 'recoil';
import { RECOIL_KEY } from './key';
/**
 * 商品详情
 */
export const productDetailState = atom({
    key: RECOIL_KEY.PRODUCT_DETAIL,
    default: {}
});
/**
 * 选中商品数量
 */
export const countState = atom({
    key: RECOIL_KEY.PRODUCT_DETAIL_COUNT,
    default: 1
});
/**
 * 商详面包
 */
export const breadcrumbState = atom({
    key: RECOIL_KEY.PRODUCT_DETAIL_BREADCRUMB,
    default: []
});
/**
 * 推荐商品
 */
export const recommendProductsState = atom({
    key: RECOIL_KEY.PRODUCT_DETAIL_RECOMMEND_PRODUCTS,
    default: []
});
/**
 * 已选的赠品，多促销维度
 */
export const selectedGiftMapsState = atom({
    key: RECOIL_KEY.PRODUCT_SELECTED_GIFT_MAPS,
    default: {}
});
/**
 * 合作商户banner
 */
export const merchantInfoState = atom({
    key: RECOIL_KEY.MERCHANT_BANNER_STATE,
    default: undefined
});
/**
 * 商品详情
 */
export const productSeoConfigState = atom({
    key: RECOIL_KEY.PRODUCT_SEO_CONFIG,
    default: {}
});
/**
 * 评价总数量
 */
export const reviewsTotalState = atom({
    key: RECOIL_KEY.PRODUCT_REVIEWS_TOTAL,
    default: 0
});

import { common } from './common';
import { coupon } from './coupon';
import { goods } from './goods';
import { imageAd } from './image-ad';
import { noticeBar } from './notice-bar';
import { sale } from './sale';
import { text } from './text';
import { topMenu } from './top-menu';
export const map = {
    common,
    coupon,
    goods,
    imageAd,
    sale,
    topMenu,
    text,
    noticeBar
};

import { isString } from 'lodash-es';
import { i18n } from 'next-i18next';
import qs from 'qs';
import { cleanParams } from './common';
import { encode } from './seo';
const matchPathRE = /\[([.a-z0-9]*)\]/gi;
/**
 * 系列化路径
 *
 * @param path 原始路径
 * @param params 路径参数
 */
export function serializationPath(path, params = {}) {
    return path.replace(matchPathRE, (_, pathName) => {
        const paramName = pathName.replace('...', '');
        const value = params[paramName];
        delete params[paramName];
        const values = (Array.isArray(value) ? value : [value]).map(encode);
        return pathName.startsWith('...') ? values.join('/') : values[0];
    });
}
/**
 * 创建路径
 *
 * ```ts
 * createPath('/page/[id]/[color]', {
 *  id: 1,
 *  color: 'red'
 * }) === '/page/1/red'
 *
 * createPath('/page/[...path]', {
 *  path: [1, 'red'],
 * }) === '/page/1/red'
 * ```
 *
 * @param basePath 基础路径
 * @param params 参数
 * @param options 其他选项
 */
export function createPath(basePath, params = {}, options = {}) {
    const { withLocale = false } = options;
    params = cleanParams(params);
    let path = serializationPath(basePath, params);
    if (withLocale) {
        if (isString(withLocale)) {
            path = `/${withLocale}${path}`;
        }
        else if (i18n === null || i18n === void 0 ? void 0 : i18n.language) {
            path = `/${i18n.language}${path}`;
        }
    }
    const paramsStr = qs.stringify(params);
    const isInApp = typeof window !== 'undefined' && window.location.search.indexOf('isInApp=true') > -1;
    if (isInApp) {
        path = `${path}?isInApp=true`;
    }
    if (paramsStr) {
        path = `${path}${isInApp ? '&' : '?'}${paramsStr}`;
    }
    return path;
}

import { jsx as _jsx } from "react/jsx-runtime";
/*
 * @Author: 朱璐
 * @Date: 2022-11-03 11:55:16
 * @Description: web端filter
 */
import { FilterContainer } from './filter-container';
export const WebFilter = () => {
    return (_jsx("div", Object.assign({ className: "w-full bg-white shadow-sm rounded-lg px-4 grid grid-cols-1" }, { children: _jsx(FilterContainer, {}) })));
};

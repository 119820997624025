import { useDebounceFn, useLatest } from 'ahooks';
import { useRef } from 'react';
import { unref } from '../utils/unref';
import { useClientLayoutEffect } from './use-client-layout-effect';
function useLongHover(onLongHover, target, { delay = 300, onLongHoverEnd } = {}) {
    const onLongHoverRef = useLatest(onLongHover);
    const onLongHoverEndRef = useLatest(onLongHoverEnd);
    const timerRef = useRef();
    const isTriggeredRef = useRef(false);
    const { run, cancel } = useDebounceFn((event) => {
        timerRef.current = setTimeout(() => {
            onLongHoverRef.current(event);
            isTriggeredRef.current = true;
        }, delay);
    }, { wait: 100 });
    const targetEl = unref(target);
    useClientLayoutEffect(() => {
        if (targetEl) {
            const targetElement = targetEl;
            const onEnd = (event) => {
                var _a;
                event.stopPropagation();
                if (timerRef.current) {
                    cancel();
                    clearTimeout(timerRef.current);
                }
                if (isTriggeredRef.current) {
                    (_a = onLongHoverEndRef.current) === null || _a === void 0 ? void 0 : _a.call(onLongHoverEndRef, event);
                }
                isTriggeredRef.current = false;
            };
            targetElement.addEventListener('mouseenter', run);
            targetElement.addEventListener('mouseleave', onEnd);
            return () => {
                if (timerRef.current) {
                    clearTimeout(timerRef.current);
                    isTriggeredRef.current = false;
                }
                targetElement.addEventListener('mouseenter', run);
                targetElement.addEventListener('mouseleave', onEnd);
            };
        }
    }, [targetEl]);
}
export default useLongHover;

import env from '@common/constants/env';
const config = {
    // 域名
    baseUrl: env.API_HOST,
    // 全局的接口超时时间
    timeout: 30 * 1000,
    // 服务器响应的数据类型，
    responseType: 'json'
};
export default config;
